import { render, staticRenderFns } from "./AdditionalRewardsLanding.vue?vue&type=template&id=6a1a593d&scoped=true"
import script from "./AdditionalRewardsLanding.vue?vue&type=script&lang=js"
export * from "./AdditionalRewardsLanding.vue?vue&type=script&lang=js"
import style0 from "./AdditionalRewardsLanding.vue?vue&type=style&index=0&id=6a1a593d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a1a593d",
  null
  
)

export default component.exports