<template>
  <div class="nav-row">
    <!-- <TokenTimeout /> -->

    <nav id="global-nav" aria-label="Main Menu">
      <div id="my-lottery-nav-wrapper">
        <ul>
          <li class="has-subnav" @click="log">
            <a href="#">My Lottery 360<span></span></a>
            <ul class="global-nav-2">
              <li><a href="/" class="my-lottery-links">My Lottery 360°</a></li>
              <li v-if="!isUserLogged">
                <a href="/register" class="my-lottery-links">Create account</a>
              </li>
              <li>
                <a href="/rewards-catalog" class="my-lottery-links"
                  >Rewards Catalog</a
                >
              </li>
              <li>
                <a href="/enter" class="my-lottery-links">Enter Ticket</a>
              </li>
              <li v-if="isUserLogged">
                <a href="/my-purchases">My Purchases</a>
              </li>
              <li v-if="isUserLogged">
                <a href="/transaction-history" class="my-lottery-links"
                  >Transaction History</a
                >
              </li>
              <li v-if="isUserLogged">
                <a href="/my-profile" class="my-lottery-links">My Profile</a>
              </li>
              <li v-if="isUserLogged">
                <a href="/notifications" class="my-lottery-links"
                  >Notifications</a
                >
              </li>
              <li v-if="isUserLogged">
                <a href="/change-password" class="my-lottery-links"
                  >Change Password</a
                >
              </li>
              <li><a href="/faqs" class="my-lottery-links">FAQs</a></li>
              <li><a href="/help" class="my-lottery-links">Help</a></li>
            </ul>
            <div class="global-nav-2-under"></div>
          </li>
        </ul>
      </div>

      <div class="columns-container">
        <ul class="global-nav-1 column-5 my-lottery-nav-target">
          <li class="has-subnav" @click="log">
            <a href="https://www.walottery.com/WinningNumbers/"
              >Winning Numbers<span></span
            ></a>
            <ul class="global-nav-2">
              <li>
                <a href="https://www.walottery.com/WinningNumbers/Default.aspx"
                  >Latest Jackpots</a
                >
              </li>
              <li>
                <a
                  href="https://www.walottery.com/WinningNumbers/CheckYourTicket.aspx"
                  >Check Your Ticket</a
                >
              </li>
              <li>
                <a
                  href="https://www.walottery.com/WinningNumbers/UnclaimedTopPrizes.aspx"
                  >Unclaimed Top Prizes</a
                >
              </li>
              <li>
                <a
                  href="https://www.walottery.com/WinningNumbers/PastDrawings.aspx"
                  >Past Drawings</a
                >
              </li>
              <li>
                <a
                  href="https://www.walottery.com/WinningNumbers/NumbersFrequency.aspx"
                  >Numbers Frequency</a
                >
              </li>
            </ul>
            <div class="global-nav-2-under"></div>
          </li>
          <li class="has-subnav" @click="log">
            <a href="https://www.walottery.com/JackpotGames/"
              >Jackpot Games<span></span
            ></a>
            <ul class="global-nav-2">
              <li>
                <a href="https://www.walottery.com/JackpotGames/Default.aspx"
                  >All Games</a
                >
              </li>
              <li>
                <a href="https://www.walottery.com/JackpotGames/Powerball.aspx"
                  >Powerball</a
                >
              </li>
              <li>
                <a
                  href="https://www.walottery.com/JackpotGames/MegaMillions.aspx"
                  >Mega Millions</a
                >
              </li>
              <li>
                <a href="https://www.walottery.com/JackpotGames/Lotto.aspx"
                  >Lotto</a
                >
              </li>
              <li>
                <a href="https://www.walottery.com/JackpotGames/Hit5.aspx"
                  >Hit 5</a
                >
              </li>
              <li>
                <a href="https://www.walottery.com/JackpotGames/Match4.aspx"
                  >Match 4</a
                >
              </li>
              <li>
                <a href="https://www.walottery.com/JackpotGames/Pick3.aspx"
                  >Pick 3</a
                >
              </li>
              <li>
                <a href="https://www.walottery.com/JackpotGames/CashPop.aspx"
                  >Cash POP</a
                >
              </li>
              <li>
                <a href="https://www.walottery.com/JackpotGames/DailyKeno.aspx"
                  >Daily Keno</a
                >
              </li>
            </ul>
            <div class="global-nav-2-under"></div>
          </li>
          <li class="has-subnav" @click="log">
            <a href="https://www.walottery.com/Scratch/"
              >Scratch<span></span
            ></a>
            <ul class="global-nav-2">
              <li>
                <a href="https://www.walottery.com/Scratch/Explorer.aspx"
                  >Scratch Explorer</a
                >
              </li>
              <li>
                <a
                  href="https://www.walottery.com/Scratch/TopPrizesRemaining.aspx"
                  >Top Prizes Remaining</a
                >
              </li>
              <li>
                <a href="https://www.walottery.com/Scratch/Disclaimers.aspx"
                  >Disclaimers</a
                >
              </li>
              <li>
                <a href="https://www.walottery.com/Scratch/PrizeOdds.aspx"
                  >Prize Odds</a
                >
              </li>
            </ul>
            <div class="global-nav-2-under"></div>
          </li>
        </ul>
        <div class="column-2"></div>
        <ul class="global-nav-1 column-5">
          <li class="has-subnav" id="subnav2" @click="log">
            <a href="https://www.walottery.com/SpecialOffers/"
              >Special Offers<span></span
            ></a>
            <ul class="global-nav-2">
              <li>
                <a href="https://www.walottery.com/SpecialOffers/Current.aspx"
                  >Current Offers</a
                >
              </li>
              <li>
                <a href="https://www.walottery.com/SpecialOffers/History.aspx"
                  >Past Offers</a
                >
              </li>
            </ul>
            <div class="global-nav-2-under"></div>
          </li>
          <li>
            <a
              class="global-nav-1-item-no-nav-2"
              href="https://www.walottery.com/WhoBenefits/"
            >
              Who Benefits</a
            >
          </li>
          <li>
            <a
              class="global-nav-1-item-no-nav-2"
              href="https://www.walottery.com/Media/"
              >Media</a
            >
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>
<script>
// import Vue from 'vue';
import TokenTimeout from "../Shared/TokenTimeout.vue";
import $ from "jquery";

export default {
  components: {
    TokenTimeout,
  },
  data: function () {
    return {
      menu: "",
      isUserLogged: false,
      breakpoint: 960,
      toggleSpeed: 469,
      userInput: "",
      token: "",
      mobile: false,
      close: true,
      active: false,
    };
  },
  mounted: function () {
    this.isUserLogged = this.isUserAuthed();
    this.dropDownMenu();
  },
  methods: {
    log(e) {
      if (this.active === false) {
        this.dropDownMenu();
        this.active = true;
        e.target.click();
      }
    },
    mobileDropdown(){
        if (window.innerWidth <= breakpoint) {
            var menuAnchor = document.querySelectorAll(
              ".global-nav-1 .has-subnav"
            );
            var menuAnchor360 = document.querySelector(
              "#my-lottery-nav-wrapper .has-subnav"
            );
            var menuAnchor2 = document.getElementById("subnav2"); //this is to target the non-sibling subnav element

            //targeting the two non-sibling subnavs

            menuAnchor360.addEventListener("click", function () {
              for (var i = 0, len = menuAnchor.length; i < len; i++) {
                $(menuAnchor[i]).removeClass("global-nav-item-open");
              }
            });

            menuAnchor2.addEventListener("click", function () {
              for (var i = 0, len = menuAnchor.length; i < len; i++) {
                $(menuAnchor[i]).removeClass("global-nav-item-open");
              }
            });

            //targeting all other subnavs

            for (var i = 0, len = menuAnchor.length; i < len; i++) {
              menuAnchor[i].addEventListener("click", function (event) {
                $(menuAnchor360).removeClass("global-nav-item-open");
                $(menuAnchor2).removeClass("global-nav-item-open");
                $(this).siblings().removeClass("global-nav-item-open");

                $(this).toggleClass("global-nav-item-open");
                if (this.classList.contains("has-subnav")) {
                  $(this).children().removeAttr("href");
                }
              });
            }

            this.active = true;

            const myLottery360nav = $("#my-lottery-nav-wrapper .has-subnav");
            myLottery360nav.on("click", function (e) {
              $(this).toggleClass("global-nav-item-open");
            });
          } else {
            for (var i = 0, len = menuAnchor.length; i < len; i++) {
              if (this.classList.contains("global-nav-item-open")) {
                $(this).removeClass("global-nav-item-open");
              }
            }
          }
    },
    dropDownMenu() {
      const navButton = document.getElementById("global-header-nav-btn-small");
      const navButton2 = document.getElementById("global-header-nav-btn");
      var breakpoint = 970;
      let menuOpen = false;

      if (navButton) {
        navButton.addEventListener("click", () => {
          if (menuOpen !== false) {
            document.body.classList.remove("global-nav-open");
            menuOpen = false;
          } else {
            menuOpen = true;
            document.body.classList.add("global-nav-open");
          }
        });
      }

      if (navButton2) {
        navButton2.addEventListener("click", () => {
          if (menuOpen !== false) {
            document.body.classList.remove("global-nav-open");
            menuOpen = false;
          } else {
            menuOpen = true;
            document.body.classList.add("global-nav-open");
          }
        });
      }

      var menuAnchor = document.querySelectorAll(".global-nav-1 .has-subnav");
          if (window.innerWidth <= breakpoint) {
            var menuAnchor = document.querySelectorAll(
              ".global-nav-1 .has-subnav"
            );
            var menuAnchor360 = document.querySelector(
              "#my-lottery-nav-wrapper .has-subnav"
            );
            var menuAnchor2 = document.getElementById("subnav2"); //this is to target the non-sibling subnav element

            //targeting the two non-sibling subnavs

            menuAnchor360.addEventListener("click", function () {
              for (var i = 0, len = menuAnchor.length; i < len; i++) {
                $(menuAnchor[i]).removeClass("global-nav-item-open");
              }
            });

            menuAnchor2.addEventListener("click", function () {
              for (var i = 0, len = menuAnchor.length; i < len; i++) {
                $(menuAnchor[i]).removeClass("global-nav-item-open");
              }
            });

            //targeting all other subnavs

            for (var i = 0, len = menuAnchor.length; i < len; i++) {
              menuAnchor[i].addEventListener("click", function (event) {
                $(menuAnchor360).removeClass("global-nav-item-open");
                $(menuAnchor2).removeClass("global-nav-item-open");
                $(this).siblings().removeClass("global-nav-item-open");

                $(this).toggleClass("global-nav-item-open");
                if (this.classList.contains("has-subnav")) {
                  $(this).children().removeAttr("href");
                }
              });
            }

            this.active = true;

            const myLottery360nav = $("#my-lottery-nav-wrapper .has-subnav");
            myLottery360nav.on("click", function (e) {
              $(this).toggleClass("global-nav-item-open");
            });
          } 


      $(window).on({
        resize: (e) => {
            var menuAnchor = document.querySelectorAll(".global-nav-1 .has-subnav");
          if (window.innerWidth <= breakpoint) {
            var menuAnchor = document.querySelectorAll(
              ".global-nav-1 .has-subnav"
            );
            var menuAnchor360 = document.querySelector(
              "#my-lottery-nav-wrapper .has-subnav"
            );
            var menuAnchor2 = document.getElementById("subnav2"); //this is to target the non-sibling subnav element

            //targeting the two non-sibling subnavs

            menuAnchor360.addEventListener("click", function () {
              for (var i = 0, len = menuAnchor.length; i < len; i++) {
                $(menuAnchor[i]).removeClass("global-nav-item-open");
              }
            });

            menuAnchor2.addEventListener("click", function () {
              for (var i = 0, len = menuAnchor.length; i < len; i++) {
                $(menuAnchor[i]).removeClass("global-nav-item-open");
              }
            });

            //targeting all other subnavs

            for (var i = 0, len = menuAnchor.length; i < len; i++) {
              menuAnchor[i].addEventListener("click", function (event) {
                $(menuAnchor360).removeClass("global-nav-item-open");
                $(menuAnchor2).removeClass("global-nav-item-open");
                $(this).siblings().removeClass("global-nav-item-open");

                $(this).toggleClass("global-nav-item-open");
                if (this.classList.contains("has-subnav")) {
                  $(this).children().removeAttr("href");
                }
              });
            }

            this.active = true;

            const myLottery360nav = $("#my-lottery-nav-wrapper .has-subnav");
            myLottery360nav.on("click", function (e) {
              $(this).toggleClass("global-nav-item-open");
            });
          } else {
            var opened = document.querySelector(".global-nav-item-open");
            if(opened){
               $(opened).attr("class", "has-subnav");
            }
            }
          }
      });
    },
    addMenu(menuData) {
      let contentMarkup = menuData[0].centralContent;
      let parser = new DOMParser();
      let htmlDoc = parser.parseFromString(contentMarkup, "text/html");

      this.menu = htmlDoc.body.innerHTML;
      return Promise.resolve();
    },
    showMenu(menuData) {
      this.addMenu(menuData).then(() => {
        this.megaMenu();

        if ($(window).width() <= this.breakpoint) {
          this.mobile = true;
          setTimeout(() => {
            let signout = document.getElementById("mobileMenuSignOut");
            if (signout !== null) {
              signout.addEventListener("click", function () {
                let realSignout =
                  document.getElementsByClassName("sign-out-button");
                realSignout[0].click();
              });
            }
          }, 500);
        }
      });
    },
    megaMenu: function () {
      var toggleSpeed = 469;
      var breakpoint = 960;
      var container = $("#container");
      $(".megaMenu li:has( > ul)").addClass("menu-dropdown-icon");
      $(".megaMenu > li > ul:not(:has(ul))").addClass("normal-sub");

      if ($(window).width() > breakpoint) {
        $(document).on(
          {
            mouseenter: function () {
              $(this)
                .children("ul")
                .stop(true, false)
                .fadeIn(150)
                .addClass("opened");
            },
            mouseleave: function () {
              $(this)
                .children("ul")
                .stop(true, false)
                .fadeOut(150)
                .removeClass("opened");
            },
          },
          ".megaMenu > li"
        );
      }

      var thisPath = location.pathname,
        aboutRegex = /about/i;

      // thisPath = thisPath.substr(1, 6);
      thisPath = thisPath.substring(1, 7);
      if (aboutRegex.test(thisPath)) {
        $("#aboutMenu > li").addClass("current-path");
      }

      // remove top level clicks, must select secondary
      if ($(window).width() <= breakpoint) {
        $(document).on(
          {
            click: function (e) {
              e.preventDefault();
            },
          },
          ".megaMenu > .menu-dropdown-icon > a"
        );
      }

      let close = true;

      // open/close menus
      $(document).on(
        {
          click: function () {
            $(
              "#winning-numbers-menu-mobile>.recent_numbers_wrap>.orbit>.orbit-wrapper>.orbit-bullets>.orbit-buttons"
            ).on({
              click: function () {
                close = false;
              },
            });
            if ($(window).width() <= breakpoint) {
              if (close === false) {
                close = true;
              } else if ($(this).hasClass("subMenuOpen")) {
                if ($(".mobileB").length) {
                  $(".subMenuOpen").off();
                  // console.log('Mobile B subMenuOpen OFF');
                } else {
                  $(".subMenuOpen")
                    .removeClass("subMenuOpen")
                    .children("ul")
                    .slideUp(toggleSpeed);
                }
                // $('.subMenuOpen').removeClass('subMenuOpen').children('ul').slideUp(toggleSpeed);
              } else {
                $(".subMenuOpen")
                  .removeClass("subMenuOpen")
                  .children("ul")
                  .slideUp(toggleSpeed);
                $(this)
                  .addClass("subMenuOpen")
                  .children("ul")
                  .slideDown(toggleSpeed, function () {});
              }
            }
          },
        },
        ".megaMenu > li"
      );

      var initWidth = container.width();

      $(window).on({
        resize: function () {
          var newWidth = container.width();
          // console.log(initWidth+' ?= '+newWidth);
          if (initWidth != newWidth) {
            $(".megaMenu > li").children("ul").hide();
            $("#container").removeClass("show-on-mobile");
            $(".menu-mobile").removeClass("menu-open");
            $(".topnav").removeClass("menu-open");
            $(".topnav").css({
              "min-height": "",
              width: "",
            });
          }

          if ($(window).width() <= breakpoint) {
            $(document).on(
              {
                click: function (e) {
                  e.preventDefault();
                },
              },
              ".megaMenu > .menu-dropdown-icon > a"
            );
          }

          if (newWidth <= this.breakpoint) {
            this.mobile = true;
            $(document).on(
              {
                click: function (e) {
                  e.preventDefault();
                },
              },
              ".megaMenu > .menu-dropdown-icon > a"
            );
          } else {
            this.mobile = false;
          }
        },
      });
    },
    menuMobile: function (event) {
      var container = $("#container");
      var winHeight = container.height();
      var winWidth = container.width();

      if (winWidth > 490) {
        winWidth = 450;
      } else {
        winWidth = winWidth - 40;
      }
      $(".topnav").css({
        "min-height": winHeight,
        width: winWidth,
      });

      setTimeout(() => {
        $("#top-menu").css("display", "none");
      }, 300);

      $("#container").removeClass("show-on-mobile");
      $(".menu-mobile").removeClass("menu-open");
      $(".topnav").removeClass("menu-open");

      event.preventDefault();
    },
    logOut: function (event) {
      event.preventDefault();
      $(".sign-out-button").click();
    },
  },
  watch: {
    password(newVal, oldVal) {
      console.log(newVal);
      console.log("=========================");
      console.log(oldVal);
    },
  },
};
</script>
<style lang="scss" scoped></style>
