<template>
    <div class="delete_account">
        <div class="grid-x grid-margin-x block-section">
            <div class="cell large-12" v-if="!isUserLogged">
                <UserLoginRegisterVue contentKey="authButtons"></UserLoginRegisterVue>
            </div>
            <div class="cell large-12" v-show="isUserLogged">
                <!-- <button class="button red_btn" @click="confirmDeletion" v-show="isUserLogged && !ConfirmDelete">Delete My Account</button> -->
                <!-- <ConfirmDeleteAccount v-show="ConfirmDelete"></ConfirmDeleteAccount> -->
                <ConfirmDeleteAccount></ConfirmDeleteAccount>
            </div>
        </div>
    </div>
</template>

<script>
// import $ from 'jquery';
import UserLoginRegisterVue from '../../ViewComponents/SignInRegister/SignInRegister.vue';
import ConfirmDeleteAccount from './ConfirmDeleteAccount.vue';
export default {
    components: {
        UserLoginRegisterVue,
        ConfirmDeleteAccount
    },
    data: function () {
        return {
            isUserLogged: false,
            ConfirmDelete: false
        }
    },
    mounted: function () {
        this.isUserLogged = this.isUserAuthed();
    },
    methods: {
        confirmDeletion() {
            this.ConfirmDelete = true;
            // window.location.pathname = "/mylotto-rewards/my-account/my-profile/delete-your-account/confirm-delete";
        }
    }
};
</script>