// NOTE Winning Numbers Module

// Game Markup
import * as vueMixins from '../../vueMixins';

const mixin = vueMixins.default.methods;

export default {
    namespaced: true,
    state: () => ({
        drawGames: [],
        activeGames: [
            'pick3',
            'pick4',
            'megamillions'
        ],
        games: [
            {
                api: '/1.0/Games/DrawGames/Pick3/GetLatestDraws',
                logo: '/1.0/Games/DrawGames/Pick3/GetGameInformation',
                mutation: 'pick3',
                logoMutation: 'pick3Logo'
            },
            {
                api: '/1.0/Games/DrawGames/Pick4/GetLatestDraws',
                mutation: 'pick4',
                logo: '/1.0/Games/DrawGames/Pick4/GetGameInformation',
                logoMutation: 'pick4Logo'
            },
            {
                api: '/1.0/Games/DrawGames/MegaMillions/GetLatestDraws',
                mutation: 'mega',
                logo: '/1.0/Games/DrawGames/MegaMillions/GetGameInformation',
                logoMutation: 'megaLogo'
            }
        ]
    }),
    mutations: {
        drawGame (state, payload) {
            let stateDrawGames = state.drawGames;

            stateDrawGames.push(payload);
            state.drawGames = stateDrawGames;
        },
        drawGameLogo (state, payload) {
            let stateDrawGames = state.drawGames;

            stateDrawGames[payload.id].numbers.gameLogo = payload.logo;
            stateDrawGames[payload.id].numbers.gameName = payload.name;

            state.drawGames = stateDrawGames;
        }
    },
    actions: {
        fetchData({commit, state}, payload) {
            var apiToken = payload.token;
            var urlProtocol = mixin.getProtocol();

            return fetch(urlProtocol+process.env.VUE_APP_APIURL+state.games[payload.game].api, {
                headers: {
                    'Authorization': 'Bearer '+apiToken,
                },
            })
            .then(response => {
                var res = async function() {
                    return await response.json();
                }
                var drawNumbers = res().then((result) => {
                    commit('drawGame', {
                        numbers: result.data
                    })
                });

                return drawNumbers;
            })
            .catch(error => {
                return this.errorMessage = error;
            });
        },
        fetchLogo({commit, state}, payload) {
            var apiToken = payload.token;
            var urlProtocol = mixin.getProtocol();
            return fetch(urlProtocol+process.env.VUE_APP_APIURL+state.games[payload.game].logo, {
                headers: {
                    'Authorization': 'Bearer '+apiToken,
                },
            })
            .then(response => {
                let res = async function() {
                    return await response.json();
                }
                
                var gameData = res().then((result) => {
                    commit('drawGameLogo', {
                        id: payload.game,
                        logo: result.data.gameLogoURL,
                        name: result.data.name
                    })
                })

                return gameData;
            })
            .catch(error => {
                return this.errorMessage = error;
            });
        }
    },
    getters: {
        getGames(state) {
            return state.activeGames
        },
        getDrawGame(state) {
            return state.drawGames;
        }
    }
}