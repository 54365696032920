<template>
    <section :class="{ 'user-message': true, 'internal-page': !isHomePage }"
        v-if="isHomePage && isUserLogged || !isHomePage && isUserLogged || !isHomePage && !isUserLogged"
        style="position: relative"
        >
        <img :src="staticData.img" />
        <div class="message-description" v-if="isUserLogged">
            <p class="user"><strong v-if="isHomePage">Hello, {{ user.name }}!</strong> Your rewards balance is:</p>
            <p class="points">{{ addCommasToNumber(user.points) }}</p>
            <p class="expired" v-if="!isHomePage && user.pointsToExpire >= 1">
                <span class="icon-exclamation-triangle"></span>
                {{ user.pointsToExpire }} points will expire on {{ formatDate(user.pointsExpirationDate) }}
            </p>
        </div>
        <div class="cta-description" v-if="isUserLogged && isHomePage">
            <p class="legend">{{ staticData.ctaText }}</p>
            <button class="" @click="ctaButonAction">{{ staticData.ctaButtonText }}</button>
            <p class="expired" v-if="user.pointsToExpire >= 1">
                <span class="icon-exclamation-triangle"></span>
                {{ user.pointsToExpire }} points will expire on {{ formatDate(user.pointsExpirationDate) }}
            </p>
        </div>

        <AutoLogout></AutoLogout>
    </section>
</template>

<script>
import moment from 'moment';
import AutoLogout from './AutoLogout.vue';

export default {
    components:{
        AutoLogout
    },
    data: function () {
        return {
            isUserLogged: false,
            isHomePage: true,
            isDeleteAccountPage: false,
            user: {
                isUserLogged: false,
                id: '',
                name: '',
                points: '0',
                pointsToExpire: '0',
                pointsExpirationDate: ''
            },
            staticData: {
                img: '/site_images/my-lottery-360-white.png',
                ctaText: 'Use your points to shop from our catalog for great gifts and gear. You’ve earned it!',
                ctaButtonText: 'Redeem Points',
                ctaUrl: '/prizes'
            }
        }
    },
    mounted: function () {
        this.getUserPoints()
        this.getToken();

        // this.isDeleteAccountPage = window.location.pathname === '/my-profile/delete-your-account';
        this.isHomePage = window.location.pathname === '/' || window.location.pathname === '/home';
    },
    methods: {
        getToken() {
            this.isUserLogged = this.isUserAuthed();
            this.getAPItoken()
                .then((token) => {
                    this.token = token;
                    if (this.isUserLogged) {
                        this.getUser();
                    }
                });
        },

        ctaButonAction() {
            window.location.href = this.staticData.ctaUrl;
        },
        addCommasToNumber(number) {
            const parts = number.toString().split('.');
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return parts.join('.');
        },
        formatDate(date) {
            const newDate = moment(date).format('MM/DD/YYYY');
            return newDate
        },
        getUser: function () {

            let userData = JSON.parse( sessionStorage.getItem('apiToken'));
            let url = process.env.VUE_APP_APIURL + '/1.0/Ecommerce/GetUserAvailableCredit?userId=' + userData.user.userId, apiToken = this.token;
            fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + apiToken
                }
            }).then((response) => response.json()).then((json) => {
                
                let data = json.data;

                this.user.id = userData.user.userId;
                this.user.name = userData.user.firstName;
                this.user.points = data.pointBalance.balance;
                this.user.pointsToExpire = userData.user.pointBalance.pointsToExpire;
                this.user.pointsExpirationDate = userData.user.pointBalance.pointsExpirationDate;
            });
        }
    }
};
</script>