import Vue from "vue";

import SecondaryMenu from "./SecondaryMenu.vue";

export function load(payload) {
    const selector = "secondary-menu";
    const store = payload;
    // Is the custom Vue root element in the DOM?
    if (!document.querySelector(selector)) {
        return;
    }

    // Create a new Vue app with the imported Home component
    new Vue({
        store,
        render: createElement =>
            createElement(SecondaryMenu, {
                props: {
                    ...window[selector],
                },
            }),
    }).$mount(selector);
}