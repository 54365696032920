<template>
    <div>
        <UserMessageVue ref="user"></UserMessageVue>
        <h1 class="headline">
            ENTER TICKET
        </h1>
        <div class="module module_pageContent">
            <div class="ticketEntryWrap">
                <div class="ticketEntryWrap_wrapper">
                    <div class="ticketEntryWrap_form">
                        <h2>What type of ticket do you wish to enter?</h2>
                        <div class="ticketEntryWrap_form_radio-buttons">
                            <div class="ticketEntryWrap_form_radio">
                                <input name="ticket-type" type="radio" class="input-radio" id="scratch" @click="ticket(0)" checked>
                                <label for="scratch">Scratch Game</label>
                            </div>
                            <div class="ticketEntryWrap_form_radio">
                                <input name="ticket-type" type="radio" class="input-radio" id="draw" @click="ticket(1)">
                                <label for="draw">Draw Game</label>
                            </div>
                        </div>
                        <TicketEntryForm v-if="tEntry === 'true' && showTicketEntryForm" contentKey="ticket-entry-text"
                            requireDigits="3" placeholderProp="" :headingProp="header" submitTextProp="" :toggleGame="manualTicket" :gameType="gameType" :displayResult="displayResult">
                        </TicketEntryForm>
                    </div>
                    <div v-html="appScanCallout" class="ticketEntryWrap_scanCallout" :class="['api-content', apiClass]"></div>
                </div>
                <div class="ticketEntryResult" v-if="showErrMsg || showGameCodeResults">
                    <hr>
                    <div class="error-ticket text-center" v-if="showErrMsg">
                        <div v-if="gameLogo != ''" class="entry-image">
                            <img :src="gameLogo" />
                        </div>
                        <p v-if="errorMessage" v-html="errorMessage"></p>
                        <p v-if="gameCodeError && validGame">{{ gameCodeError }}</p>
                    </div>
                    <hollow-dots-spinner :animation-duration="1000" :dot-size="15" :dots-num="3" color="#0FD8C9" v-if="isLoading" />

                    <div class="ticket-entry-gamecode-results" v-if="showGameCodeResults">
                        <div class="ticket-entry-results text-center" v-if="showResults">
                            <h5 class="text-center">{{ entered }}</h5>

                            <div class="ticket-entered grid-x grid-margin-x">   
                                <div class="entry-image">
                                    <img :src="result.icon" />
                                </div>
                                <!-- <div class="cell small-1" v-if="result.icon === ''">
                                    <span class="lotteryLogo"></span>
                                </div> -->
                                <div class="result text-center">
                                    <h2 v-if="result.name">{{ result.name }}</h2>
                                    <p v-if="result.title">{{ result.title }}</p>
                                    <p v-if="result.message" v-html="result.message"></p>
                                </div>
                                <div class="result text-center" v-for="(notification, index) in notificationList" :key="index">
                                    <span v-if="notification.Type === 6 || notification.Type === 5">
                                        <h2 class="text-center">{{ notification.Title }}</h2>
                                        <p class="text-center" v-html="notification.Message"></p>
                                    </span>
                                </div>
                                <div v-if="submit">
                                    You have a balance of <strong>{{ userPoints }}</strong> rewards points. You can collect up to <strong>{{ remainingPoints }}</strong> more points for today.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <div v-html="scratchEntryHowTo" v-if="gameType === 0"></div>
                <div v-html="drawEntryHowTo" v-if="gameType === 1"></div>
            </div>
        </div>
    </div>
</template>

<script>
import TicketEntryForm from './TicketEntry.vue';
import UserMessageVue from '../Shared/User-Message.vue';

export default {
    props: [
        'header',
        'scdCode'
    ],
    components: {
        TicketEntryForm,
        UserMessageVue
    },
    data: function () {
        return {
            showTicketEntryForm: true,
            tEntry: false,
            apiClass: '',
            gameType: 0,
            scratchEntryHowTo: '',
            drawEntryHowTo: '',
            appScanCallout: '',
            result: '',
            showErrMsg: false,
            errorMessage: '',
            gameCodeError: false,
            validGame: false,
            isLoading: false,
            showGameCodeResults: false,
            gameLogo: '',
            gameName: '',
            scanResultTitle: '',
            gameNumber: '',
            gamePrice: '',
            showResults: false,
            notificationList: '',
            entered: '',
            submit: false,
            userPoints: '',
            remainingPoints: ''
        }
    },
    mounted: function () {
        let apiToken = JSON.parse( sessionStorage.getItem('apiToken'));
        // console.log(apiToken.user.userId)

        // NOTE Commenting for WA testing.
        // if(apiToken.user.username == "mobilepublic@mtllc.com"){
        //     this.showTicketEntryForm = false;
        // }

        this.fetchContentFromAPI('manual-ticket-entry', 'manual-ticket-entry')
            .then(json => {
                this.tEntry = json.enabled;
            });
        this.fetchContent();
    },
    methods: {
        ticket(type) {
            this.notificationList = [] // force to reset the Object
            type === 0 ? this.gameType = 0 : this.gameType = 1;
        },

        manualTicket(type) {
            type === 0 ? $('#scratch').click() : $('#draw').click();
        },

        sanitizeHtml(html) {
            // Removing double backslashes
            let sanitizedHtml = html.replace(/\\\\/g, '\\');
            // Replacing \" with "
            sanitizedHtml = sanitizedHtml.replace(/\\"/g, '"');
            // Replacing \r\n with empty space
            sanitizedHtml = sanitizedHtml.replace(/\\r\\n/g, '');
            // Removing leading and trailing double quotes
            sanitizedHtml = sanitizedHtml.replace(/^"/, '').replace(/"$/, '');

            return sanitizedHtml;
        },

        fetchContent: async function () {
            const scratchEntryHowTo = await this.fetchContentFromAPI('scratch-entry-howto', 'scratch-entry-howto');
            const drawEntryHowTo = await this.fetchContentFromAPI('draw-entry-howto', 'draw-entry-howto');
            const appScanCallout = await this.fetchContentFromAPI('app-scan-callout', 'app-scan-callout');

            this.scratchEntryHowTo = this.sanitizeHtml(scratchEntryHowTo);
            this.drawEntryHowTo = this.sanitizeHtml(drawEntryHowTo);
            this.appScanCallout = this.sanitizeHtml(appScanCallout);
        },

        displayResult(d, sub) {
            this.notificationList = [] // force to reset the Object
            
            this.submit = false;
            let json = d,
                data = d.data,
                notif;

            // console.log(d)

            if (d.NotificationList) {
                notif = d.NotificationList;
            }

            this.gameLogo = '';
            this.gameName = '';

            this.showErrMsg = false;
            this.errorMessage = "";
            this.result = "";

            if (d === null || d === undefined) {
                this.isLoading = false;
                this.showErrMsg = true;
                // this.errorMessage = "Invalid Ticket"
                this.errorMessage = "Invalid Ticket"

            } else if (data === null || data === undefined) {
                this.isLoading = false;

                this.redeemTicket = false;
                this.loginButton = false;
                
                this.showErrMsg = true;

                json.error === null || json.error === undefined ? this.errorMessage = 'Invalid Ticket' : this.errorMessage = json.error.message;

                return false;

            } else {
                // console.log(data)
                if (data.isValid === true) {
                    // console.log('Sub')
                    // console.log(sub)

                    let result;
                    
                    if (sub === true) {
                        let message = data.submitMessage;
                        let messageReplace = message.replace(/\n/g, '<br />');

                        result = {
                            icon: this.gameLogo,
                            name: this.gameName,
                            title: false,
                            message: messageReplace,
                            submit: true
                        }

                        this.submit = true;

                        this.updatePoints();
                        this.$refs.user.getUser();
                    } else {
                        // console.log('Check')
                        this.wager = data.ticketCost;
                        this.gameId = data.gameID;
                        this.isWinningTicket = json.isWinningTicket;

                        result = {
                            icon: data.gameImageURL,
                            name: data.gameName,
                            title: data.scanResultTitle,
                            message: data.scanResultMessage,
                            points: data.ticketWinAmount.toLocaleString(),
                            submit: false
                        }

                        this.submit = false;

                        this.gameLogo = data.gameImageURL;
                        this.gameName = data.gameName;
                        this.scanResultTitle = data.scanResultMessage;
                    }

                    if (notif) {
                        // console.log(notif);

                        this.notificationList = notif;
                    }

                    this.result = result;

                    // console.log(this.result)

                    this.redeemTicket = false;
                    this.loginButton = true;

                    this.showResults = true;
                    this.showGameCodeResults = true;

                    // this.submitTicket(forSubmission);

                } else {
                    console.log(data)
                    this.gameLogo = '';
                    this.gameName = '';
                    this.redeemTicket = false;
                    this.loginButton = false;

                    this.showErrMsg = true;

                    if (data.scanResultMessage === "CHANGE") {
                        this.errorMessage = "";
                    } else if (data.gameName.length) {
                        this.gameLogo = data.gameImageURL;
                        this.gameName = data.gameName;
                        data.scanResultMessage.length ? this.errorMessage = data.scanResultTitle + ' ' + data.scanResultMessage : this.errorMessage = data.scanResultTitle;
                        this.showErrMsg = true;
                    } else if (data.scanResultMessage === '' && data.scanResultTitle === '') {
                        // console.log(data.scanResultMessage)
                        this.showGameCodeResults = false;
                        this.showErrMsg = false;
                    } else if (data.scanResultTitle === null || data.scanResultTitle === undefined || data.scanResultTitle === '') {
                        // console.log(data.scanResultTitle)
                        this.errorMessage = data.scanResultMessage;
                    } else {
                        this.errorMessage = data.scanResultTitle + ' ' + data.scanResultMessage;
                    }
                }
            }
        },
        updatePoints() {
            this.getUserPoints();
            let apiToken = JSON.parse( sessionStorage.getItem('apiToken'));
            let url = process.env.VUE_APP_APIURL + '/1.0/Ecommerce/GetUserAvailableCredit?userId=' + apiToken.user.userId;

            fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + apiToken.token,
                },
            })
            .then(response => response.json())
            .then((json) => {
                let d = json;
                let points = 0;
                // console.log(d)
                if (d.data !== null && d.data !== undefined) {
                    // catch for users created in CMS withour Registering
                    // Swagger will create the customer, but not before we get null
                    // console.log(d.data.pointBalance.balance)
                    // console.log(d.data.pointBalance.remainingAwardedPointsByMonth)
                    this.userPoints = d.data.pointBalance.balance.toLocaleString();
                    this.remainingPoints = d.data.pointBalance.remainingEarnedPointsByDay.toLocaleString();
                }
                // if(userData.user.username !== 'mobilepublic@mtllc.com'){
                //     userData.user.pointBalance.balance = points;
                //     // sessionStorage.setItem('apiToken', userData);
                // }
            });
        }
    },
    computed: {

    },
};
</script>

<style lang="scss" scoped>
.ticketEntryWrap {
    background: #fff;
    border-radius: 20px;

    &_wrapper {
        display: flex;
        flex-direction: column;
        gap: 4.4rem;

        @media only screen and (min-width: 641px) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &_form {
        margin-bottom: 0;
        h2 {
            margin-bottom: 1.8rem;
        }
    }

    &_form_radio-buttons {
        display: flex;
        flex-direction: column;
        gap: 1.3rem;
        margin-bottom: 2.2rem;
    }

    &_form_radio {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &_scanCallout {
        background-color: #f3f3f3;
        border-radius: 10px;
        padding: 2.2rem 1.6rem;
        height: max-content;
    }

    &_form,
    &_scanCallout {
        width: 100%;
        @media only screen and (min-width: 641px) {
            width: 34.2rem;
        }
    }

    hr {
        margin: 3.2rem auto;
    }
}

.entry-image {
    padding-bottom: 2.5rem;

    img {
        max-width: 400px;
        max-height: 250px;
        @media only screen and (min-width: 641px) {
            max-height: 300px;
        }

    }
}

.input-radio {
    width: 2.4rem;
    height: 2.4rem;
    margin: 0;
    cursor: pointer;

    &::before {
        border: 1px solid #9e9e9e;
        background-color: #ffffff;
        width: 2.4rem;
        height: 2.4rem;
    }

    &:checked::after {
        width: 1.6rem;
        height: 1.6rem;
    }

    +label {
        font-size: 1.4rem;
        padding-bottom: 0;
        line-height: 1;
        padding-top: 0.3rem;
    }
}
.ticket-entry-landing h1.headline {
	border-radius: 20px;
	background-color: #e4e4e4;
	min-height: 56px;
	text-transform: uppercase;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 1.5rem;
}
@media print, screen and (min-width: 40em) {
    .ticket-entry-landing h1.headline {
        min-height: 10rem;
    }
}
</style>
