<template>
    <span>
        <button v-if="pointsAvailable" class="button btn-primary" data-open="modal-checkout"
            :disabled="stock === 0">Purchase with Points</button>
        <button v-if="isUserLogged && !wished" class="button btn-secondary" data-open="modal-wishlist" @click="addToWish">Add to
            Wish list</button>
            <button v-if="isUserLogged && wished" class="button btn-secondary" data-open="modal-wishlist" @click="removeProduct">Remove from
            Wish list</button>


        <div id="modal-checkout" class="reveal callout modal-checkout reveal-modal" data-reveal data-close-on-click="false">
            <section v-if="!confirm" class="shoppingCartmage oneStepCheckout">
                <h2>Confirm Purchase.</h2>
                <button class="close-button" type="button" data-close>
                    <span aria-hidden="true">×</span>
                </button>
                <div class="CartContent">
                    <!-- <picture class="checkoutProductImg">
                            <img :src="image">
                        </picture> -->
                    <div class="clear">
                        <p>
                            Please confirm your purchase of <strong>{{ data.qty }} {{ name }}</strong> for a total of {{
                                price }} points.

                            Your order will be shipped to this address: <strong v-if="user != ''">{{ user.user.email
                            }}</strong>
                        </p>
                        <!-- <p>
                                You can also locate your Digital Purchases under <strong>My Account</strong> in <strong>My Digital Purchases</strong>.
                            </p> -->
                        <a href="/my-profile">Update profile address</a>
                        <p>
                            Your order will be submitted after you select Confirm Purchase below.
                            <br>
                            <strong>ALL PURCHASES ARE FINAL.</strong>
                        </p>
                    </div>
                    <div class="clear"></div>
                    <div class="modalFooter cf" v-if="!transaction">
                        <button class="button btn btn-secondary" data-close>Cancel Purchase</button>
                        <button class="ProcessPaymentButton button btn btn-primary" @click="confirmPurchase">Confirm
                            Purchase</button>
                    </div>
                    <div class="modalFooter cf prplText" v-if="transaction">
                        Please wait while your transaction is being processed.
                    </div>
                </div>
            </section>
            <section id="confirmPanel" class="confirmPanel" v-if="confirm">
                <div v-if="error">
                    <h2>ERROR</h2>
                    <p>{{ message }}</p>
                    <button class="button btn-secondary" data-close>Close</button>
                </div>
                <div v-else>
                    <h2>Order Complete</h2>
                    <p>
                        Congratulations! Your order is complete.
                    </p>
                </div>
                <div class="modalFooter cf">
                    <button class="button btn btn-primary" data-close @click="refreshPage">Keep Shopping</button>
                </div>
            </section>
        </div>

        <div id="modal-wishlist" class="reveal modal-wishlist reveal-modal" data-reveal>
            <section v-if="!confirm" class="shoppingCartImage oneStepCheckout">
                <h2>Please wait...</h2>
            </section>
            <section v-if="confirm" class="oneStepCheckout-error">
                <div v-if="error">
                    <h2>ERROR</h2>
                    <p>{{ message }}</p>
                    <button class="button btn-secondary" data-close>Close</button>
                </div>
                <div v-else>
                    <h2>Success!</h2>
                    <button class="close-button" data-close type="button">
                        <span aria-hidden="true">×</span>
                    </button>
                    <p v-if="wished">This product has been added to your wish list.</p>
                    <p v-if="!wished">This product has been removed from your wish list.</p>
                    <button class="button btn-primary" data-close>Keep Shopping</button>
                </div>
            </section>
        </div>
    </span>
</template>

<script>
import $ from 'jquery';
import Foundation from "@/foundation-sites/js/foundation.js";

export default {
    props: [
        'data',
        'prop',
        'success'
    ],
    components: {
    },
    data: function () {
        return {
            isUserLogged: false,
            userPoints: 0,
            pointsAvailable: false,
            category: '',
            description: '',
            image: '',
            name: '',
            parameter1: '',
            parameter2: '',
            parameter3: '',
            parameter4: '',
            parameter5: '',
            price: 0,
            productId: 0,
            skuNumber: '',
            stock: 0,
            token: {},
            user: '',
            address: '',
            purchase: false,
            message: '',
            enter: true,
            confirm: false,
            error: false,
            transaction: false,
            wished: false
        }
    },
    mounted: function () {
        this.setProps();
        let _this = this;
        (function (window, undefined) {
            'use strict';

            $('[data-reveal]').on('closed.zf.reveal', function () {
                var modal = $(this);
                // alert('closed');
                _this.reset();
            });

            $(document).foundation();
        })();

        this.parseURL();
    },
    methods: {
        parseURL() {
            let pathname = window.location.pathname;

            let sections = pathname.split('/');
            this.url = "/rewards-catalog/" + sections[2];
            // console.log(this.url)
        },
        refreshPage() {
            // Reload the page
            // location.reload();
            this.$emit('success');
            window.location.pathname = this.url;
        },
        getProducts(token) {
            let apiToken = token,
                urlProtocol = this.getProtocol(),
                url = process.env.VUE_APP_APIURL+'/1.0/Ecommerce/WhislistGetUserProducts?userId=' + this.user.user.userId;

            return fetch(urlProtocol +  url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + apiToken
                }
            })
            .then(response => response.json())
            .then(json => {
                // console.log(json)
                if (json.error === null) {
                    for (let i = 0; i < json.data.length; i++) {
                        if (json.data[i].productId === this.productId) {
                            this.wished = true;
                        }
                    }
                } else {
                    this.error = true;
                    this.errorMessage = json.error.message;
                }
            })
            .catch(error => {
                this.error = true;
                // console.log(this.errorMessage = error);
            })
        },
        removeProduct() {
            let _this = this;
            let apiToken = this.token,
                urlProtocol = this.getProtocol(),
                url = process.env.VUE_APP_APIURL+'/1.0/Ecommerce/WhishlistRemoveProduct?skuId=' + this.productId + '&userId=' + this.user.user.userId;

            return fetch(urlProtocol +  url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + apiToken
                }
            })
            .then((response) => {
                this.transaction = false;
                this.confirm = true;

                // console.log(response)
                if (response.status === 200) {
                    return response.json();
                } else {
                    return false;
                }
            })
            .then((json) => {
                let res = json;

                if (res === false) {
                    this.message = "There was an error. Please try again later.";
                    this.error = true;
                    // console.log()
                } else if (res.error === null) {
                    this.message = "";
                    this.error = false;
                    this.wished = false;
                    this.getUserPoints();
                } else {
                    this.message = res.error.message;
                    this.error = true;
                }
            })
            .catch(error => {
                // console.log(_this)
                // console.error("There was an error!", error);
                _this.message = error;
                // console.log(error)
                _this.transaction = false;
                _this.confirm = true;
                _this.error = true;
            });
        },
        setProps() {
            this.isUserLogged = this.isUserAuthed(),
                this.userPoints = this.data.userPoints,
                this.pointsAvailable = this.data.pointsAvailable,
                this.category = this.data.category,
                this.description = this.data.description,
                this.image = this.data.image,
                this.name = this.data.name,
                this.parameter1 = this.data.parameter1,
                this.parameter2 = this.data.parameter2,
                this.parameter3 = this.data.parameter3,
                this.parameter4 = this.data.parameter4,
                this.parameter5 = this.data.parameter5,
                this.price = this.data.price,
                this.productId = this.data.productId,
                this.skuNumber = this.data.skuNumber,
                this.stock = this.data.stock,
                this.token = this.data.token,
                this.user = this.data.user,
                this.address = this.data.address

                this.getProducts(this.token)
        },
        reset() {
            // this.purchase = false;
            this.transaction = false;
            this.confirm = false;
            this.error = false;
        },
        openPurchase() {
            let popup = new Foundation.Reveal($('#modal-checkout'));
            popup.open();
        },
        closePurchase() {
            this.enter = true;
            this.confirm = false;
            this.error = false;
            $('#modal-checkout').hide();
            $('.reveal-overlay').hide();
        },
        addToWish() {
            // console.log(this.user)
            let url = process.env.VUE_APP_APIURL + '/1.0/Ecommerce/WishlistAddProduct?skuID=' + this.productId + '&userId=' + this.user.user.userId;

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json-patch+json',
                    'Authorization': "Bearer " + this.token
                },
            })
                .then((response) => {
                    this.transaction = false;
                    this.confirm = true;

                    if (response.status === 200) {
                        return response.json();
                    } else {
                        return false;
                    }
                })
                .then((json) => {
                    let res = json;

                    if (res === false) {
                        this.message = "There was an error with your purchase. Please try again later.";
                        this.error = true;
                        // console.log()
                    } else if (res.error === null) {
                        this.message = res.message;
                        this.error = false;
                        this.wished = true;
                        this.getUserPoints();
                    } else {
                        this.message = res.error.message;
                        this.error = true;
                    }
                })
                .catch(error => {
                    // console.log(_this)
                    // console.error("There was an error!", error);
                    _this.message = error;
                    // console.log(error)
                    _this.transaction = false;
                    _this.confirm = true;
                    _this.error = true;
                });
        },
        confirmPurchase() {
            let _this = this;
            this.transaction = true;

            let url = process.env.VUE_APP_APIURL + '/1.0/Ecommerce/PerformPurchase?userId=' + this.user.user.userId + '&productId=' + this.productId + '&qty=' + this.data.qty;

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json-patch+json',
                    'Authorization': "Bearer " + this.token
                },
            })
                .then((response) => {
                    this.transaction = false;
                    this.confirm = true;

                    if (response.status === 200) {
                        return response.json();
                    } else {
                        // console.log(response)
                        // if (response === null) {
                        //     console.log('NULL');
                        // } else if(response === '') {
                        //     console.log('BLANK');
                        // } else if (response === undefined) {
                        //     console.log('UNDEFINED');
                        // } else {
                        return false;
                        // }
                    }
                })
                .then((json) => {
                    let res = json;

                    if (res === false) {
                        this.message = "There was an error with your purchase. Please try again later.";
                        this.error = true;
                        // console.log()
                    } else if (res.error === null) {
                        // console.log('success')
                        this.message = "";
                        this.error = false;
                        this.getUserPoints();
                        
                    } else {
                        this.message = res.error.message;
                        this.error = true;
                    }
                })
                .catch(error => {
                    // console.log(_this)
                    // console.error("There was an error!", error);
                    _this.message = error;
                    // console.log(error)
                    _this.transaction = false;
                    _this.confirm = true;
                    _this.error = true;
                })
        }
    },
    computed: {
    },
    watch: {
        data: function (newVal) {
            this.isUserLogged = newVal.isUserLogged,
                this.userPoints = newVal.userPoints,
                this.pointsAvailable = newVal.pointsAvailable,
                this.category = newVal.category,
                this.description = newVal.description,
                this.image = newVal.image,
                this.name = newVal.name,
                this.parameter1 = newVal.parameter1,
                this.parameter2 = newVal.parameter2,
                this.parameter3 = newVal.parameter3,
                this.parameter4 = newVal.parameter4,
                this.parameter5 = newVal.parameter5,
                this.price = newVal.price,
                this.productId = newVal.productId,
                this.skuNumber = newVal.skuNumber,
                this.stock = newVal.stock,
                this.token = newVal.token,
                this.user = newVal.user,
                this.address = newVal.address
        }
    }
};
</script>

<style lang="scss" scoped></style>