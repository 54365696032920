<template>
    <TransactionLog :section="2"></TransactionLog>
</template>

<script>
import TransactionLog from './TransactionLog.vue';
export default {
    components: {
        TransactionLog
    }
};
</script>