<template>
  <div>
    <span v-html="rulesContent"></span>
  </div>
</template>

<script>



export default {
  data: function () {
    return {
      rulesContent: ''
      

    };
  },
  mounted: function () {
    this.getToken();

  },
  methods: {
    getToken() {
      // then hit API with token
      this.getAPItoken()
      .then((token) => {
          this.fetchData(token);
          this.fetchLatestDraws(token);
      });

    },
    fetchData(token) {
        var apiToken = token;
        var urlProtocol = this.getProtocol();
        var gameName = document.getElementById('gameID').value;

        return fetch(urlProtocol+process.env.VUE_APP_APIURL+"/1.0/games/drawgames/"+gameName+"/getgameinformation?getAllGameInfo=false", {
            headers: {
                'Authorization': 'Bearer '+apiToken,
            },
        })
        .then(response => response.json())
        .then(json => {
            var gameInformation = json.data;
            if(gameInformation.tab5Content.length == ''){
              this.rulesContent = gameInformation.tab4Content;
            }else{
              this.rulesContent = gameInformation.tab5Content;
            }
        })
        .catch(error => {
            // console.error("There was an error!", error);
            return this.errorMessage = error;
        });
    },    


  },
  computed: {},
};
</script>
<style lang="scss" scoped>

</style>