<template>
    <div class="title-bar hide-for-large" data-responsive-toggle="top-menu" data-hide-for="large">
        <button class="menu-icon" type="button" data-toggle="top-menu" v-on:click="toggleMobileMenu"></button>
        <!-- <button class="menu-icon" type="button" data-toggle="top-menu"></button> -->
    </div>
</template>
<script>
    // import Vue from 'vue';
    import $ from 'jquery';

    export default {
        data: function () {
            return {
                open: false
            }
        },
        mounted: function () {
            
        },
        methods: {
            toggleMobileMenu: function(event) {
                var container = $('#container');
                var winHeight = container.height();
                var winWidth = container.width();

                if (winWidth > 490) {
                    winWidth = 450;
                } else {
                    winWidth = winWidth - 40;
                }
                $('.topnav').css({
                    'min-height': winHeight,
                    'width': winWidth
                });
                if (this.open === false) {
                    this.open = true;
                    $('#top-menu').css('display', 'block !important');
                    $('#top-menu').css('left', '0');
                    $('.btn-close').css('left', '0');
                } else {
                    this.open = false;
                    $('#top-menu').css('left', '100%');
                }
                $('.menu-mobile').toggleClass('menu-open');
                $('.topnav').toggleClass('menu-open');
                $('.btn-close').css({ 'padding-left': 'calc('+ winWidth +'px)'});
                event.preventDefault();
            }
        }

    }
</script>
<style lang="scss" scoped>
</style>