<template>
    <div class="drawing-box">
        <a :href="drawing.nodeAliasPath">
            <img :src="drawing.image" alt="">
        </a>
        <a :href="drawing.nodeAliasPath" class="drawingTtl">
            {{ drawing.name }}
        </a>
        <span class="drawingPts">
            {{ drawing.price }} Points
        </span>
        <span class="drawingPeriod">
            {{ drawing.parameter3 }} - {{ drawing.parameter4 }}
        </span>
    </div>
</template>

<script>
    export default {
        props: ['drawing'],
        data: function() {
            return {
            }
        },
        mounted: function() {
            console.log(this.drawing)
        },
        methods: {
            
        }
    }
</script>