<template>
        <article>
            <div class="module rdmptnCntrl_header">
                <div class="cf moduleContent">
                    <h1 class="mlrLogoHeader"></h1>
                        <div class="bottom_scPromo">
                            <div class="text">
                                <p class="medText">Welcome to</p>
                                <p class="bigText">Redemption Central!</p>
                                <p class="smallText">Where you choose how to use your points. 
                            </p>
                            <div class="image_tech" />
                        </div>
                    </div>
                </div>
            </div>
            <LoginAndRegister v-if="!isUserLogged" :heading="loginCTA"></LoginAndRegister>
            <p id="rdcParagraph" v-if="isUserLogged">Enter to win big in our prize drawings or shop straight from our catalog for great gifts and gear. Ready to redeem? You’ve earned it!</p>
            <section v-for="cat in categories" :key="cat.name" class="module featuredDrawings cf featuredItems" v-if="isUserLogged">
                <div v-if="cat.type === 8 && cat.items.length > 0" class="module featuredDrawings cf featuredItems">
                    <div class="headerAndButton">
                        <h2>{{ cat.name }}</h2>
                        <a href="/mylotto-rewards/redemption-central/drawings/" class="btn_purpleLong hdrBtn">SEE ALL DRAWINGS</a>
                    </div>
                    <div v-if="cat.type === 8 && cat.items.length > 0" class="drawingContainer">
                        <div v-for="item in cat.items" :key="item.productId" class="drawingItemRedemption cf">
                            <a :href="item.nodeAliasPath" class="drawingTtl">
                                <img :src="item.image" alt="">
                            </a>
                            <div class="featured-drawing-text">
                                <a :href="item.nodeAliasPath" class="drawingTtl">{{ item.name }}</a>
                            </div>
                            <span class="drawingPts">{{ item.price }} points per entry</span>
                            <span class="drawingPeriod">
                                <span>Entry Period:</span>
                                {{ new Date(item.parameter2) | moment("MM/DD/YYYY") }} - {{ new Date(item.parameter3) | moment("MM/DD/YYYY") }}
                            </span>
                        </div>
                    </div>
                </div>

                <div v-if="cat.type === 4 && cat.items.length > 0" class="module featuredCatalog cf featuredItems">
                    <div class="headerAndButton">
                        <h2>{{ cat.name }}</h2>
                        <a href="/mylotto-rewards/redemption-central/catalog" class="btn_purpleLong hdrBtn">SEE FULL CATALOG</a>
                    </div>
                    <div v-if="cat.type === 4 && cat.items.length > 0" class="featuredContainer">
                        <div v-for="item in cat.items" :key="item.productId" class="productPreview cf">
                            <div class="left">
                                <a :href="item.nodeAliasPath" class="image">
                                    <img :src="item.image" alt="">
                                </a>
                            </div>
                            <div class="right">
                                <a :href="item.nodeAliasPath" class="productTitle">{{ item.name }}</a>
                                <span class="price">{{ item.price }} Points</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="cat.type === 5 && cat.items.length > 0" class="module featuredCatalog cf featuredItems">
                    <div class="headerAndButton">
                        <h2>{{ cat.name }}</h2>
                        <a href="/mylotto-rewards/redemption-central/coupons-and-lottery-cash" class="btn_purpleLong hdrBtn">SEE ALL LOTTERY CASH & COUPONS</a>
                    </div>
                    <div v-if="cat.type === 5 && cat.items.length > 0" class="featuredContainer">
                        <div v-for="item in cat.items" :key="item.productId" class="productPreview cf">
                            <div class="left">
                                <a :href="item.nodeAliasPath" class="image">
                                    <img :src="item.image" alt="">
                                </a>
                            </div>
                            <div class="right">
                                <a :href="item.nodeAliasPath" class="productTitle">{{ item.name }}</a>
                                <span class="price">{{ item.price }} Points</span>
                            </div>
                        </div>
                    </div>
                </div>


                <div v-if="cat.type === 3 && cat.items.length > 0" class="module featuredCatalog cf featuredItems">
                    <div class="headerAndButton">
                        <h2>{{ cat.name }}</h2>
                        <a href="/mylotto-rewards/redemption-central/digital-catalog" class="btn_purpleLong hdrBtn">SEE FULL DIGITAL CATALOG</a>
                    </div>
                    <div v-if="cat.type === 3 && cat.items.length > 0" class="digitalCatalog">
                        <div class="digitalImages" id="digitalCatalogue_images">
                            <a :href="item.nodeAliasPath" v-for="item in cat.items" :key="item.productId">
                                <img :src="item.image" alt="" class="digImage">
                            </a>  
                        </div>
                        <div class="digitalText">
                            <h5>
                                <a>Movies & Music</a>
                            </h5>
                            <p>
                                It's easy to turn your rewards points into movies or music from your favorite entertainment source.
                            </p>
                        </div>
                    </div>
                </div>

                <div v-if="cat.type === 4000 && cat.items.length > 0" class="module featuredCatalog cf featuredItems">
                    <div class="headerAndButton">
                        <h2>{{ cat.name }}</h2>
                        <a href="/mylotto-rewards/redemption-central/interactive-games" class="btn_purpleLong hdrBtn">SEE ALL GAMES</a>
                    </div>
                    <div class="accordianItem clearfix" id="js_add_games">
                        <div v-for="item in cat.items" :key="item.id" class="clearfix interactiveGame-box" :id="item.id">
                            <a :href="'/mylotto-rewards/redemption-central/interactive-games/IGTest?id=' + item.id + demo" class="interactiveGame-gridItem">
                                <!-- <img :src="item.mainImageUrl" alt=""> -->
                                <div class="mainImageUrl" :id="item.cssID" @mouseover="hover(item.id)" @mouseleave="exit(item.id)" />
                                <div class="ig-btns" :ref="'button' + item.id" @mouseover="hover(item.id)" @mouseleave="exit(item.id)">
                                    <div class="interactivePlay-btn red_btn" @mouseover="noDemo" v-if="isUserLogged">PLAY</div>
                                    <div class="interactiveDemo-btn red_btn" @mouseover="addDemo">DEMO</div>
                                </div>
                                <span class="interactiveGameTitle">
                                    {{ item.name }}
                                </span>
                                <span class="interactiveGameText"></span>
                                <div class="interactiveGamePoints">

                                </div>
                            </a>
                            <span class="featuredGame-info">
                                <div class="featuredinteractivegames">
                                    <a href="" class="igTtl">{{ item.name }}</a>
                                    <!-- <span class="igPrize">
                                        <span class="igPoints">
                                            <span>Plays Available: </span>

                                        </span>
                                    </span> -->
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
            </section>
        </article>
</template>

<script>
    import $ from 'jquery';
    import Foundation from "@/foundation-sites/js/foundation.js";
    import LoginAndRegister from '../../ViewComponents/SignInRegister/SignInRegister.vue';

    export default {
        components: {
            LoginAndRegister
        },
        data: function () {
            return {
                isUserLogged: false,
                loginCTA: 'Sign in to enter your non-winning ticket numbers for points or entry into second chance promotions. If you don’t have a MyLotto account yet, it’s free and easy to set up by clicking the REGISTER button below.',
                demo: '',
                categories: [
                    {
                        name: 'Featured Drawings',
                        params: '/1.0/Ecommerce/GetProductList?type=8&categoryId=9&top=4',
                        type: 8,
                        categoryId: 9,
                        top: 2,
                        items: []
                    },
                    {
                        name: 'Featured Catalog Items',
                        params: '/1.0/Ecommerce/GetProductList?type=4&categoryId=46&top=4',
                        type: 4,
                        categoryId: 9,
                        top: 4,
                        items: []
                    },
                    {
                        name: 'Lottery Cash & Coupons',
                        params: '/1.0/Ecommerce/GetProductList?type=5&categoryId=9&top=4',
                        type: 5,
                        categoryId: 0,
                        top: 4,
                        items: []
                    },
                    {
                        name: 'Digital Catalog',
                        params: '/1.0/Ecommerce/GetProductList?type=3&categoryId=46&top=2',
                        type: 3,
                        categoryId: 46,
                        top: 4,
                        items: []
                    },
                    {
                        name: 'Games for Prizes',
                        params: '/1.0/Games/InteractiveGames/GetAvailableGames?itemsPerPage=4',
                        type: 4000,
                        itemsPerPage: 2,
                        items: []
                    }
                ]
            }
        },
        mounted: function () {
            this.isUserLogged = this.isUserAuthed();            
            this.getToken();
        },
        methods: {
            getToken() {
                // then hit API with token
                this.getAPItoken().then((token) => {
                    this.fetchData(token);
                    this.token = token;
                });
            },
            fetchData(token) {
                let sections = this.categories,
                    apiToken = token;

                for (let i = 0; i < sections.length; i++) {
                    let cat = sections[i],
                        url = process.env.VUE_APP_APIURL + cat.params;

                    fetch(url, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + apiToken,
                        },
                    })
                    .then((response) => response.json())
                    .then((json) => {
                        let data = json.data;

                        if (data.games) {
                            let jsonGames = data.games,
                                games = [];

                            for (let i = 0; i < jsonGames.length; i++) {
                                let game = { ...jsonGames[i] };

                                if (game.name.indexOf('Connectr_') > -1) {
                                    game.cssID = 'connectr';
                                } else if (game.name.indexOf('Hardwood') > -1) {
                                    game.cssID = 'hardwood';
                                } else if (game.name.indexOf('Mazr') > -1) {
                                    game.cssID = 'mazr';
                                } else if (game.name.indexOf('Mirrorball') > -1) {
                                    game.cssID = 'mirrorball';
                                } else if (game.name.indexOf('Mix') > -1) {
                                    game.cssID = 'mix';
                                } else if (game.name.indexOf('Spinzy') > -1) {
                                    game.cssID = 'spinzy';
                                }

                                games.push(game);
                            }

                            sections[i].items = games;
                        } else {
                            sections[i].items = data;
                        }
                    })
                    .catch((error) => {
                        // console.error("There was an error!", error);
                        return (this.errorMessage = error);
                    });
                }

                this.categories = sections;
            },
           openPurchase() {
            let popup = new Foundation.Reveal($('#modal-checkout'));
            popup.open();
           },
           openImage() {
            let popup = new Foundation.Reveal($('#modal-image'));
            popup.open();
           },
           confirmPurchase() {
            let url = process.env.VUE_APP_APIURL + '/1.0/Ecommerce/PerformPurchase',
                body = {
                    "userId": this.user.user.userId,
                    "productId": this.productId,
                    "qty": 1
                };
            
            fetch(url, {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json-patch+json',
                    'Authorization': "Bearer " + this.token
                },
            })
            .then((response) => response.json())
            .then((json) => {
                let res = json;

                if (res.data === null && res.error != null) {
                    this.message = res.error.message;
                    this.purchase = true;
                } else {
                    this.message = "Thank you for your purchase";
                    this.purchase = true;
                }
            })
           },
           hover(id) {
                // console.log(id)
                // console.log(this.$refs['button' + id][0])
                this.$refs['button' + id][0].style.display = 'block';
            },
            exit(id) {
                // console.log(id)
                // console.log(this.$refs['button' + id][0])
                this.$refs['button' + id][0].style.display = 'none';
            },
            noDemo() {
                this.demo = '';
            },
            addDemo() {
                this.demo = '&demo=true';
            }
        },
        computed: {
        }
    };
</script>

<style lang="scss" scoped>
    #rdcParagraph {
        margin: 20px 0 40px;
    }
</style>