<template>
    <div>
        <div v-if="menu != ''" v-html="menu"></div>

        <div id="consentBox" class="grt-cookie grt-cookie-active" style="color: rgb(255, 255, 255); background: rgb(71, 71, 71);">
            <div class="grt-cookies-msg">
                <p>
                    We use cookies to ensure that we give you the best experience on our website. If you continue to use this site, 
                    you acknowledge that you accept and understand our <a href="https://walottery.com/Privacy/" target="_blank">Privacy Policy.</a>
                </p>
            </div>
            <span class="grt-cookie-button" style="background: rgb(110, 200, 41); color: rgb(255, 255, 255);" @click="acceptBtn()">Accept</span>
        </div>
    </div>
</template>
<script>
export default {
    data: function () {
        return {
            currentTime: new Date().getFullYear(),
            menu: '',
        }
    },
    mounted: function () {
        this.getMenu();

        let cookieValue = ('; '+document.cookie).split(`; GDRP_Terms_Accepted=`).pop().split(';')[0];
        const consentBox = document.getElementById("consentBox"); 

        cookieValue !== 'true' ? consentBox.classList.remove("hide") : 
            consentBox.classList.add("hide");

    },
    methods: {
        acceptBtn(){
            document.cookie = "GDRP_Terms_Accepted=true"
            if (document.cookie) { 
                consentBox.classList.add("hide"); 
            } else { 
                console.log 
                    ("Cookie can't be set! Please"+ 
                    " unblock this site from the cookie"+ 
                    " setting of your browser."); 
            } 
        },
        getToken() {
            // then hit API with token
            this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });
        },
        getMenu() {
            let menuData = JSON.parse(sessionStorage.getItem('mt_footerMenu'));

            if (menuData !== undefined && menuData !== "" && menuData !== null) {
                this.showMenu(menuData);
                
                setTimeout(() => {
                    let selector = document.querySelector("#copy-year");
                    if (selector !== null) {
                        selector.innerHTML = this.currentTime;
                    }
                }, 500);

            } else {
                this.getToken();
            }
        },
        showMenu(data) {
            let parser = new DOMParser();
            let htmlDoc = parser.parseFromString(data, 'text/html');

            this.menu = htmlDoc.body.innerHTML;
        },
        fetchData(apiToken) {
            return fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + '/1.0/Games/StaticContentElements/StaticContentElement/GetContentElementByKey?contentKey=footercontent', {
                headers: {
                    'Authorization': 'Bearer ' + apiToken
                }
            })
                .then(response => response.json())
                .then(json => {
                    let element = json.data[0].centralContent;

                    sessionStorage.setItem('mt_footerMenu', JSON.stringify(element));
                    this.showMenu(element)
                })
        }
    }
}
</script>

<style scoped lang="scss">
.hide { 
    opacity: 0; 
    pointer-events: none; 
    transition: all 0.3s ease; 
} 
</style>