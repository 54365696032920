<template>
  <div :class="activeTabTitle" class="tabs__light">
    <ul class="tabs" data-deep-link="true" data-update-history="true" data-deep-link-smudge>
      <li v-for='(tab, index) in tabs' :key='tab.title' :id='"tab" + index' :data-tab-target="tab.title"
        @click='selectTab(index, tab.title)' class="tabs-title" :class='{ "is-active": (index == selectedIndex) }'>
        <a :href='"#tab" + index' role="tab">{{ tab.title }}</a>
      </li>
    </ul>
    <slot></slot>
  </div>
</template>

<script>
export default {
  components: {

  },
  props: {
    mode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      activeTabTitle: '',
      selectedIndex: 0, // the index of the selected tab,
      tabs: []         // all of the tabs
    }
  },
  created() {
    this.tabs = this.$children;
    this.onEndVFor();
  },
  mounted() {
    // console.log(this.tabs[0].title);
    // let firstTab = this.tabs[0].title;
    //this.selectTab(0, null)

  },
  methods: {
    onEndVFor() {
      const hastagLocation = window.location.hash;
      if (hastagLocation != '') {
        setTimeout(() => {
          const id = parseInt(hastagLocation.substring(4));
          const element = document.querySelector(hastagLocation);
          const targetValue = element.getAttribute('data-tab-target');
          this.selectTab(id, targetValue);
        }, 2000);

      }
    },
    selectTab(i, tabTitle) {
      this.selectedIndex = i;
      this.activeTabTitle = tabTitle;
      // loop over all the tabs
      this.tabs.forEach((tab, index) => {
        tab.isActive = (index === i)
        // set the url hash
      })
    }
  }
}
</script>