<template>
    <nav class="left-nav-menu">
        <ul>
            <li class="menuItem">
                <a href="/" class="menuLink" :class="{menuLinkActive : parentLink === 'home'}">My Lottery 360°</a>
            </li>
            <li class="menuItem" v-if="!isUserLogged">
                <a href="/register" class="menuLink" :class="{menuLinkActive : parentLink === 'register'}">Create Account</a>
            </li>
            <li class="menuItem menu-item-has-children">
                <a href="/rewards-catalog" class="menuLink" :class="{menuLinkActive : parentLink === 'rewards-catalog'}">Rewards Catalog</a>
                <ul v-if="isCatalog" style="display:block;" class="left-menu-child-menu rewards-catalog-submenu">
                    <li class="childMenuItem showAuthed" v-if="isUserLogged">
                        <a class="childMenuLink" href="/rewards-catalog/wish-list" :class="{menuLinkActive : childLink === 'wish-list'}">Wish List</a>
                    </li>
                    <li class="childMenuItem" v-for="category in categoryList" :key="category.id">
                        <a class="childMenuLink" :href="'/rewards-catalog/'+category.codeName" :class="{menuLinkActive : toLowercase(category.codeName) === childLink}">{{ category.name }}</a>
                    </li>
                </ul>
            </li>
            <li class="menuItem">
                <a href="/enter" class="menuLink" :class="{menuLinkActive : parentLink === 'enter'}">Enter Ticket</a>
            </li>
            <li class="menuItem" v-if="isUserLogged">
                <a href="/my-purchases" class="menuLink" :class="{menuLinkActive : parentLink === 'my-purchases'}">My Purchases</a>
            </li>
            <li class="menuItem" v-if="isUserLogged">
                <a href="/transaction-history" class="menuLink" :class="{menuLinkActive : parentLink === 'transaction-history'}">Transaction History</a>
            </li>
            <li class="menuItem" v-if="isUserLogged">
                <a href="/my-profile" class="menuLink" :class="{menuLinkActive : parentLink === 'my-profile'}">My Profile</a>
            </li>
            <li class="menuItem" v-if="isUserLogged">
                <a href="/notifications" class="menuLink" :class="{menuLinkActive : parentLink === 'notifications'}">Notifications</a>
            </li>
            <li class="menuItem" v-if="isUserLogged">
                <a href="/change-password" class="menuLink" :class="{menuLinkActive : parentLink === 'change-password'}">Change Password</a>
            </li>
            
            <li class="menuItem">
                <a href="/faqs" class="menuLink" :class="{menuLinkActive : parentLink === 'faqs'}">FAQs</a>
            </li>

            <li class="menuItem menu-item-has-children">
                <a href="/help" class="menuLink" :class="{menuLinkActive : parentLink === 'help'}">Help</a>
                <ul class="left-menu-child-menu help-submenu">
                    <li class="childMenuItem">
                        <a class="childMenuLink" href="/help/achievements-rewards" :class="{menuLinkActive : childLink === 'achievements-rewards'}">Achievements &amp; Rewards</a>
                    </li>
                    <li class="childMenuItem">
                        <a class="childMenuLink" href="/help/player-account" :class="{menuLinkActive : childLink === 'player-account'}">Player Account</a>
                    </li>
                    <li class="childMenuItem">
                        <a class="childMenuLink" href="/help/eligible-tickets" :class="{menuLinkActive : childLink === 'eligible-tickets'}">Eligible Tickets</a>
                    </li>
                    <li class="childMenuItem">
                        <a class="childMenuLink" href="/help/my-lottery-360°-contact-info" :class="{menuLinkActive : childLink === 'my-lottery-360%C2%B0-contact-info'}">My Lottery 360° Contact Info</a>
                    </li>
                    <li class="childMenuItem">
                        <a class="childMenuLink" href="/help/ticket-scanning" :class="{menuLinkActive : childLink === 'ticket-scanning'}">Ticket Scanning</a>
                    </li>
                    <li class="childMenuItem">
                        <a class="childMenuLink" href="/help/ticket-entry" :class="{menuLinkActive : childLink === 'ticket-entry'}">Ticket Entry</a>
                    </li>
                    <li class="childMenuItem">
                        <a class="childMenuLink" href="/help/manual-entry" :class="{menuLinkActive : childLink === 'manual-entry'}">Manual Entry</a>
                    </li>
                    <li class="childMenuItem">
                        <a class="childMenuLink" href="/help/feedback" :class="{menuLinkActive : childLink === 'feedback'}">Feedback</a>
                    </li>
                    <li class="childMenuItem">
                        <a class="childMenuLink" href="/help/official-rules" :class="{menuLinkActive : childLink === 'official-rules'}">Official Rules</a>
                    </li>
                    <li class="childMenuItem">
                        <a class="childMenuLink" href="/help/my-lottery-360°-privacy-policy" :class="{menuLinkActive : childLink === 'my-lottery-360%C2%B0-privacy-policy'}">My Lottery 360° Privacy Policy</a>
                    </li>
                </ul>
            </li>
        </ul>
        
    </nav>
</template>


<script>

    import $ from 'jquery';

    export default {
        components: {

        },
        data: function () {
            return {
                isUserLogged: false,
                categoryList: [],
                parentLink: '',
                childLink: '',
                isCatalog: false
            }
        },
        mounted: function () {
            this.isUserLogged = this.isUserAuthed();
            this.getToken();

            this.determineTopMenuItem();
            this.determineChildMenuItem();
        },
        methods: {
            getToken() {
                // then hit API with token
                this.getAPItoken().then((token) => {
                    let sessionCategories = sessionStorage.getItem('categoryList');

                    if (sessionCategories) {
                        this.categoryList = JSON.parse(sessionCategories);
                    } else {
                        this.fetchCategoryList(token);
                    }

                    this.token = token;
                });
            },
            fetchCategoryList(token) {
                let apiToken = token,
                    url = process.env.VUE_APP_APIURL + '/1.0/Ecommerce/GetCategoryList';

                return fetch(url, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + apiToken,
                        },
                    }
                )
                .then((response) => response.json())
                .then((json) => {
                    let data = json.data;
                    this.categoryList = data;
                    // Hit mixin for category images -- then (mixin yet to be created)
                    // Add categories to session storage
                    sessionStorage.setItem('categoryList', JSON.stringify(data));
                })
                .catch((error) => {
                    // console.error("There was an error!", error);
                    return (this.errorMessage = error);
                });
            },

            determineTopMenuItem() {
                let getPageTitle = window.location.pathname.toString();
                let parentPage = getPageTitle.substring((getPageTitle.indexOf('/') + 1, getPageTitle.lastIndexOf('/')))
                parentPage = parentPage.substring(1);
                // console.log(getPageTitle);
                this.parentLink = parentPage;

                if( getPageTitle.includes("help") ){
                    // this.parentLink = "help"
                    document.getElementsByClassName("help-submenu")[0].style.display = "block";
                    document.getElementsByClassName("help-submenu")[0].parentElement.classList.add('menuItemActive');

                }
                if( getPageTitle.includes("rewards-catalog") ){
                    this.isCatalog = true;
                    this.parentLink = "rewards-catalog"
                    // document.getElementsByClassName("rewards-catalog-submenu")[0].style.display = "block";
                    // document.getElementsByClassName("rewards-catalog-submenu")[0].parentElement.classList.add('menuItemActive');
                    $("rewards-catalog-submenu").addClass('menuItemActive');
                    $("rewards-catalog-submenu").parent().addClass('menuItemActive');
                }

                // console.log("Parent Active Link: " + this.parentLink);
                
            },
            determineChildMenuItem() {
                let getPagePath = window.location.pathname.toString()
                let childPage = getPagePath.substring((getPagePath.lastIndexOf('/') + 1))
                // console.log(getPagePath);
                // console.log(childPage);
                this.childLink = childPage;
                // console.log("Active child link: " + this.childLink);
            },
            toLowercase(string) {
                let lowercasestring = string.toLowerCase();
                return lowercasestring;
            }
        },
        computed: {
        
        }
    }
</script>