<template>
    <div class="">
    </div>
    
</template>

<script>

    export default {
        components: {
        },
        data: () => ({
        }),
        mounted: function() {
        },
        methods: {
        }
    };
</script>


<style lang="scss" scoped>
    
</style>