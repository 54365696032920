var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',[(_vm.loaded)?_c('span',[_c('section',{staticClass:"callout thickPadding"},[_c('h1',{staticClass:"productName"},[_vm._v(" "+_vm._s(_vm.name)+" ")]),_c('section',[_c('section',{staticClass:"productImg cf callout light boxShadow"},[_c('a',{staticClass:"enalrgeProductImg productDetailImg",attrs:{"title":"Title Here","target":"_blank"},on:{"click":_vm.openImage}},[_c('img',{attrs:{"src":_vm.image,"alt":""}})])]),_c('section',{staticClass:"productOrderForm cf"},[_c('div',{staticClass:"CartItemSelectorContainer"},[_c('div',{staticClass:"TotalPriceContainer callout light"},[_c('div',{staticClass:"TotalPriceQuantity",class:{'notLogged': !_vm.isUserLogged}},[(_vm.isUserLogged)?_c('p',[_vm._v("Qty")]):_vm._e(),(_vm.isUserLogged)?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.qtySelected),expression:"qtySelected"}],staticClass:"input-select",attrs:{"name":"quantity","id":"","disabled":!_vm.pointsAvailable || _vm.stock === 0},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.qtySelected=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.handlePrice]}},[(!_vm.pointsAvailable || _vm.stock === 0)?_c('option',{attrs:{"value":"0"}},[_vm._v("0")]):_vm._e(),_vm._l((_vm.options),function(option){return _c('option',{key:option,domProps:{"value":option}},[_vm._v(_vm._s(option))])})],2):_vm._e(),_c('span',{staticClass:"TotalPrice"},[_vm._v(_vm._s(_vm.price.toLocaleString())+" Points")])])]),_c('div',{staticClass:"AddToCartContainer add-to-card-container control-group-inline"},[(_vm.coupon && _vm.pointsAvailable)?_c('DetailCoupon',{attrs:{"data":{
                                isUserLogged: this.isUserLogged,
                                userPoints: this.userPoints,
                                pointsAvailable: this.pointsAvailable,
                                category: this.category,
                                description: this.description,
                                image: this.image,
                                name: this.name,
                                parameter1: this.parameter1,
                                parameter2: this.parameter2,
                                parameter3: this.parameter3,
                                parameter4: this.parameter4,
                                parameter5: this.parameter5,
                                qty: this.qtySelected,
                                price: this.price,
                                productId: this.productId,
                                skuNumber: this.skuNumber,
                                stock: this.stock,
                                token: this.token,
                                user: this.user,
                                address: this.address
                            }}}):_vm._e(),(_vm.stock === 0)?_c('p',[_vm._v("This product is currently out of stock.")]):_vm._e(),(!_vm.pointsAvailable && _vm.isUserLogged)?_c('p',{staticClass:"productDetail-points-error"},[_vm._v("You do not have enough points to complete this purchase")]):_vm._e(),(!_vm.isUserLogged)?_c('p',[_vm._v("Log in or create an account to purchase this item")]):_vm._e(),(!_vm.isUserLogged)?_c('button',{staticClass:"button btn-primary btn_popupLogIn",on:{"click":_vm.login}},[_vm._v("Log In")]):_vm._e(),(!_vm.isUserLogged)?_c('a',{staticClass:"button btn btn-secondary",attrs:{"href":"/register"}},[_vm._v("Create Account")]):_vm._e()],1)])]),_c('section',{staticClass:"productInformation"},[_c('h2',[_vm._v("Product Information")]),_c('section',{staticClass:"productDescription",domProps:{"innerHTML":_vm._s(_vm.description)}}),_c('div',{staticClass:"reveal modal-image reveal-modal",attrs:{"id":"modal-image","data-reveal":""}},[_c('section',{staticClass:"shoppingCartImage"},[_c('picture',{staticClass:"productImg"},[_c('img',{staticClass:"fullSizeImg",attrs:{"src":_vm.image}})])])])])])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }