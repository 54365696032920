<template>
    <div>
        <HollowDotsSpinner v-if="isLoading" color="#0FD8C9" class="home-banner-loader" />

        <VueSlickCarousel v-if="!isLoading" v-bind="settings" :class="{ 'slick-slider--no-dots': !settings.dots }"
            @beforeChange="beforeSlideChange" ref="carousel">
            <div v-for="(slide, index) in bannerSlides" :key="slide.bannerSlideID">

                <div v-if="slide.bannerType == 'standard'"
                    :class="{ first: isFirst(index), 'slide-wrapper': true, [slide.bannerType]: true, [slide.bannerLayout]: true, [slide.bannerClass]: true }">
                    <component :is="slide.bannerLinkURL ? 'a' : 'div'" :href="slide.bannerLinkURL"
                        :target="slide.bannerLinkType" class="slick-bannerLink">
                        <div class="bg-img bg-img-desktop"
                            :style="{ 'background-image': slide.bannerImageBackgroundUrl ? 'url(' + slide.bannerImageBackgroundUrl + ')' : '' }">
                            <div class="bg-img bg-img-mobile"
                                :style="{ 'background-image': slide.bannerImageBackgroundMobileUrl ? 'url(' + slide.bannerImageBackgroundMobileUrl + ')' : '' }">
                                <div class="banner-container">
                                    <img class="bannerImg show-for-medium" :src="slide.bannerImageForegroundUrl"
                                        alt="banner image" v-if="slide.bannerImageForegroundUrl">
                                    <img class="bannerImg hide-for-medium" :src="slide.bannerImageForegroundMobileUrl"
                                        alt="banner image" v-if="slide.bannerImageForegroundMobileUrl">
                                    <p class="bannerText" v-if="slide.bannerText">{{ slide.bannerText }}</p>
                                    <div class="bannerCustomCode" v-if="slide.bannerCustomCode"
                                        v-html="slide.bannerCustomCode"></div>
                                </div>
                            </div>
                        </div>
                    </component>
                </div>

                <div v-else-if="slide.bannerType === 'custom'" class="slide-wrapper" :class='slide.bannerType'>
                    <div class="bg-img bg-img-desktop"
                        :style="{ 'background-image': 'url(' + slide.bannerImageBackgroundUrl + ')' }">
                        <div class="bg-img bg-img-mobile"
                            :style="{ 'background-image': 'url(' + slide.bannerImageBackgroundMobileUrl + ')' }">
                            <div v-html="slide.bannerCustomCode" class="bannerCustom"></div>
                        </div>
                    </div>
                </div>

                <div v-else-if="slide.bannerType == 'jackpot' && slide.bannerPrizePool >= slide.bannerThreshold"
                    class="slide-wrapper" :class='[slide.bannerType, slide.bannerClass]'>

                    <component :is="slide.bannerLinkURL ? 'a' : 'div'" :href="slide.bannerLinkURL"
                        :target="slide.bannerLinkType" class="slick-bannerLink">

                        <div class="bg-img bg-img-desktop"
                            v-bind:style="{ 'background-image': 'url(' + slide.bannerImageBackgroundUrl + ')' }">

                            <div class="bg-img bg-img-mobile"
                                v-bind:style="{ 'background-image': 'url(' + slide.bannerImageBackgroundMobileUrl + ')' }">

                                <div class="banner-container">

                                    <img class="jackpot-image show-for-medium" :src="slide.bannerImageForegroundUrl"
                                        alt="banner image" v-if="slide.bannerImageForegroundUrl">
                                    <img class="jackpot-image hide-for-medium" :src="slide.bannerImageForegroundMobileUrl"
                                        alt="banner image" v-if="slide.bannerImageForegroundMobileUrl">

                                    <div class="jackpot-text">
                                        <!-- Dynamic Jackpot Banner -->
                                        <span v-if="slide.bannerPrizePool == 'MegaMillions'">PrizePool Present</span>
                                        <span>{{ slide.bannerPrizePool }}</span>
                                        <span>{{ slide.bannerJackpotType }}</span>
                                        <div class="bannerCustomCode" v-html="slide.bannerCustomCode"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </component>
                </div>

                <div v-else-if="slide.bannerType === 'video'" class="slide-wrapper"
                    :class='[slide.bannerType, slide.bannerClass]'>
                    <div class="video-container" ref="container">
                        <youtube :video-id="slide.bannerVideoID" :player-width="containerWidth"
                            :player-height="containerWidth * 0.5625" :player-vars="playerVars"
                            class="video-container-youtube" ref="youtube"></youtube>
                    </div>
                </div>

            </div>
        </VueSlickCarousel>
    </div>
</template>


<script>

// import $ from 'jquery';
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import YouTube from 'vue-youtube-embed';
import { HollowDotsSpinner } from 'epic-spinners'

export default {
    components: {
        VueSlickCarousel,
        HollowDotsSpinner,
        YouTube
    },
    data() {
        const bannerGroup = document.querySelector("#bannerSlickGroup").value;
        const showBullets = document.querySelector("#bannerSlickShowBullets").value === "True";
        const showControls = document.querySelector("#bannerSlickShowControls").value === "True";

        return {
            megaThreshold: 200,
            isLoading: true,
            bannerSlides: [],
            slides: [],
            single: true,
            double: false,
            thresholds: "0,0",
            errorMessage: 'Banners currently not available. Please try again later.',
            bannerGroup: bannerGroup,
            bannerAForeground: '',
            bannerABackground: '',
            bannerBForeground: '',
            bannerBBackground: '',
            settings: {
                arrows: showControls,
                dots: showBullets,
                speed: 300,
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 0,
                waitForAnimate: false,
                accessibility: true,
                draggable: true,
            },
            isVideoPlaying: false,
            containerWidth: null,
            playerVars: {
                autoplay: 0,
                controls: 1,
                modestbranding: 1,
                rel: 0,
                showinfo: 0,
                fs: 1
            },
            currentSlideIndex: 0,
        };
    },

    mounted() {
        // console.log(showBullets);
        this.getToken();

        this.setContainerWidth();
        window.addEventListener('resize', this.setContainerWidth);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.setContainerWidth);
    },
    methods: {
        getToken() {
            // then hit API with token
            this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });
        },
        fetchData(token) {
            let apiToken = token,
                bannerId = this.bannerGroup;
            // console.log(bannerId);
            return fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + "/1.0/Games/BannerManager/BannerManager/GetBannerGroupByKey?bannerIdentifier=" + bannerId, {
                headers: {
                    'Authorization': 'Bearer ' + apiToken,
                },
            })
                .then(response => response.json())
                .then(json => {
                    let res = json.data;

                    for (let i = 0; i < res.bannerSlides.length; i++) {
                        let banner = res.bannerSlides[i];
                        this.bannerSlides.push(banner);
                    }

                    this.isLoading = false;


                })
                .catch(error => {
                    return this.errorMessage = error;
                });
        },
        getJackpots(token, banners) {
            let rawSlides = banners;

            for (let i = 0; i < rawSlides.length; i++) {
                let banner = rawSlides[i];

                if (banner.bannerType === 'jackpot') {
                    let apiToken = token;

                    let games = banner.bannerJackpotID;

                    games = games.split("|");

                    this.thresholds = games[1];
                    let threshold = this.thresholds.split(",");

                    games = games[0].split(",");

                    if (games.length < 2) {
                        return fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + "/1.0/Games/DrawGames/" + banner.bannerJackpotID + "/GetLatestDraws", {
                            headers: {
                                'Authorization': 'Bearer ' + apiToken,
                            },
                        })
                            .then(response => response.json())
                            .then(json => {
                                let res = json.data;

                                banner.bannerThreshold = this.thresholds[0];

                                banner.bannerPrizePool = res[0].nextPrizePool;

                                banner.bannerPrizePool <= 999999999 ? banner.bannerJackpotType = 'Million' : banner.bannerJackpotType = 'Billion';

                                if (banner.bannerPrizePool > 999999999) {
                                    banner.bannerPrizePool = (banner.bannerPrizePool / 1000000000).toFixed(2);
                                } else {
                                    banner.bannerPrizePool = (banner.bannerPrizePool / 1000000);
                                }

                                this.bannerSlides.push(banner);
                            })
                            .catch(error => {
                                return this.errorMessage = error;
                            });

                    } else if (games.length > 1) {

                        // I think we need to check the nextPrizePool against the threshold values for both games, before we decide if we're showing a combined banner or not. If both are over threshold then bannerType: 'multiple-jackpot'
                        // otherwise treat it like bannerType: 'jackpot'

                        // can we also get the game info from store?

                        let gameBanners = {
                            bannerType: 'multiple-jackpot',
                            bannerSlides: []
                        };

                        for (let i = 0; i < games.length; i++) {
                            let game = { ...banner };
                            game.bannerJackpotID = games[i];
                            game.bannerThreshold = threshold[i];

                            // fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + "/1.0/Games/DrawGames/" + game.bannerJackpotID + "/GetLatestDraws", {
                            fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + "/1.0/Games/DrawGames/" + game.bannerJackpotID + "/GetGameInformation?getAllGameInfo=false", {
                                headers: {
                                    'Authorization': 'Bearer ' + apiToken,
                                },
                            })
                                .then(response => response.json())
                                .then(json => {
                                    let data = json.data,
                                        res = data.draws;

                                    game.bannerPrizePool = res[0].nextPrizePool;
                                    game.schedule = data.drawSchedule;

                                    // TESTING ONLY --
                                    // game.bannerPrizePool < 1000000 ? game.bannerPrizePool = 1100000 : false;
                                    if (game.bannerPrizePool >= game.bannerThreshold) {
                                        if (game.bannerPrizePool >= 999999999) {
                                            game.bannerPrizePool = (game.bannerPrizePool / 1000000000).toFixed(2);
                                            game.bannerJackpotType = 'Billion';
                                        } else if (game.bannerPrizePool) {
                                            game.bannerPrizePool = (game.bannerPrizePool / 1000000);
                                            game.bannerJackpotType = 'Million';
                                        }

                                        if (game.bannerJackpotID === 'Powerball') {
                                            game.mega = false;
                                        } else {
                                            game.mega = true;
                                        }

                                        // console.log('banner')
                                        // console.log(game)

                                        gameBanners.bannerSlides.push(game);
                                        // console.log(gameBanners)
                                    }

                                    if (i === games.length - 1) {
                                        // console.log(gameBanners.bannerSlides.length)
                                        if (gameBanners.bannerSlides.length === 1) {
                                            this.single = true;
                                            this.double = false;
                                        } else if (gameBanners.bannerSlides.length === 2) {
                                            this.single = false;
                                            this.double = true;
                                        }

                                        if (gameBanners.bannerSlides.length > 0) {
                                            this.bannerSlides.push(gameBanners);

                                            setTimeout(() => {
                                                this.bannerAForeground = document.getElementById('banner-a-foreground').value;
                                                this.bannerABackground = document.getElementById('banner-a-background').value;
                                                this.bannerBForeground = document.getElementById('banner-b-foreground').value;
                                                this.bannerBBackground = document.getElementById('banner-b-background').value;
                                            }, 500);
                                        } else {
                                            // console.log(' === No Appropriate Banners ===')
                                        }
                                    }
                                })
                                .catch(error => {
                                    return this.errorMessage = error;
                                });
                        }
                    }
                } else {
                    this.bannerSlides.push(banner);
                }
            }
            this.isLoading = false;
            return this.bannerSlides;
        },
        isFirst(index) {
            if (index === 0) {
                return true;
            }
            return false;
        },
        setContainerWidth() {
            const container = this.$refs.container;
            if (container) {
                this.containerWidth = container.offsetWidth;
            }
        },

        beforeSlideChange(oldSlideIndex, newSlideIndex) {
            const previousSlide = this.bannerSlides[oldSlideIndex];
            if (previousSlide.bannerType === 'video') {
                const slideWrappers = Array.from(this.$refs.carousel.$el.querySelectorAll('.slick-slide'));
                const prevVideoSlide = slideWrappers.find(slide => slide.dataset.index === oldSlideIndex.toString())

                if (prevVideoSlide) {
                    const youtubeComponents = this.$refs.youtube;
                    youtubeComponents.$parent.$children.forEach(youtubeComponent => {
                        if (youtubeComponent && youtubeComponent.$options.propsData.videoId === previousSlide.bannerVideoID) {
                            youtubeComponent.player.pauseVideo();
                        }
                    });
                }
            }
        }
    }
}
</script>
<style lang="scss" scoped></style>
