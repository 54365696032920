<template>
    <section class="tabbed_content fluid_tabs cf" id="TransactionLogPage">
        <div class="mlrUserCouponDetail">
            <div class="couponDetailHeader">
                <div class="couponDetailBarCode">
                    <img v-if="product.barcodeBase64" :src="'data:image/png;base64,' + product.barcodeBase64" alt="Coupon Barcode" class="img-responsive">
                    <p>{{ product.serialNumber }}</p>
                </div>
                <div class="couponDetailInfo">
                    <h3>{{ product.associatedProductName }}</h3>
                    <span>Expires on {{ product.expiresOn }}</span>
                </div>
            </div>
            <div class="couponDetailBody">
                <p></p>
                <h4>
                    Lottery Cash can only be used in a single transaction. Any unused balance will be forfeited.
                </h4>
                <p>
                    <strong>
                        Lottery Cash expires on {{ product.expiresOn }}
                    </strong>
                </p>
                <p>
                    <strong>
                        Terms and Conditions:
                    </strong>
                    <br>
                    Your purchase, use, or acceptance of Lottery Cash constitutes acceptance of the following terms and conditions. This Lottery Cash is issued by and represents an obligation solely of the Ohio Lottery. Lottery Cash is valid at any participating, licensed Ohio Lottery retailer.
                    <strong>
                        If you do not use the entirety of the Lottery Cash, you forfeit the remaining balance.
                    </strong>
                     It will not be issued via cash or voucher. Lottery Cash has no cash value. It may not be sold or transferred. Lottery Cash will become void if duplicated. The Ohio Lottery cannot replace the value of the Lottery Cash if it is lost or stolen. Lottery Cash cannot be replaced if merchandise is returned. Once the Lottery Cash is redeemed, the Lottery Cash holder may not redeem it again, even if the Lottery Cash holder retains a copy of the Lottery Cash in a physical or digital format. Lottery Cash may only be used once.
                </p>
                <p>
                    Additional exclusions may apply. See store for details.
                </p>
                <p></p>
                <h5>
                    Cashier Instructions:
                </h5>
                <p>
                    Scan the barcode in the body of the transaction or key in the number beneath the barcode.
                </p>
            </div>
        </div>
        <div class="couponDetailBtns">
            <button class="btn_purple button printCoupon" @click="print">PRINT LOTTERY CASH/COUPON</button>
        </div>
    </section>
</template>

<script>
    // import $ from 'jquery';
    import moment from "moment";

    export default {
        data: function () {
            return {
                mobileCashing: false,
                token: '',
                product: ''
            }
        },
        mounted: function () {
            this.getToken();
        },
        methods: {
            getToken() {
                this.getAPItoken()
                .then((token) => {
                    this.token = token;
                    this.user = JSON.parse( sessionStorage.getItem('apiToken'));
                    this.getOrder();
                });
            },
            getOrder() {
                let apiToken = this.token,
                    sku = this.getURLQuery('id'),
                    redeem = this.getURLQuery('redeem'),
                    url = process.env.VUE_APP_APIURL + '/1.0/Ecommerce/GetUserCouponList?userId=' + this.user.user.userId + '&isRedeemed=' + redeem + '&categoryId=0';

                return fetch(url, {
                        method: 'GET',
                        // body: JSON.stringify(datCreds),
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + apiToken,
                        },
                    }
                )
                .then((response) => response.json())
                .then((json) => {
                    let orders = json.data;
                    
                    for (let i = 0; i < orders.length; i++) {
                        let data = orders[i];

                        data.associatedOrderId === parseInt(sku) ? this.product = data : false;
                    }
                })
                .catch((error) => {
                    // console.error("There was an error!", error);
                    return (this.errorMessage = error);
                });

                // let apiToken = this.token,
                //     red = redeemed,
                //     url = process.env.VUE_APP_APIURL + '/1.0/Ecommerce/GetUserCouponList?userId=' + this.user.user.userId + '&isRedeemed=' + red + '&categoryId=0';

                // return fetch(url, {
                //         method: 'GET',
                //         // body: JSON.stringify(datCreds),
                //         headers: {
                //             'Content-Type': 'application/json',
                //             'Authorization': "Bearer " + apiToken,
                //         },
                //     }
                // )
                // .then((response) => response.json())
                // .then((json) => {
                //     let d = json.data;
                // })
                // .catch((error) => {
                //     // console.error("There was an error!", error);
                //     return (this.errorMessage = error);
                // });
            },
            print() {
                window.print();
            }
		}
    };
</script>