<template>
    <div class="user-login-wrapper">
        <!-- <div class="user-login">
            <div v-if="show">
                <a class="signoutLink" href="#" @click="logout">Sign Out</a>
                <a class="btn_myAccount" href="#" @click="$refs.userAccount.openModal()">My Account</a>
            </div>

            <div v-if="!show">
                <a href="#" class="link" @click="$refs.loginModal.openModal();login=true">Sign In</a>
                <a href="#" class="link" @click="$refs.registerModal.openModal()">Join now</a>
            </div>
        </div>

        <modal ref="loginModal">
            <div v-if="login" v-slot:header>
                <h3>Sign In</h3>
            </div>

            <div v-if="login" v-slot:body>
                    <h4>Enter your email address and password.</h4>
                    <div class="error" v-if="showErrors"><p>{{errorMessage}}</p></div>
                    <label>Email
                        <input type="email" name="email" placeholder="Email Address" required>
                    </label>
                    <label>Password
                        <input type="password" name="password" placeholder="Password" required>
                    </label>
                    <p><input type="submit" class="button expanded" value="Sign in" @click="submit" /></p>
                    <p><a>Forgot your password?</a></p>
            </div>

            <div v-if="disclaimer" v-slot:body>
                    <div class="error" v-if="showErrors"><p>{{errorMessage}}</p></div>
                    <div id="loginDisclaimers">
                        <div class="disclaimerScroll" id="disclaimerScroll">
                            
                        </div>
                    </div>
                    <div class="disclaimersGroup">
                        <input type="checkbox" id="disclaimers">
                        <label for="disclaimers" class="userRegCheckLbl">
                            I have read and agree to the above statements and Terms & Conditions.
                        </label>
                    </div>
            </div>
        </modal>

        <modal ref="userAccount">
            <div v-slot:header>
                <h3>User Information</h3>
            </div>

            <div v-slot:body>
                    <p><b>Username:</b> {{firstname}}</p>
                    <p><b>Lastname:</b> {{lastname}}</p>
                    <p><b>Email:</b> {{username}}</p>
                    
                
            </div>
        </modal>

        <modal ref="registerModal">
            <div v-slot:header>
                <h3>Join Now!</h3>
            </template>

            <div v-slot:body>
                    <h4>Enter your email address and password.</h4>
                    <div class="error" v-if="showErrors"><p>{{errorMessage}}</p></div>
                    <label>Email
                        <input type="email" name="email" placeholder="Email Address" required>
                    </label>
                    <label>Password
                        <input type="password" name="password" placeholder="Password" required>
                    </label>
                    <label>Confirm Password
                        <input type="password" name="confirm-password" placeholder="Confirm Password" required>
                    </label>
                    <label>Firstname
                        <input type="text" name="firstname" placeholder="Firstname" required>
                    </label>
                    <label>Lastname
                        <input type="text" name="lastname" placeholder="Lastname" required>
                    </label>
                    <label>Age
                        <input type="text" name="age" placeholder="Age" required>
                    </label>
                    <label>Phone Number
                        <input type="tel" name="phone" placeholder="Phone Number" required>
                    </label>
                    <p><input type="submit" class="button expanded" value="Register" /></p>
                
            </div>
        </modal> -->


    </div>
</template>


<script>
    // import Modal from '../../ViewComponents/Modal';

    export default {
        components: {
            // Modal
        },
        data: function () {
            return {
                show: false,
                showErrors: false,
                errorMessage: '',
                username: '',
                password: '',
                firstname: '',
                lastname: '',
                login: false,
                instant: false,
                disclaimer: false
            }
        },
        mounted: function () {
            console.log('File Loaded');
        },
        methods: {
            submit: async function(){
                console.log('file');
                let username = document.querySelector("input[name=email]").value
                let password = document.querySelector("input[name=password]").value
                let protocol = this.getProtocol()

                let loginCreds = {
                    "userName": username,
                    "password": password,
                    "refreshToken": "string",
                    "newTermsAndConditionsAccepted": true
                }

                // no session created or is outdated - get new token
                return fetch(protocol+process.env.VUE_APP_APIURL+'/1.0/Authentication/Login', {
                // return fetch(protocol+'apisolutions-lottery-dev.mtllc.com/1.0/Authentication/Login', {
                    method: 'POST',
                    body: JSON.stringify(loginCreds),
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                    }
                })
                .then(response => {
                    var resStatus = response.status;
                    // console.log('response: '+response.ok+' resStatus: '+resStatus);
                    if (response.ok) {
                        // this.$store.dispatch('setCurrentUser', {
                        //     username: userName
                        // });
                        this.login = false;
                        console.log(response);
                        return response.json();
                    } else {
                        console.log('token error: '+response+' resStatus: '+resStatus);
                        throw new Error('Data API access temporarily down. Please try again later.');
                    }
                })
                .then(response => {
                    var tokenObj = {};
                    tokenObj = response.data;
                    console.log('fresh token: '+tokenObj.token);
                    console.log(response)

                    if( response.data.isAuthenticated ){
                        sessionStorage.setItem('apiToken', tokenObj);
                        sessionStorage.setItem('tokenExpires', "10d");
                        // this.$refs.loginModal.closeModal();
                        
                        if(response.data.notification.type === 1) {
                            this.instant = true;
                        }else{
                            this.disclaimer = true;
                        }
                        this.show = true;
                        if(this.showErrors){
                            this.showErrors = false;
                        }
                        this.username = username;
                        this.password = password;
                        this.firstname = response.data.user.firstName;
                        this.lastname = response.data.user.lastName;
                        
                        return tokenObj.token;
                    }else{
                        this.show = false;
                        this.showErrors = true;
                        this.errorMessage = response.data.message
                    }
                    
                })
                .catch(error => {
                    console.log('Token fetch '+error);
                    return error;
                });
            },
            logout: async function(){
                let protocol = this.getProtocol()
                let logoutCreds = {
                    "username": this.username,
                    "password": this.password,
                    "refreshToken": "string"
                }

                return fetch(protocol+process.env.VUE_APP_APIURL+'/1.0/Authentication/Logout', {
                    method: 'POST',
                    body: JSON.stringify(logoutCreds),
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                    }
                }).then(response => {
                    var resStatus = response.status;
                    // console.log('response: '+response.ok+' resStatus: '+resStatus);
                    if (response.ok) {
                        return response.json();
                    } else {
                        console.log('token error: '+response+' resStatus: '+resStatus);
                        throw new Error('Data API access temporarily down. Please try again later.');
                    }
                })
                .then(response => {
                    var tokenObj = {};
                    tokenObj = response.data;
                    console.log('fresh token: '+tokenObj.token);
                    console.log(response)
                    this.show = false;
                    
                    
                })
                .catch(error => {
                    console.log('Error Logout '+error);
                    return error;
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
</style>