<template>
    <section>
        <h2>More Ways to Redeem</h2>
        <div class="orbit" role="region" data-orbit data-options="animInFromLeft:fade-in; animInFromRight:fade-in; animOutToLeft:fade-out; animOutToRight:fade-out;">
            <div class="orbit-wrapper">
                <div class="orbit-controls">
                    <input id="showBullets" name="showBullets" type="hidden" value="True">
                </div>
                <ul class="orbit-container redemption_ctas_slider">
                    <li class="orbit-slide is-active" data-slide="0">
                        <figure class="orbit-figure">
                            <div class="btn-redemption btn-playForPrizes">
                                <div alt="" class="img-responsive orbit-image" id="redemption-catalog-image" />
                                <p class="redemption_title">Games for Prizes</p>
                                <p class="redemption_txt">Play online games to instantly win points and gift cards. Games start at 5 points per play.</p>
                                <a class="button" href="/MyLotto-Rewards/Redemption-Central/Games-for-Prizes">Play Now</a>
                            </div>
                        </figure>
                    </li>
                    <li class="orbit-slide" data-slide="1">
                        <figure class="orbit-figure">
                            <div class="btn-redemption btn-enterDrawings">
                                <div alt="" class="img-responsive" id="redemption-drawings-image" />
                                <p class="redemption_title">Drawings</p>
                                <p class="redemption_txt">Enter drawings for amazing prizes. Drawings start at 5 points per entry.</p>
                                <a class="button" href="/myLotto/Redemption-Central/Drawings">Enter Now</a>
                            </div>
                        </figure>
                    </li>
                    <li class="orbit-slide" data-slide="2" v-if="stock">
                        <div class="btn-redemption btn-browseCatalog">
                            <img alt="Digital Catalog Offerings" id="redemption-digitalCatalog-image" class="img-responsive" src="/ohiolottery/media/ContentImages/MyLottoRewards/redemptionSlider_DigitalCatalog-400x254.png" />
                            <p class="redemption_title">Digital Catalog</p>
                            <p class="redemption_txt">Redeem points for gift cards or physical rewards. Catalog items start at 1,000 points.</p>
                            <a class="button" href="/MyLotto-Rewards/Redemption-Central/Digital-Catalog">Shop Now</a>
                        </div>
                    </li>
                </ul>
                <nav class="orbit-bullets">
                    <button class="is-active" data-slide="0"></button>
                    <button data-slide="1"></button>
                    <button data-slide="2"></button>
                </nav>
            </div>
        </div>

        <div class="redemption_ctas_desktop">
            <div class="redemption_ctas_slider">
                <div class="btn-redemption btn-playForPrizes">
                    <div alt="" class="img-responsive" id="redemption-catalog-image" />
                    <p class="redemption_title">Games for Prizes</p>
                    <p class="redemption_txt">Play online games to instantly win points and gift cards. Games start at 5 points per play.</p>
                    <a class="button" href="/MyLotto-Rewards/Redemption-Central/Games-for-Prizes">Play Now</a>
                </div>

                <div class="btn-redemption btn-enterDrawings">
                    <div alt="" class="img-responsive" id="redemption-drawings-image" />
                    <p class="redemption_title">Drawings</p>
                    <p class="redemption_txt">Enter drawings for amazing prizes. Drawings start at 5 points per entry.</p>
                    <a class="button" href="/myLotto/Redemption-Central/Drawings">Enter Now</a>
                </div>

                <div class="btn-redemption btn-browseCatalog" v-if="stock">
                    <div alt="Digital Catalog Offerings" class="img-responsive" id="redemption-digitalCatalog-image" />
                    <p class="redemption_title">Digital Catalog</p>
                    <p class="redemption_txt">Redeem points for gift cards or physical rewards. Catalog items start at 1,000 points.</p>
                    <a class="button" href="/MyLotto-Rewards/Redemption-Central/Digital-Catalog">Shop Now</a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import Foundation from "@/foundation-sites/js/foundation.js";

    export default {
        components:{
        },
        data: function () {
            return {
                token: '',
                stock: false
            }
        },
        mounted: function () {
            this.isUserLogged = this.isUserAuthed();            
            this.getToken();
        },
        methods: {
            getToken() {
                // then hit API with token
                this.getAPItoken().then((token) => {
                    // this.fetchData(token);
                    this.token = token;
                    this.getStock();
                });
            },
            setStock() {
                let stock = document.getElementsByClassName('productItem');

                stock.length < 1 ? this.stock = false : false;
                
                this.stock === false ? this.getStatic() : false;
            },
            getStock() {
                var apiToken = this.token,
                    url = process.env.VUE_APP_APIURL + '/1.0/Ecommerce/GetProductList?type=3&top=1';

                return fetch(url, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + apiToken,
                        },
                    }
                )
                .then((response) => response.json())
                .then((json) => {
                    let data = json.data;

                    for (let i = 0; i < data.length; i++) {
                        data[i].stock > 0 ? this.stock = true : false;
                    }

                    setTimeout(() => {
                        new Foundation.Orbit($('.orbit'))
                    }, 400);
                })
                .catch((error) => {
                    // console.error("There was an error!", error);
                    return (this.errorMessage = error);
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
    .redemption_title {
        font-size: 2.1rem;
        font-weight: 400;
        margin-bottom: 20px;
    }

    .redemption_txt {
        font-size: 1.6rem;
        margin-bottom: 20px;
        line-height: normal;
    }

    .redemption_ctas_desktop {
        display: none;

        @media only screen and (min-width: 680px) {
            display: block;
            margin-top: 40px;
        }
    }

    .orbit {
        margin-top: 40px;

        .orbit-container {
            height: 330px !important;
        }

        @media only screen and (min-width: 680px) {
            display: none;
        }
    }

    .redemption_ctas_slider {
        display: flex;

        .btn-redemption {
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;

            @media only screen and (min-width: 680px) {
                border-right: 1px solid #d8d8d8;
                width: 33%;
                padding: 0 20px;

                &:last-child {
                    border-right: 0;
                    padding-right: 0;
                    padding-top: 0;
                    padding-left: 20px;
                }
            }
        }
    }
</style>