<template>
    <div>
        <section v-if="games.length">
            <div class="accordianItem cf" id="js_add_games">
                <div v-for="game in games" :key="game.id" class="cleafix interactiveGame-box" :id="game.id">
                    <span>
                        <a :href="'/MyLotto-Rewards/Redemption-Central/Interactive-Games/IGTest?id=' + game.id + demo" class="interactiveGame-gridItem">
                            <!-- <img :src="game.mainImageUrl"> -->
                            <div class="mainImageUrl" :id="game.cssID" @mouseover="hover(game.id)" @mouseleave="exit(game.id)" />
                            <div class="ig-btns" :ref="'button' + game.id" @mouseover="hover(game.id)" @mouseleave="exit(game.id)">
                                <div class="interactivePlay-btn red_btn" @mouseover="noDemo" v-if="auth">PLAY</div>
                                <div class="interactiveDemo-btn red_btn" @mouseover="addDemo">DEMO</div>
                            </div>
                            <span class="interactiveGameTitle">{{game.name}}</span>
                            <span class="interactiveGameText"></span>
                            <span class="interactiveGamePoints">
                                <strong></strong>
                            </span>
                        </a>
                    </span>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        data: function() {
            return {
                token: true,
                games: [],
                demo: '',
                auth: false
            }
        },
        mounted: function() {
            this.getToken()
        },
        methods: {
            getToken() {
                this.getAPItoken()
                .then((token) => {
                    this.token = token;
                    this.auth = this.isUserAuthed();
                })
                .then(() => {
                    // this.token = token;
                    this.getGames(this.token);
                });
            },
            // add_game_toPage (game) {
            //     var target = document.getElementById('js_add_games');
            //     var div = document.createElement('div');
            //     div.className = 'clearfix interactiveGame-box';
            //     div.id = game.Id;
            //     if (document.querySelector('#userIdentifier')) {
            //     div.innerHTML = '<a href="/MyLotto-Rewards/Redemption-Central/Games-for-Prizes/interactive-game?id=' + game.Id + '" class="interactiveGame-gridItem"><img src="' + game.MainImageUrl + '"><span class="ig-btns"><span class="interactivePlay-btn red_btn">PLAY</span><span class="interactiveDemo-btn red_btn" onClick="addDemo(event, ' + game.Id + ')">DEMO</span></span><span class="interactiveGameTitle">' + game.Name + '</span><span class="interactiveGameText"></span><span class="interactiveGamePoints"><strong></strong></br></span></a>';
            //     } else {
            //     div.innerHTML = '<a href="/MyLotto-Rewards/Redemption-Central/Games-for-Prizes/interactive-game?id=' + game.Id + '" class="interactiveGame-gridItem"><img src="' + game.MainImageUrl + '"><span class="ig-btns"><span class="interactivePlay-btn red_btn">PLAY</span><span class="interactiveDemo-btn red_btn" onClick="addDemo(event, ' + game.Id + ')">DEMO</span></span><span class="interactiveGameTitle">' + game.Name + '</span><span class="interactiveGameText"></span><span class="interactiveGamePoints"><strong></strong></br></span></a>>';
            //     }
            //     target.appendChild(div);
            // },
            // addDemo(e, id) {
            //     e.preventDefault();
            //     var aTag = document.getElementById(id).getElementsByTagName('a');
            //     window.location.href = aTag[0].href += '&demo=true';
            // },
            // unregDemo(e, id) {
            //     e.preventDefault();
            //     var aTag = '/MyLotto-Rewards/Redemption-Central/Games-for-Prizes/interactive-game?id=' + id;
            //     window.location.href = aTag + '&demo=true';
            // },
            getGames(token) {
                let apiToken = token,
                    url = process.env.VUE_APP_APIURL+'/1.0/Games/InteractiveGames/GetAvailableGames?pagenumber=0&itemsPerPage=100';

                return fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + apiToken
                    }
                })
                .then(response => response.json())
                .then(json => {
        
                    let data = json.data.games,
                        games = [];

                    for (let i = 0; i < data.length; i++) {
                        let game = { ...data[i] };

                        if (game.name.indexOf('Connectr_') > -1) {
                            game.cssID = 'connectr';
                        } else if (game.name.indexOf('Hardwood') > -1) {
                            game.cssID = 'hardwood';
                        } else if (game.name.indexOf('Mazr') > -1) {
                            game.cssID = 'mazr';
                        } else if (game.name.indexOf('Mirrorball') > -1) {
                            game.cssID = 'mirrorball';
                        } else if (game.name.indexOf('Mix') > -1) {
                            game.cssID = 'mix';
                        } else if (game.name.indexOf('Spinzy') > -1) {
                            game.cssID = 'spinzy';
                        }

                        games.push(game);
                    }

                    this.games = games;
                })
                .catch(error => {
                    console.log(this.errorMessage = error);
                })
            },
            hover(id) {
                this.$refs['button' + id][0].style.display = 'block';
            },
            exit(id) {
                this.$refs['button' + id][0].style.display = 'none';
            },
            noDemo() {
                this.demo = '';
            },
            addDemo() {
                this.demo = '&demo=true';
            }
        }
    }
</script>