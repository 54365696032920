<template>
    <div id="TransactionLogPage">

        <div v-if="!showDetails">
            <label class="perPage">
                <span>Items per page</span>
                <select v-model="itemsPerPage" @change="updateList">
                    <optgroup v-for="option in optionsPerPage" :key="option" style="padding:5px 0;">
                        <option :value="option">{{ option }}</option>
                    </optgroup>
                </select>
            </label>



            <div id="transactionLog" class="transactionLog_MLR purchasesList transactionLog cf">
                <ul class="listHeader">
                    <li class="col1 listHead">
                        <span>Date</span>
                    </li>
                    <li class="col2 listHead">
                        <span>Item</span>
                    </li>
                    <li class="col3 listHead">
                        <span>Points</span>
                    </li>
                    <li class="col4 listHead">
                        <span>Status</span>
                    </li>
                </ul>
                <ul class="transactionList">
                    <li v-for="(item, index) in itemsPagination" :key="index" class="transLI cf TLogRow">
                        <div class="transDate" data-content="Date">
                            <span>{{ customFormatter(item.orderDate) }}</span>
                        </div>
                        <div class="transType" data-content="Item">
                            <span><a @click="getDetails(item.orderID)">{{ item.orderItems[0].name }}</a>
                                <br> Quantity: {{ item.orderItems[0].units }}
                                <br> Order #{{ item.orderID }}</span>
                        </div>
                        <div class="transAmount" data-content="Points">
                            <span>{{ item.totalPrice }}</span>
                        </div>
                        <div class="transAmount" data-content="Status">
                            <span>{{ item.statusName }}</span>
                        </div>
                    </li>
                </ul>
            </div>
            <div v-if="totalPages > 1" class="transactionLogPagination">
                <Pagination :totalPages="totalPages" :itemsPerPage="itemsPerPage" :currentPage="currentPage"
                    @changePage="changePage" :center="true" :right="true"></Pagination>
            </div>
        </div>

        <PurchasesDetail v-if="showDetails" @toggleView="listView" :physical="true" :order-id="orderId">
        </PurchasesDetail>


    </div>
</template>

<!-- <table v-if="!showDetails">
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Item</th>
                    <th>Points</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in items" :key="item.id">
                    <td>{{ customFormatter(item.orderDate) }}</td>
                    <td><a @click="getDetails(item.orderID)">{{ item.orderItems[0].name}}</a> <br> Order #{{item.orderID}}</td>
                    <td>{{ item.totalPrice }}</td>
                    <td>{{ item.statusName }}</td>
                </tr>
            </tbody>
        </table> -->

<script>
import moment from 'moment';
import PurchasesDetail from './DigitalPurchasesDetail';
import Pagination from '../Pagination/pagination.vue';

export default {
    components: {
        PurchasesDetail,
        Pagination
    },
    data: function () {
        return {
            isUserLogged: false,
            showDetails: false,
            purchasesItems: {},
            itemsPagination: {},
            optionsPerPage: [10, 20, 50, 100],
            totalPages: 0,
            itemsPerPage: 100,
            currentPage: 1,

        }
    },
    mounted: function () {
        this.getToken()
        this.isUserLogged = this.isUserAuthed();
    },
    methods: {
        customFormatter(date) {
            return moment(date).format('MM/DD/yyyy');
        },
        getToken() {
            // then hit API with token
            this.getAPItoken().then((token) => {
                this.fetchData(token);
                this.token = token;
            });
        },
        fetchData(token) {
            let protocol = this.getProtocol();
            this.user = JSON.parse( sessionStorage.getItem('apiToken'));
            let url = process.env.VUE_APP_APIURL + '/1.0/Ecommerce/GetUserOrderList?type=4&userId=' + this.user.user.userId + '&top=' + this.itemsPerPage
            let apiToken = token;
            return fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + apiToken,
                },
            }
            )
                .then((response) => response.json())
                .then((json) => {
                    let data = json.data;
                    this.purchasesItems = data;
                    this.itemsPagination = data;
                    console.log(data)
                })
                .catch((error) => {
                    // console.error("There was an error!", error);
                    return (this.errorMessage = error);
                });
        },
        getDetails(orderId) {
            this.orderId = orderId;
            this.showDetails = true;
        },
        setPages() {
            this.totalPages = Math.ceil(this.purchasesItems.length / this.itemsPerPage);
            this.currentPage = 1;
        },
        setItems() {
            let start = (this.itemsPerPage * this.currentPage) - this.itemsPerPage,
                end = (this.itemsPerPage * this.currentPage),
                currentItems = this.purchasesItems.slice(start, end);
            this.itemsPagination = currentItems;
        },

        changePage(p) {
            this.currentPage = p;
            this.setItems();
        },
        updateList() {
            this.setPages();
            this.setItems();
        },
        listView() {
            this.showDetails = false;
        }
    }
};
</script>