var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('h2',[_vm._v("More Ways to Redeem")]),_c('div',{staticClass:"orbit",attrs:{"role":"region","data-orbit":"","data-options":"animInFromLeft:fade-in; animInFromRight:fade-in; animOutToLeft:fade-out; animOutToRight:fade-out;"}},[_c('div',{staticClass:"orbit-wrapper"},[_vm._m(0),_c('ul',{staticClass:"orbit-container redemption_ctas_slider"},[_vm._m(1),_vm._m(2),(_vm.stock)?_c('li',{staticClass:"orbit-slide",attrs:{"data-slide":"2"}},[_vm._m(3)]):_vm._e()]),_vm._m(4)])]),_c('div',{staticClass:"redemption_ctas_desktop"},[_c('div',{staticClass:"redemption_ctas_slider"},[_vm._m(5),_vm._m(6),(_vm.stock)?_c('div',{staticClass:"btn-redemption btn-browseCatalog"},[_c('div',{staticClass:"img-responsive",attrs:{"alt":"Digital Catalog Offerings","id":"redemption-digitalCatalog-image"}}),_c('p',{staticClass:"redemption_title"},[_vm._v("Digital Catalog")]),_c('p',{staticClass:"redemption_txt"},[_vm._v("Redeem points for gift cards or physical rewards. Catalog items start at 1,000 points.")]),_c('a',{staticClass:"button",attrs:{"href":"/MyLotto-Rewards/Redemption-Central/Digital-Catalog"}},[_vm._v("Shop Now")])]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"orbit-controls"},[_c('input',{attrs:{"id":"showBullets","name":"showBullets","type":"hidden","value":"True"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"orbit-slide is-active",attrs:{"data-slide":"0"}},[_c('figure',{staticClass:"orbit-figure"},[_c('div',{staticClass:"btn-redemption btn-playForPrizes"},[_c('div',{staticClass:"img-responsive orbit-image",attrs:{"alt":"","id":"redemption-catalog-image"}}),_c('p',{staticClass:"redemption_title"},[_vm._v("Games for Prizes")]),_c('p',{staticClass:"redemption_txt"},[_vm._v("Play online games to instantly win points and gift cards. Games start at 5 points per play.")]),_c('a',{staticClass:"button",attrs:{"href":"/MyLotto-Rewards/Redemption-Central/Games-for-Prizes"}},[_vm._v("Play Now")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"orbit-slide",attrs:{"data-slide":"1"}},[_c('figure',{staticClass:"orbit-figure"},[_c('div',{staticClass:"btn-redemption btn-enterDrawings"},[_c('div',{staticClass:"img-responsive",attrs:{"alt":"","id":"redemption-drawings-image"}}),_c('p',{staticClass:"redemption_title"},[_vm._v("Drawings")]),_c('p',{staticClass:"redemption_txt"},[_vm._v("Enter drawings for amazing prizes. Drawings start at 5 points per entry.")]),_c('a',{staticClass:"button",attrs:{"href":"/myLotto/Redemption-Central/Drawings"}},[_vm._v("Enter Now")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btn-redemption btn-browseCatalog"},[_c('img',{staticClass:"img-responsive",attrs:{"alt":"Digital Catalog Offerings","id":"redemption-digitalCatalog-image","src":"/ohiolottery/media/ContentImages/MyLottoRewards/redemptionSlider_DigitalCatalog-400x254.png"}}),_c('p',{staticClass:"redemption_title"},[_vm._v("Digital Catalog")]),_c('p',{staticClass:"redemption_txt"},[_vm._v("Redeem points for gift cards or physical rewards. Catalog items start at 1,000 points.")]),_c('a',{staticClass:"button",attrs:{"href":"/MyLotto-Rewards/Redemption-Central/Digital-Catalog"}},[_vm._v("Shop Now")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"orbit-bullets"},[_c('button',{staticClass:"is-active",attrs:{"data-slide":"0"}}),_c('button',{attrs:{"data-slide":"1"}}),_c('button',{attrs:{"data-slide":"2"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btn-redemption btn-playForPrizes"},[_c('div',{staticClass:"img-responsive",attrs:{"alt":"","id":"redemption-catalog-image"}}),_c('p',{staticClass:"redemption_title"},[_vm._v("Games for Prizes")]),_c('p',{staticClass:"redemption_txt"},[_vm._v("Play online games to instantly win points and gift cards. Games start at 5 points per play.")]),_c('a',{staticClass:"button",attrs:{"href":"/MyLotto-Rewards/Redemption-Central/Games-for-Prizes"}},[_vm._v("Play Now")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btn-redemption btn-enterDrawings"},[_c('div',{staticClass:"img-responsive",attrs:{"alt":"","id":"redemption-drawings-image"}}),_c('p',{staticClass:"redemption_title"},[_vm._v("Drawings")]),_c('p',{staticClass:"redemption_txt"},[_vm._v("Enter drawings for amazing prizes. Drawings start at 5 points per entry.")]),_c('a',{staticClass:"button",attrs:{"href":"/myLotto/Redemption-Central/Drawings"}},[_vm._v("Enter Now")])])
}]

export { render, staticRenderFns }