<template>
    <div>
        <section v-if="weekly.length" class="drawingItem cf desktopBoxList">
            <h2 class="drawingCat accordianBtn">
                Weekly Prize Drawings
            </h2>
            <span class="weeklyList accordianItem">
                <DrawingsCatalogItem v-for="drawing in weekly" :key="drawing.productId" :drawing="drawing"></DrawingsCatalogItem>
            </span>
        </section>
        <section v-if="monthly.length" class="drawingItem cf desktopBoxList">
            <h2 class="drawingCat accordianBtn">
                Monthly Prize Drawings
            </h2>
            <span class="weeklyList accordianItem">
                <DrawingsCatalogItem v-for="drawing in monthly" :key="drawing.productId" :drawing="drawing"></DrawingsCatalogItem>
            </span>
        </section>
        <section v-if="quarterly.length" class="drawingItem cf desktopBoxList">
            <h2 class="drawingCat accordianBtn">
                Quarterly Prize Drawings
            </h2>
            <span class="weeklyList accordianItem">
                <DrawingsCatalogItem v-for="drawing in quarterly" :key="drawing.productId" :drawing="drawing"></DrawingsCatalogItem>
            </span>
        </section>
        <section v-if="yearly.length" class="drawingItem cf desktopBoxList">
            <h2 class="drawingCat accordianBtn">
                Yearly Prize Drawings
            </h2>
            <span class="weeklyList accordianItem">
                <DrawingsCatalogItem v-for="drawing in yearly" :key="drawing.productId" :drawing="drawing"></DrawingsCatalogItem>
            </span>
        </section>
        <section v-if="unclassified.length" class="drawingItem cf desktopBoxList">
            <h2 class="drawingCat accordianBtn">
                unclassified Drawings, these need a way to be organized
            </h2>
            <span class="weeklyList accordianItem">
                <DrawingsCatalogItem v-for="drawing in unclassified" :key="drawing.productId" :drawing="drawing"></DrawingsCatalogItem>
            </span>
        </section>
        <section v-if="!products.length" class="noProductsAvailable">
            No drawings currently available. Please check back soon!
            <!-- really not likely to happen, but you never know -->
        </section>
        <p class="drawingDisclaimer">Winners are drawn within 10 days of drawing close dates.</p>
    </div>
</template>

<script>
    import moment from 'moment';
    import DrawingsCatalogItem from './DrawingsCatalogItem.vue';
    
    export default {
        components: {
            DrawingsCatalogItem
        },
        data: function() {
            return {
                token: '',
                products: '',
                weekly: [],
                monthly: [],
                quarterly: [],
                yearly: [],
                unclassified: []
            }
        },
        mounted: function() {
            this.getToken()
        },
        methods: {
            getToken() {
                this.getAPItoken()
                .then((token) => {
                    this.getProducts(token);
                });
            },
            getProducts(token) {
                let apiToken = token,
                    url = process.env.VUE_APP_APIURL+'/1.0/Ecommerce/GetProductList?type=8&top=100';

                return fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + apiToken
                    }
                })
                .then(response => response.json())
                .then(json => {
                    this.products = json.data;
                    console.log(this.products)
                })
                .then(() => {
                    let products = this.products,
                        weekly = this.weekly,
                        monthly = this.monthly,
                        quarterly = this.quarterly,
                        yearly = this.yearly,
                        unclassified = this.unclassified;

                    for(let i = 0; i < products.length; i++) {
                        let product = products[i],
                            startDate = new moment(product.parameter2).format('M/DD/YY'),
                            endDate = new moment(product.parameter3).format('M/DD/YY');

                        product.parameter2 = startDate;
                        product.parameter3 = endDate;

                        if (product.category.includes("Weekly")) {
                            weekly.push(product);
                        }

                        if (product.category.includes("Monthly")) {
                            monthly.push(product);
                        }

                        if (product.category.includes("Quarterly")) {
                            quarterly.push(product);
                        }

                        if (product.category.includes("Annual")) {
                            yearly.push(product);
                        }

                        if (product.category === "" || product.category === undefined || product.category === null) {
                            unclassified.push(product);
                        }

                        this.weekly = weekly;
                        this.monthly = monthly;
                        this.quarterly = quarterly;
                        this.yearly = yearly;
                        this.unclassified = unclassified;
                    }
                })
                .catch(error => {
                    console.log(this.errorMessage = error);
                })
            }
        }
    }
</script>