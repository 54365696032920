<template>
    <section class="promotion-banner">
        <img :src="banner.img" />
        <div class="banner-description">
            <p class="user"><strong>Hello, {{ user.name }}!</strong> Your rewards balance is:</p>
            <p class="points">{{ addCommasToNumber(user.points) }} <span>pts.</span></p>
        </div>
        <div class="cta-description">
            <p class="legend">{{ banner.ctaText }}</p>
            <button class="">{{ banner.ctaButtonText }}</button>
            <p class="expired">
                <span class="alert-icon"><strong>!</strong><span class="bg"></span></span>
                {{ user.pointsExpired }}
            </p>
        </div>
    </section>
</template>

<script>
export default {
    components: {
    },
    data() {
        return {
            user: {
                name: 'Robert',
                points: '5900',
                pointsExpired: '750 points will expire on 12/31/23'
            },
            banner: {
                img: 'https://admin-walottery-dev.mtllc.com/getmedia/d7029cb5-6ef8-4173-91db-218790c63284/myLottery_360_white.png',
                ctaText: 'Use your points to shop from our catalog for great gifts and gear. You’ve earned it!',
                ctaButtonText: 'Redeem Points',
                ctaUrl: ''
            }
        };
    },

    mounted() {
        // console.log('hello');
    },
    methods: {
        addCommasToNumber(number) {
            const parts = number.toString().split('.');
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return parts.join('.');
        }
    }
}
</script>