<template>
    <div class="" :class="widgetCssClass, cssClass, customClass">
        <div class="custom-content-block" v-if="widgetCustomContent">
            <span v-html="widgetCustomContent"></span>
        </div>

        <div class="ticket-entry-form callout">
            <h4 id="ticket-entry-header">{{ heading }}</h4>
            <span v-html="content" class="ticket-entry-content"></span>
            <div class="input-group">
                <span id="spanImage" class="ticketImg" v-if="logo"><img :src="gameLogo" width="50"></span>
                <span id="spanImage" class="ticketImg lotteryLogo" v-if="!logo && search"></span>
                <input class="input-group-field input-text" :class="{error: !validInput}" type="text" :placeholder="placeholder" v-model="inputValue"
                    v-on:input="onInput" :disabled="disabled" maxlength="26">
                <input class="input-group-field input-text" :class="{error: !validInput}" type="text" :placeholder="placeholder_pin" v-if="gameType == 0"
                    v-model="inputValue_pin" v-on:input="onInput" :disabled="disabled" maxlength="3">
            </div>
            <div class="input-group">
                <div class="input-group-button">
                    <input v-if="!loginButton && !redeemTicket" class="button btn-primary" :class="{disabled: isLoading}" type="submit" :value="submit" @click="go" :disabled="isLoading">
                    <input class="button btn-secondary logo-mylottery" value="Redeem Ticket" type="button" v-if="redeemTicket" :disabled="isLoading" @click="submitTicket">
                    <input class="button btn-secondary logo-mylottery" value="Login to Submit" type="button" v-if="loginButton && !redeemTicket" @click="login">
                </div>
            </div>
            <!-- <div class="error-ticket text-center" v-if="showErrMsg">
                <p v-if="errorMessage">{{ errorMessage }}</p>
                <p v-if="gameCodeError && validGame">{{ gameCodeError }}</p>
            </div>
            <hollow-dots-spinner :animation-duration="1000" :dot-size="15" :dots-num="3" color="#0FD8C9" v-if="isLoading" />

            <div class="ticket-entry-gamecode-results" v-if="showGameCodeResults">
                <div class="ticket-entered grid-x grid-margin-x">
                    <div class="cell small-4" v-if="gameLogo">
                        <img :src="`/getattachment/${gameLogo}/attachment.aspx`" />
                    </div>
                    <div class="cell small-4" v-if="!gameLogo">
                        <span class="lotteryLogo"></span>
                    </div>
                    <div class="cell small-6">
                        <p v-if="gameNumber"> {{ gameNumber }} </p>


                    </div>
                    <div class="cell small-2">
                        <p>{{ gamePrice }} points</p>
                    </div>
                </div>
            </div>

            <div class="ticket-entry-results" v-if="showResults">
                <h5 class="text-center">{{ entered }}</h5>

                <div class="ticket-entered grid-x grid-margin-x" v-for="result in resultsArray.slice().reverse()" :key="result.id" >   
                <div class="ticket-entered grid-x grid-margin-x">
                    <div class="cell small-1" v-if="result.icon.length > 0">
                        <img :src="result.icon" />
                    </div>
                    <div class="cell small-1" v-if="result.icon === ''">
                        <span class="lotteryLogo"></span>
                    </div>
                    <div class="cell small-9 result">
                        <p v-if="result.name">{{ result.name }}</p>
                        <p v-if="result.message">{{ result.message }}</p>
                        <p v-if="result.points">${{ result.points }}</p>
                    </div>
                    <div class="cell small-2 points">
                        <p>{{ result.points }} points</p>
                    </div>
                </div>
            </div> -->
        </div>

    </div>
</template>

<script>

import { HollowDotsSpinner } from 'epic-spinners'

export default {
    props: ['contentKey', 'requireDigits', 'placeholderProp', 'headingProp', 'submitTextProp', 'customClass', 'gameType', 'displayResult','toggleGame'],
    components: {
        HollowDotsSpinner
    },
    data: function () {
        return {
            heading: '',
            placeholder: 'Ticket Number',
            placeholder_pin: 'PIN',
            submit: 'ENTER TICKET',
            entered: 'Entered Tickets',
            search: false,
            content: '',
            cssClass: '',
            inputValue: '',
            inputValue_pin: '',
            widgetContentKey: '',
            widgetCssClass: '',
            widgetCustomContent: '',
            gameLogo: '',
            logo: false,
            gameNumber: '',
            gamePrice: '',
            gameCodeError: '',
            isLoading: false,
            showResults: false,
            showGameCodeResults: false,
            showErrMsg: false,
            isLogoExist: false,
            disabled: false,
            validInput: true,
            validGame: false,
            resultsArray: [],
            result: '',
            wager: 0,
            gameId: 0,
            isWinningTicket: 0,
            submittable: false,
            errorMessage: '',
            loginButton: false,
            submitButton: false,
            forSubmission: '',
            redeemTicket: false,
            pureInput: '',
            gameReturn: ''
        }
    },
    mounted: function () {
        let widgetContentKey = document.getElementById('content-key'),
            widgetCssClass = document.getElementById('custom-css'),
            widgetCustomContent = document.getElementById('custom-content');

        if (widgetContentKey !== null) {
            this.contentKey = widgetContentKey.value;
            this.widgetCssClass = widgetCssClass.value;
            this.widgetCustomContent = widgetCustomContent.value;
        }

        // this.fetchContentByKey();
        if (this.isUserAuthed() === true) {
            let cookie = this.$cookies.get('ticketEntry');

            if (cookie) {
                this.setInput(cookie);
            }
        }
    },
    methods: {
        login() {
            this.$cookies.set('ticketEntry', JSON.stringify({
                'inputValue': this.inputValue,
                'inputValue_pin':this.inputValue_pin,
                'type': this.gameType,
                'data': this.gameReturn
            }));

            $("#globalLogInModal").show();
            $("#globalLoginBlocker").show();
        },
        setInput(d) {
            this.toggleGame(d.type);
            setTimeout(() => {
                this.inputValue = d.inputValue;
                this.inputValue_pin = d.inputValue_pin;
            }, 500);
            this.displayResult(d.data);

            this.$cookies.remove('ticketEntry');
        },
        logMe() {
            // console.log('Focus')
        },
        go() {
            this.isLoading = true;
            this.showGameCodeResults = false;
            
            this.getToken();
            // Add ability to launch modal from here for special promos based on a true/false prop passed from parent page.
        },
        clearInput() {
            this.inputValue = ""
            this.showErrMsg = false;
            this.showGameCodeResults = true;
            this.showGameCodeResults = false;
            this.logo = false;
            this.search = false;
        },
        getToken() {
            // then hit API with token
            this.getAPItoken()
                .then((token) => {
                    this.token = token
                    this.fetchData();
                });
        },
        fetchData() {
            this.validate();
            const apiToken = this.token;
            // const userId = this.$cookies.get('TokenInfo').User.UserId;
            const urlProtocol = this.getProtocol();
            let serialNumber;

            this.gameType === 1 ? serialNumber = this.inputValue : serialNumber = this.inputValue + this.inputValue_pin;
            // this.pureInput = serialNumber;
            // let percentReplace = serialNumber.replace(/%/g, "%25"),
            //     hashReplace = percentReplace.replace(/#/g, "%23"),
            //     ampReplace = hashReplace.replace(/&/g, "%26");

            // serialNumber = ampReplace;

            // console.log(serialNumber)

            if (this.validInput === true) {
                let body = {
                    "serialNumber": serialNumber.toString()
                }

                return fetch(urlProtocol + process.env.VUE_APP_APIURL + "/1.0/TicketChecker/CheckTicket", {
                        method: "POST",
                        body: JSON.stringify(body),
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + apiToken
                        }
                    })
                    .then(response => response.json())
                    .then(json => {
                        let data = json.data;

                        if (data === null || data === undefined) {
                            // console.log("Data is NULL");
                            json.error === null ? this.displayResult(json) : this.displayResult({error:{message:'Invalid Ticket'}});
                            this.isLoading = false;

                            this.redeemTicket = false;
                            this.loginButton = false;

                            this.showErrMsg = true;
                            
                            this.errorMessage = json.error.message;
                            return false;
                        } else if (this.isUserAuthed() === true) {
                            // console.log("Data found, user is Authorized");
                            const userId = JSON.parse(sessionStorage.getItem('TokenInfo') ).user.userId;
                            this.isLoading = false;

                            if (data.isValid === true) {
                                console.log("Ticket Data is Valid");
                                let result = {
                                    icon: data.gameImageURL,
                                    serial: data.serialNumber,
                                    message: data.submitMessage,
                                    points: data.rewardsPointsValue
                                }

                                this.result = result;

                                this.showResults = true;
                                
                                this.gameLogo = data.gameImageURL;
                                this.wager = data.ticketCost;
                                this.gameId = data.gameNumber;
                                this.isWinningTicket = data.isWinningTicket;

                                this.forSubmission = {
                                    "serialNumber": serialNumber.toString(),
                                    "userId": userId,
                                    "wager": this.wager,
                                    "gameNumericIdentifier": this.gameId,
                                    "isWinningTicket": this.isWinningTicket
                                }

                                // this.displayResult(json);

                                if (data.isWinningTicket === true) {
                                    this.displayResult(json);
                                } else {
                                    // console.log("Non-winning Ticket");
                                    // console.log(json)
                                    // this.submitTicket();
                                    this.displayResult(json);
                                    if (data.submittableForPoints === true && data.isWinningTicket === false) {
                                        this.redeemTicket = true;
                                    }
                                }

                                this.loginButton = false;
                            } else {
                                this.displayResult(json);
                                this.redeemTicket = false;
                                this.loginButton = false;

                                this.showErrMsg = true;
                                this.errorMessage = json.data.scanResultMessage;
                            }
                        } else {
                            this.isLoading = false;
                            
                            this.displayResult(json);
                            
                            if (data.isValid === true && data.submittableForPoints === true) {
                                this.gameReturn = json;
                                this.redeemTicket = false;
                                this.loginButton = true;

                                this.showResults = true;
                            } else {
                                this.redeemTicket = false;
                                this.loginButton = false;

                                this.showErrMsg = true;
                                this.errorMessage = json.data.scanResultMessage;
                            }
                        }
                    })
                    .catch(error => {
                        this.isLoading = false;
                        this.showErrMsg = true;
                        // console.log(error)
                        this.displayResult(error)
                        this.errorMessage = "Invalid Ticket";
                    });
            }
        },
        submitTicket() {
            this.isLoading = true;
            const urlProtocol = this.getProtocol();
            let data = this.forSubmission;

            let requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.token
                },
                body: JSON.stringify(data)
            };

            return fetch(urlProtocol + process.env.VUE_APP_APIURL + "/1.0/TicketChecker/SubmitTicket", requestOptions)
                .then(response => response.json())
                .then(json => {
                    let data = json.data;

                    if (json.error === null) {
                        let result = {
                            icon: this.gameLogo,
                            serial: data.serialNumber,
                            message: data.submitMessage,
                            points: data.rewardsPointsValue
                        }

                        this.result = result;

                        this.displayResult(json, true);

                        this.showResults = true;
                        this.inputValue = '';
                        this.inputValue_pin = '';
                        this.isLoading = false;
                    } else {
                        this.displayResult(json, true);
                        this.isLoading = false;
                        this.showErrMsg = true;
                        this.errorMessage = json.error.message;
                    }

                })
                .catch(error => {
                    this.displayResult(error);
                    this.isLoading = false;
                    this.showErrMsg = true;
                    this.errorMessage = error;
                });
        },
        // fetchContentByKey() {
        //     this.getAPItoken()
        //         .then((token) => {
        //             let apiToken = token;
        //             let ContentKey = this.contentKey;

        //             return fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + "/1.0/Games/StaticContentElements/StaticContentElement/GetContentElementByKey?contentKey=" + ContentKey, {
        //                 headers: {
        //                     'Authorization': 'Bearer ' + apiToken,
        //                 }
        //             })
        //                 .then(response => response.json())
        //                 .then(json => {
        //                     if (json.data.length > 0) {
        //                         let content = json.data[0].centralContent;
        //                         let parseContent = JSON.parse(content)

        //                         this.headingProp.length > 1 ? this.heading = this.headingProp : this.heading = parseContent.heading;
        //                         this.placeholder = parseContent.placeholder;
        //                         this.submit = parseContent.submit;
        //                         this.entered = parseContent.entered;
        //                         this.content = parseContent.content;
        //                         this.cssClass = parseContent.cssclass;
        //                     }
        //                 })
        //                 .catch(error => {
        //                     console.log(error)
        //                 });
        //         });
        // },
        fetchIcon(inputValue) {
            this.getAPItoken()
                .then((token) => {
                    let apiToken = token;

                    return fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + "/1.0/Games/ScratchOffs/ScratchOffGame/GetGameInformation?gameCode=" + inputValue + "&getAllGameInfo=false", {
                        headers: {
                            'Authorization': 'Bearer ' + apiToken,
                        }
                    })
                        .then(response => response.json())
                        .then(json => {
                            let result = json.data;
                            // this.disabled = false;
                            // this.showGameCodeResults = true;

                            if (result !== null) {
                                this.gameLogo = result.gameLogoURL;
                                this.logo = true;
                                this.gameNumber = result.gameId;
                                this.gamePrice = result.gamePrice;
                                this.gameCodeError = "";
                                // this.showErrMsg = false;
                                // this.showGameCodeResults = true;
                            } else {
                                // this.showErrMsg = true;
                                // this.showGameCodeResults = false;
                                this.gameLogo = " ";
                                this.gameNumber = " ";
                                this.gamePrice = " ";
                                this.gameCodeError = json.error.message;
                            }
                        })
                        .catch(error => {
                            console.log(error)
                        });
                });
        },
        validate() {
            // Numbers only
            // 23 || 35 characters long
            let input = '';

            this.gameType === 0 ? input = this.inputValue : input = this.inputValue + this.inputValue_pin;

            let isnum = /^\d+$/.test(input);
            // console.log(isnum)

            if (this.gameType === 0) {
                if (isnum === true) {
                    this.showErrMsg = false;
                    this.validInput = true;
                    // input.length === 23 || input.length === 35 ? this.validInput = true : this.validInput = false;
                } else {
                    this.isLoading = false;
                    this.validInput = false;
                    this.showErrMsg = true;
                    
                    let invalid = {
                        "data": {
                            "isValid": false,
                            "scanResultMessage": 'Invalid Scratch Game Input.'
                        }
                    }

                    this.displayResult(invalid);
                }
            } else if (this.gameType === 1) {
                if (isnum === true) {
                    this.isLoading = false;
                    this.showErrMsg = true;
                    this.validInput = false;
                    
                    let invalid = {
                        "data": {
                            "isValid": false,
                            "scanResultMessage": 'Invalid Draw Game Input.'
                        }
                    }

                    this.displayResult(invalid);
                } else {
                    this.showErrMsg = false;
                    this.validInput = true;
                }
            }

            input === '' ? this.validInput = true : false;

            console.log(this.validInput)
        },
        onInput() {
            this.showResults = false;
            this.showErrMsg = true;
            this.redeemTicket = false;
            this.loginButton = false;
            let input = '';

            this.inputValue_pin === '' ? input = this.inputValue : input = this.inputValue + this.inputValue_pin;

            let requireDigits = parseInt(this.requireDigits);
            if (input.length === requireDigits || input.length === 23 || input.length === 35) {
                this.search = true;
                // this.disabled = true;
                let fourInputValue = input;
                this.fetchIcon(fourInputValue)
            }
            if (input.length === 0) {
                this.search = false;
            }
        }
    },
    watch: {
        gameType(newVal, oldVal) {
            if (newVal !== oldVal) {
                console.log(oldVal + " - " + newVal)
                this.inputValue = '';
                this.inputValue_pin = '';
                this.redeemTicket = false;
                this.loginButton = false;
                let invalid = {
                    "data": {
                        "isValid": false,
                        "scanResultMessage": 'CHANGE'
                    }
                }

                this.displayResult(invalid);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.ticket-entry-form {
    background-color: #fff;
    padding: 0;
    margin-bottom: 0;

    .input-group {
        width: 100%;
        display: flex;
        gap: 1.6rem;

        &-field {
            flex: 1;
            text-align: left;

            &:first-of-type {
                min-width: 73.39%;
            }
        }

        input {
            margin: 0;
            height: 4.8rem;
            &::placeholder {
                text-align: left;
                color: #6C6C6C;
                font-weight: 500;
            }
        }

        .input-group-field.input-text {
            border-width: 1px;
            &:not(:focus) {
                border: 1px solid #6C6C6C;
            }
        }

        &-button {
            flex: 1;

            input {
                min-height: 4.8rem;
                width: 100%;
                font-size: 1.3rem;
                line-height: 1;
            }
        }

        .ticketImg {
            display: none;
        }

        + .input-group {
            margin-top: 1.6rem;
        }
    }
}

.ticket-entered {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    border-top: 1px solid #d3d3d3;
    padding-top: 2rem;

    div.cell.small-1 {
        img {
            width: 36px;
        }
    }

    .points {
        width: 100%;

        @include breakpoint(medium) {
            width: inherit;
        }
    }

    .result {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        @include breakpoint(medium) {
            flex-direction: row;
        }
    }

    @include breakpoint(medium) {
        flex-direction: row;
    }
}

// .input-text {
//     height: 48px;
//     border: 1px solid #6C6C6C;
//     color: #6C6C6C;
// }


// .button.btn-primary {
//     height: 48px;
//     box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.15);
// }

// .button.secondary {
//     text-transform: none;
//     letter-spacing: unset;
//     display: inline-block;
//     margin-left: 20px;
// }

.error-ticket {
    p {
        color: #CF0A2C;
        display: inline-block;
        vertical-align: middle;
        margin: 1.6rem 0;
    }
}
</style>
