<template>
        <span>
            <button v-if="pointsAvailable" class="button btn-primary" data-open="modal-checkout" :disabled="stock === 0">Purchase with Points</button>
            <button v-if="isUserLogged" class="button btn-secondary" data-open="modal-wishlist" @click="addToWish">Add to Wishlist</button>

            <div id="modal-checkout" class="reveal modal-checkout reveal-modal" data-reveal>
                <section v-if="!confirm" class="shoppingCartImage oneStepCheckout">
                    <h2>Confirm Purchase</h2>
                    <button class="close-button" data-close type="button">
                        <span aria-hidden="true">×</span>
                    </button>
                    <div class="CartContent">
                        <!-- <picture class="checkoutProductImg">
                            <img :src="image">
                        </picture> -->
                        <div class="clear">
                            <p class="confirmationMessage">
                                Please confirm your purchase of <strong>{{ qty }} {{ name }}</strong> for a total of <strong>{{ price.toLocaleString() }} Points</strong>.
                            </p>
                            <p class="confirmationMessage">
                                Your order will be delivered to the address in your profile:
                            </p>
                            <p class="userShippingAddress">
                                <strong>
                                    {{ firstName }} {{ lastName }}<br/>
                                    {{ address.address1 }}<br/>
                                    <span v-if="address.address2">{{ address.address2}}<br/></span>
                                    {{ address.City }}<br/>
                                    {{ address.State }}<br/>
                                    {{ address.Zip }}
                                </strong>
                            </p>
                        </div>
                        <div class="clear"></div>
                        <div class="modalFooter cf" v-if="!transaction">
                            <button class="ProcessPaymentButton button btn-primary" @click="confirmPurchase">Confirm Purchase</button>
                            <!-- <a href="#" class="cancelOrder" data-close>Cancel Purchase</a> -->
                        </div>
                        <div class="modalFooter cf prplText" v-if="transaction">
                            Please wait while your transaction is being processed.
                        </div>
                    </div>
                </section>
                <section id="confirmPanel" class="confirmPanel" v-if="confirm">
                    <div v-if="error">
                        <h2>ERROR</h2>
                        <p>{{ message }}</p>
                        <button class="button btn-secondary" data-close>Close</button>
                    </div>
                    <div v-else>
                        <h1>Order Complete</h1>
                        <h3>{{ name }}</h3>
                        <p>
                            Congratulations! Your purchase from MyLotto Rewards&reg; Redemption Central is on its way!
                        </p>
                        <ul>
                            <li>
                                Expect to receive physical gift cards in the mail in the next 3 -4 weeks.
                            </li>
                            <li>
                                Expect an email with your digital gift cards within 2 hours, or find them listed under <strong>My Digital Gift Cards</strong> in your <strong>My Account</strong> section.
                            </li>
                            <li>
                                Find all of your Lottery Cash &amp; Coupons in <strong>My Lottery Cash &amp; Coupons</strong> under <strong>My Account</strong>.
                            </li>
                            <li>
                                Expect delivery of your physical item in the next 1 to 3 weeks. You can track the item shipment information in the <strong>My Physical Purchases</strong> of your <strong>My Account</strong> section.
                            </li>
                        </ul>

                        <button class="button btn-secondary" data-close>Back to Redemption Central</button>
                    </div>
                </section>
            </div>
        </span>
</template>

<script>
    import $ from 'jquery';
    import Foundation from "@/foundation-sites/js/foundation.js";

    export default {
        props: [
            'data',
            'prop'
        ],
        components:{
        },
        data: function () {
            return {
                isUserLogged: false,
                userPoints: 0,
                pointsAvailable: false,
                category: '',
                description: '',
                image: '',
                name: '',
                parameter1: '',
                parameter2: '',
                parameter3: '',
                parameter4: '',
                parameter5: '',
                price: 0,
                productId: 0,
                skuNumber: '',
                stock: 0,
                token: {},
                user: {},
                firstName: '',
                lastName: '',
                address: '',
                purchase: false,
                message: '',
                success: false,
                confirm: false,
                transaction: false,
                error: false,
                qty: 0
            }
        },
        mounted: function () {
            this.setProps();
            let _this = this;
            (function (window, undefined) {
                'use strict';

                $('[data-reveal]').on('closed.zf.reveal', function () {
                    var modal = $(this);
                    // alert('closed');
                    _this.reset();
                });

                $(document).foundation();
            })();
        },
        methods: {
            setProps() {
                this.isUserLogged = this.isUserAuthed(),
                this.userPoints = this.data.userPoints,
                this.pointsAvailable = this.data.pointsAvailable,
                this.category = this.data.category,
                this.description = this.data.description,
                this.image = this.data.image,
                this.name = this.data.name,
                this.parameter1 = this.data.parameter1,
                this.parameter2 = this.data.parameter2,
                this.parameter3 = this.data.parameter3,
                this.parameter4 = this.data.parameter4,
                this.parameter5 = this.data.parameter5,
                this.price = this.data.price,
                this.productId = this.data.productId,
                this.skuNumber = this.data.skuNumber,
                this.stock = this.data.stock,
                this.token = this.data.token,
                this.user = this.data.user,
                this.address = this.data.address,
                this.qty = this.data.qty
            },
            reset() {
                // this.purchase = false;
                this.transaction = false;
                this.confirm = false;
                this.error = false;
            },
           openPurchase() {
            let popup = new Foundation.Reveal($('#modal-checkout'));
            popup.open();
           },
           closePurchase() {
                this.enter = true;
                this.confirm = false;
                this.error = false;
                $('#modal-checkout').hide();
                $('.reveal-overlay').hide();
            },
           confirmPurchase() {
            let _this = this;
            this.transaction = true;

            let url = process.env.VUE_APP_APIURL + '/1.0/Ecommerce/PerformPurchase?userId=' + this.user.user.userId + '&productId=' + this.productId + '&qty=1';
            
            fetch (url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json-patch+json',
                    'Authorization': "Bearer " + this.token
                },
            })
            .then((response) => {
                this.transaction = false;
                this.confirm = true;

                if (response.status === 200) {
                    return response.json(); 
                } else {
                    // console.log(response)
                    // if (response === null) {
                    //     console.log('NULL');
                    // } else if(response === '') {
                    //     console.log('BLANK');
                    // } else if (response === undefined) {
                    //     console.log('UNDEFINED');
                    // } else {
                        return false;
                    // }
                }
            })
            .then((json) => {
                let res = json;

                if (res === false) {
                    this.message = "There was an error with your purchase. Please try again later.";
                    this.error = true;
                } else if (res.error === null) {
                    this.message = "";
                    this.error = false;
                    this.getUserPoints();                    
                } else {
                    this.message = res.error.message;
                    this.error = true;
                }
            })
            .catch(error => {
                // console.error("There was an error!", error);
                _this.message = error;
                _this.transaction = false;
                _this.confirm = true;
                _this.error = true;
            })
           }
        },
        computed: {
        },
        watch: {
            data: function(newVal) {
                this.isUserLogged = newVal.isUserLogged,
                this.userPoints = newVal.userPoints,
                this.pointsAvailable = newVal.pointsAvailable,
                this.category = newVal.category,
                this.description = newVal.description,
                this.image = newVal.image,
                this.name = newVal.name,
                this.parameter1 = newVal.parameter1,
                this.parameter2 = newVal.parameter2,
                this.parameter3 = newVal.parameter3,
                this.parameter4 = newVal.parameter4,
                this.parameter5 = newVal.parameter5,
                this.price = newVal.price,
                this.productId = newVal.productId,
                this.skuNumber = newVal.skuNumber,
                this.stock = newVal.stock,
                this.token = newVal.token,
                this.user = newVal.user,
                this.address = newVal.address
            }
        }
    };
</script>

<style lang="scss" scoped>

</style>