<template>
    <div>
        <div v-if="showPromotionCodeEntry" class="promocodeentry" :class="widgetCssClass, cssClass, customClass">
            <div class="custom-content-block" v-if="widgetCustomContent">
                    <span v-html="widgetCustomContent"></span>
            </div>
            
            <div class="ticket-entry-form">
                <h4 id="ticket-entry-header">{{ heading }}</h4>
                <p>{{ subheading }}</p>
                <span v-html="content" class="ticket-entry-content"></span>
                <div class="input-group">
                    <input class="input-group-field" type="text" :placeholder="placeholder" v-model="inputValue" v-on:input="onInput" :disabled="disabled">
                    <div class="input-group-button">
                        <input class="button" type="submit" :value="submit"  @click="go">
                    </div>
                </div>
                <div class="error-ticket text-center" v-if="showErrMsg">
                    <p v-if="errorMessage">{{ errorMessage }}</p>
                    <p v-if="gameCodeError && validGame">{{ gameCodeError }}</p>
                    <button class="button secondary" value="Clear Fields" @click="clearInput">Clear Field</button>
                </div>
                <hollow-dots-spinner
                                :animation-duration="1000"
                                :dot-size="15"
                                :dots-num="3"
                                color="#0FD8C9"
                                v-if="isLoading"
                            />

                <div class="ticket-entry-gamecode-results" v-if="showGameCodeResults">
                    <div class="ticket-entered grid-x grid-margin-x">   
                        <div class="cell small-4" v-if="gameLogo">
                            <img :src="`/getattachment/${gameLogo}/attachment.aspx`"  />
                        </div>
                        <div class="cell small-4" v-if="!gameLogo">
                            <span class="lotteryLogo"></span>
                        </div>
                        <div class="cell small-6">
                            <p v-if="gameNumber"> {{ gameNumber }} </p>
                            

                        </div>
                        <div class="cell small-2">
                            <p>{{ gamePrice }} points</p>
                        </div> 
                    </div>        
                </div>

                <div class="ticket-entry-results" v-if="showResults">
                    <h5 class="text-center">{{entered }}</h5>

                    <div class="ticket-entered grid-x grid-margin-x"  v-for="result in resultsArray" :key="result.id" >   
                        <div class="cell small-2" v-if="result.gameImageURL">
                            <img :src="result.gameImageURL" />
                        </div>
                        <div class="cell small-2" v-if="!result.gameImageURL">
                            <span class="lotteryLogo"></span>
                        </div>
                        <div class="cell small-8 result">
                            <p v-if="result.ticketCode">{{ result.ticketCode }}</p>
                            <p v-if="!result.ticketCode">{{ result.scanResultMessage }}</p>
                        </div>
                        <div class="cell small-2 points">
                            <p>{{ result.ticketWinAmount }} points</p>
                        </div> 
                    </div>
                </div>

                <div class="ticket-entry-msg" v-if="showScanResultMessage">
                    <div class="ticket-entered"> 
                        <div class="callout text-center" 
                            :class = "(scanResultMessage == 'This promotion code has already been used.')?'warning':'success'">
                            {{  scanResultMessage }}
                        </div>
                        
                    </div>
                </div>

                <!-- <div class="error-ticket text-center" v-if="showErrMsg">
                    <p v-if="this.errorMessage">{{ this.errorMessage }}</p>
                    <p v-if="gameCodeError">{{ gameCodeError }}</p>
                    <button class="button secondary" value="Clear Fields" @click="clearInput">Clear Fields</button>
                </div> -->

                
            </div>

        </div>

        <SignInRegister v-if="!showPromotionCodeEntry"></SignInRegister>
    </div>
    
</template>

<script>

    import { HollowDotsSpinner } from 'epic-spinners'
    import SignInRegister from '../../ViewComponents/SignInRegister/SignInRegister.vue';

    export default {
        props: ['contentKey', 'requireDigits', 'placeholderProp', 'headingProp', 'submitTextProp', 'customClass'],
        components: {
            HollowDotsSpinner,
            SignInRegister
        },
        data: function () {
            return {
                showPromotionCodeEntry: true,
                token: '',
                heading: 'Enter your promotion codes below.',
                subheading: '',
                placeholder: 'Enter promotion code',
                submit: 'GO',
                entered: 'Entered Promo Codes',
                content: '',
                cssClass: '',
                inputValue: '',
                widgetContentKey: '',
                widgetCssClass: '',
                widgetCustomContent: '',
                gameLogo: '',
                logo: false,
                gameNumber: '',
                gamePrice: '',
                gameCodeError: '',
                isLoading: false,
                showResults: false,
                showGameCodeResults: false,
                showErrMsg: false,
                isLogoExist: false,
                disabled: false,
                resultsArray: [],
                validPromo: false,
                showScanResultMessage: false,
                scanResultMessage: ''
            }
        },
        mounted: function () {
            let apiToken = JSON.parse( sessionStorage.getItem('apiToken'));

            if(apiToken.user.username == "mobilepublic@mtllc.com"){
                this.showPromotionCodeEntry = false;
                return
            }

            let widgetContentKey = document.getElementById('content-key'),
                widgetCssClass = document.getElementById('custom-css'),
                widgetCustomContent = document.getElementById('custom-content');
            

            if(widgetContentKey !== null){ 
                this.contentKey = widgetContentKey.value;
                this.widgetCssClass = widgetCssClass.value;
                this.widgetCustomContent = widgetCustomContent.value;
                 
            }
            
            this.fetchContentByKey();

        },
        methods: {
            go(){
                this.isLoading = true;
                this.showGameCodeResults = false;
                this.getToken();
                // Add ability to launch modal from here for special promos based on a true/false prop passed from parent page.
            },
            clearInput(){
                this.inputValue = ""
                this.showErrMsg = false;
                this.showGameCodeResults = true;
                this.showGameCodeResults = false;
            },
            getToken() {
                // then hit API with token
                this.getAPItoken()
                .then((token) => {
                    this.token = token;
                    this.fetchData();
                });
            },
            fetchData() {
                let isnum = /^\d+$/.test(this.inputValue);
                if (isnum){
                    console.log('only numbers');
                    if (this.inputValue.length === 23 || this.inputValue.length > 29) {
                        console.log('too long');
                        // Show: The promotion code entered was not recognized.
                    } else {
                        this.submitTicket();
                    }
                } else {
                    console.log('Not just Numbers');
                    this.submitTicket();
                }
            },
            submitTicket() {
                const urlProtocol = this.getProtocol();
                let forSubmission = {
                    "serialNumber": this.inputValue,
                    "userId": JSON.parse(sessionStorage.getItem('TokenInfo') ).User.UserId,
                    "gameNumericIdentifier": '000'
                }

                let requestOptions = {
                    method: 'POST',
                    headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+ this.token
                    },
                    body: JSON.stringify(forSubmission)
                };

                return fetch(urlProtocol + process.env.VUE_APP_APIURL + "/1.0/TicketChecker/SubmitTicket", requestOptions)
                .then(response => response.json())
                .then(json => {
                    console.log(json);
                    let data = json.data;

                    if (json.error === null) {

                        if(data.scanResultMessage == "There are no active drawings for this promotion."){
                            this.isLoading = false;
                            this.showErrMsg = true;
                            this.errorMessage = "There are no active drawings for this promotion."
                        }
                        else if(data.scanResultMessage.length){
                            
                            this.isLoading = false;
                            this.showScanResultMessage = true;
                            this.scanResultMessage = data.scanResultMessage

                        }
                        else{                            
                            let result = {
                                icon: this.gameLogo,
                                serial: data.serialNumber,
                                message: data.submitMessage,
                                points: data.rewardsPointsValue
                            }

                            this.resultsArray.push(result);

                            this.showResults = true;
                        }

                    } else {
                        this.isLoading = false;
                        this.showErrMsg = true;
                        this.showScanResultMessage = false;
                        json.error.message === "Invalid Serial Number" ? this.errorMessage = "Invalid Promotion Code" : this.errorMessage = json.error.message;
                    }
                    
                })
                .catch(error => {
                    this.isLoading = false;
                    this.showErrMsg = true;
                    error.message === "Invalid Serial Number" ? this.errorMessage = "Invalid Promotion Code" : this.errorMessage = error;
                    console.log(error.message)
                });
            },
            fetchContentByKey(){
                this.getAPItoken()
                .then((token) => {
                    let apiToken = token;
                    let ContentKey = this.contentKey;

                    return fetch(process.env.VUE_APP_APIPROTOCOL+process.env.VUE_APP_APIURL+"/1.0/Games/StaticContentElements/StaticContentElement/GetContentElementByKey?contentKey="+ContentKey, { 
                        headers: {
                            'Authorization': 'Bearer '+apiToken,
                        }
                    })
                    .then(response => response.json())
                    .then(json => {
                        // console.log('placeholder '+ this.placeholderProp, 'heading '+ this.headingProp, 'submit '+ this.submitTextProp)

                        if (json.data.length > 0) {
                            let content = json.data[0].centralContent;
                            let parseContent = JSON.parse(content);

                            this.heading = parseContent.heading;
                            this.subheading = parseContent.subheading;
                            this.placeholder = parseContent.placeholder;
                            this.submit = parseContent.submit;
                            this.entered = parseContent.entered;
                            this.content = parseContent.content;
                            this.cssClass = parseContent.cssclass;
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    });
                });
            },
            onInput() {
                // let requireDigits = parseInt(this.requireDigits);
                // if (this.inputValue.length === requireDigits) {
                //     this.search = true;
                //     // this.disabled = true;
                //     let fourInputValue = this.inputValue;
                //     // this.fetchIcon(fourInputValue)
                // }
                // if (this.inputValue.length === 0) {
                //     this.search = false;
                // }
            }
           
        },
        computed: {
           
        },
    };
</script>

<style lang="scss" scoped>
    .promocodeentry {
        .ticket-entry-form {
            padding: 20px 40px;
        }
    }
    .ticket-entered {
        border-top: 1px solid #d3d3d3;
        padding-top: 20px;
    }
    .button.secondary {
        text-transform: none;
        letter-spacing: unset;
        display: inline-block;
        margin-left: 20px;
    }
    .error-ticket {
        p {
            color: #fc551e;
            display: inline-block;
            vertical-align: middle;
        }
    }

    .ticket-entry-results{
        h5, p{
            color: #000;
        }
    }

    .ticket-entry-form {
        .input-group {
            input {
                font-weight: 500;
            }

            .input-group-button {
                input {
                    border-radius: 20px !important;
                    // background-color: #5161ac;
                }
            }

            @include breakpoint(large) {
                display: flex;
                justify-content: space-evenly;
                align-items: center;

                input {
                    font-weight: 500;
                    margin-right: 20px;
                }

                .input-group-button {
                    // position: absolute;
                    // right: 40px;
                }
            }
        }
    }
</style>