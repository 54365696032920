<template>
        <main class="productDetailWrap cf">
            <!-- <SignInRegister v-if="!isUserLogged"></SignInRegister> -->
            <!-- <article v-if="isUserLogged"> -->
            <article class="product-sort">
                <Coupon v-if="coupon == true" :id="id"></Coupon>
                <Catalog v-if="catalog === true" :id="id"></Catalog>
                <Drawing v-if="drawing === true" :id="id"></Drawing>
                <Digital v-if="digital === true" :id="id"></Digital>
            </article>
        </main>
</template>

<script>
    // import SignInRegister from '../../ViewComponents/SignInRegister/SignInRegister.vue';
    import Coupon from './ProductDetail_Coupon.vue';
    import Catalog from './ProductDetail_Catalog.vue';
    import Drawing from './ProductDetail_Drawings.vue';
    import Digital from './ProductDetail_Digital.vue';

    export default {
        components:{
            // SignInRegister,
            Coupon,
            Catalog,
            Drawing,
            Digital
        },
        data: function () {
            return {
                id: 0,
                isUserLogged: false,
                coupon: false,
                catalog: false,
                drawing: false,
                digital: false,
                type: ''
            }
        },
        mounted: function () {  
            this.type = document.getElementById('type').value;

            this.getToken();
            this.id = document.getElementById("id").value;
            this.isUserLogged = this.isUserAuthed();
            this.getProductType();
        },
        methods: {
            getToken() {
                this.getAPItoken().then((token) => {
                    this.token = token;
                });
            },
            getCategories() {
                let category = sessionStorage.getItem('categoryList');

                if (!category) {
                    setTimeout(() => {
                        this.getCategories();
                    }, 500)
                } else {
                    this.categories = category;
                }

            },
            getProductType() {
                switch (this.type) {
                    case '1':
                        this.drawing = true;
                        break;
                    case '2':
                        this.coupon = true;
                        break;
                    case '3':
                        this.coupon = true;
                        break;
                    case '4':
                        this.digital = true;
                        break;
                    case '5':
                        this.catalog = true;
                        break;
                    case '6':
                        this.digital = true;
                        break;
                }
            }
        }
    };
</script>

<style lang="scss" scoped>

</style>