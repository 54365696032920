<template>
    <div class="survey-list">

        <div class="survey-row" v-for="(survey, index) in surveys" :key="index">
             <div class="survey-name"><b>Survey name:</b> {{survey.surveyName}}</div>
             <div class="survey-questions"></div>

             <span v-if="survey.hasRewards">   
                <div class="survey-rewards">
                    <span ><b>Survey reward:</b> {{survey.rewardsQuantity}}  {{survey.rewardsName}}</span>
                </div>
             </span>
             <div class="survey-btn">
                 <button class="button" @click="getId($event)" :id="survey.surveyIdentifier" :urlid="survey.surveyID">Start survey</button>
             </div>
        </div>

    </div>
</template>


<script>
    import $ from 'jquery';
    let appSurveyID = $('#appSurveyData').data('surveyid');
    let appSurveyToken = $('#appSurveyData').data('surveytoken');
    let appSurveyUserID = $('#appSurveyData').data('userid');
    let appSurveyUserName = $('#appSurveyData').data('username');
    if(appSurveyID == 'undefined'){
        appSurveyID = '';
    }
    if(appSurveyToken == 'undefined'){
        appSurveyToken = '';
    }
    if(appSurveyUserID == 'undefined'){
        appSurveyUserID = '';
    }
    if(appSurveyUserName == 'undefined'){
        appSurveyUserName = '';
    }

    export default {
        components: {
        },
        data: function () {
            return {
                surveys: [],
                surveyToken: appSurveyToken,
                surveyID: appSurveyID,
                userID: appSurveyUserID,
                userName: appSurveyUserName
            }
        },
        mounted: function () {
            const url = window.location.href;
            const arr = url.split('?');
            // need to specify the param we're looking for here
            if (arr.length > 1 && arr[1] !== '') {
                console.log('params found');
                this.urlSurvey();
                this.goToSurvey()
            }else if(  this.surveyID != undefined || this.surveyID != null){
                console.log('surveyID:'+this.surveyID);
                // assumes survey loaded from app and requires all 4 vars
                // check the other 3
                if( this.surveyToken == "" || this.userID == "" || this.userName == "" ){
                    // return a friendly error
                    return this.errorMessage = 'This survey isn\'t avialable at the moment. Code[499]';
                }else{
                    this.goToSurvey();
                }
            }else{
                this.getToken();
            }
        },
        methods: {
            urlSurvey(){
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                const surveyIDParam = urlParams.get('surveyID')
                this.surveyID = surveyIDParam;
            },
            getToken() {
                // then hit API with token
                this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });
            },
            fetchData(token) {
                var apiToken;
                console.log(this.surveyToken)
                if(this.surveyToken != undefined || this.surveyToken != null ){
                    apiToken = this.surveyToken;
                }else{
                    apiToken = token;
                }
                var urlProtocol = 'https:';

                if( this.userID == "" || this.userID === undefined || this.userID === null && this.userName == "" || this.userName === undefined || this.userName === null  ){
                    var getCurrentUser = JSON.parse( sessionStorage.getItem('apiToken'));
                    console.log(getCurrentUser)
                    this.userID = getCurrentUser.user.userId;
                    this.userName = getCurrentUser.user.username;
                    console.log('Dispatch below:');
                    // this.$store.dispatch('setCurrentUser', {
                    //     userId: this.userID,
                    //     username: this.username
                    // });
                }

                let userId = parseInt(this.userID)

                console.log(userId)
                console.log(apiToken)

                 return fetch(urlProtocol+process.env.VUE_APP_APIURL+"/1.0/Survey/Survey/GetActiveSurveys?userID="+this.userID, {
                    headers: {
                        'Authorization': 'Bearer '+apiToken,
                    },
                })
                .then(response => response.json())
                .then(json => {
                    console.log(json)
                    var data = json.data;
                    this.surveys = data;

                })
                .catch(error => {
                    // console.error("There was an error!", error);
                    return this.errorMessage = error;
                });
            },
            getId(event){
                const params = new URLSearchParams({
                    surveyID: event.target.getAttribute('urlid')
                });

                const paramString = params.toString();

                window.history.pushState( {} , '', '?'+paramString );
                this.$emit('getIdSurvey', event.target.getAttribute('urlid'))
            },
            goToSurvey(){
                this.$emit('getIdSurvey', this.surveyID, this.userID, this.surveyToken)
            }
        }
    };
</script>

<style lang="scss" scoped>
.survey-row {
    border-bottom: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 20px;
}
</style>