<template>
  <section class="logon-panel-password-retrieval desktop-login-content">
    <div id="retrievePassword">
      <form data-abide novalidate>
        <h1>Forgot Your Password</h1>
        <p v-if="!message">
          Enter the email address associated with your account. We'll email you
          a password recovery link.
        </p>
        <div class="form-password-reset" role="form">
          <div v-if="!message" class="field-group">
            <label for="email" class="field-label">
              Enter Email Address *
            </label>
            <input
              name="email"
              type="text"
              class="form-field"
              placeholder="Enter Email Address *"
              v-model="email"
              pattern="email"
              required
            />
            <span class="form-error">Please provide a valid Email Address</span>
          </div>
          <div v-if="message" class="callout success" data-closable>
            <small v-html="message"></small>
          </div>
          <button
            v-if="!message"
            class="button btn-primary"
            @click="forgotPassword"
            :disabled="disabled"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import $ from "jquery";

export default {
  data: function () {
    return {
      token: "",
      email: "",
      message: "",
      disabled: false,
    };
  },
  mounted: function () {
    $(document).foundation();
    this.getToken();
  },
  methods: {
    getToken() {
      this.getAPItoken().then((token) => {
        this.token = token;
      });
    },
    forgotPassword() {
      let _this = this;
      $(document)
        .on("forminvalid.zf.abide", function (ev, frm) {
          _this.message = "";
        })
        .on("formvalid.zf.abide", function (ev, frm) {
          _this.disabled = true;
          _this.getForgottenPassword();
        })
        .on("submit", function (ev) {
          ev.preventDefault();
        });
    },
    getForgottenPassword() {
      let apiToken = this.token,
        urlProtocol = this.getProtocol(),
        url = process.env.VUE_APP_APIURL + "/1.0/PAM/ForgotPassword";
      let bodyAPI = {
        userName: this.email,
      };
 
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + apiToken,
        },
        body: JSON.stringify(bodyAPI),
      };

                fetch(urlProtocol + url, requestOptions)
                    .then(response => response.json())
                    .then(json => {
                        this.message = "If we recognize <strong>" + this.email + "</strong> as an address associated with an account, we will send a password update link. Follow the instructions in the email to finish resetting your password.";
                        this.disabled = false;
                    })
                    .catch(error => {
                        this.disabled = false;
                    })
                
                    
                },
                resetForm() {
                    this.message='';
                    this.email= '';
                }
        }
    }
</script>

<style lang="scss" scoped>
#globalLogInModal{
  .desktop-login-content{
    display: block !important;
  }
}


#retrievePassword .callout {
  padding-top: 2rem;
}

#retrievePassword .close-button {
  top: 4px;
  right: 2px;
  min-height: auto;
}

.success {
  background-color: $wash_green_brighter2;
}
</style>
