<template>
    <div>
        <User></User>
        <section class="categoryPageHeader">
            <h1>
                {{ headline }}
            </h1>
        </section>
        <div class="module">
            <section class="productsNav">
                <div class="productCount">{{ itemCount }} items</div>
                <div class="productSort">
                    <div class="productSort-label">
                        <p>
                            Sort by:
                        </p>
                    </div>
                    <select name="productSortSelect" id="productSortSelect" class="productSort-select" @change="sort">
                        <option value="0">Points Low to High</option>
                        <option value="1">Points High to Low</option>
                    </select>
                </div>
            </section>
            <section v-if="currentItems.length" class="catalogList">
                <ul class="productList">
                    <li v-for="product in currentItems" :key="product.skuNumber" class="product">
                        <span class="productPrice">
                            {{product.price.toLocaleString()}} Points
                        </span>
                        <div class="productItem cf callout light boxShadow">
                            <a :href="product.nodeAliasPath" class="productImg">
                                <img :src="product.image" alt="product.description">
                            </a>
                        </div>
                        <article class="productInfo">
                            <a :href="product.nodeAliasPath" class="productTitle">
                                {{product.name}}
                            </a>
                        </article>
                    </li>
                </ul>
            </section>
            <Pagination :totalPages="totalPages" :itemsPerPage="itemsPerPage" :currentPage="currentPage" @changePage="changePage" :center="true" :right="true"></Pagination>
        </div>
    </div>
</template>

<script>
    import AdditionalRewardsVue from '../AdditionalRewards.vue'
    import Pagination from '../../Pagination/pagination.vue'
    import User from '../../Shared/User-Message.vue'

    export default {
        components: {
            AdditionalRewardsVue,
            Pagination,
            User
        },
        data: function() {
            return {
                token: '',
                products: [],
                games: [
                    {
                        name: 1,
                        id: 2
                    }
                ],
                itemCount: 0,
                items: 0,
                totalPages: 0,
                itemsPerPage: this.items / this.totalPages,
                currentPage: 1,
                currentItems: [],
                headline: '',
                categories: ''
            }
        },
        mounted: function() {
            this.getToken()
            this.headline = document.getElementById("sectionName").value;
        },
        methods: {
            getToken() {
                this.getAPItoken()
                .then((token) => {
                    this.token = token;
                    // Get categories from Session Storage
                    this.returnCategories(token);
                });
            },
            returnCategories(token) {
                let categories = sessionStorage.getItem('categoryList');

                // If sessionStorage categoryList doesn't exist, repeat in 1/2 second.
                if (categories) {
                    this.categories = JSON.parse(categories);
                    // Get URL Codename and compare to Category List
                    this.getURL();
                    // this.getProducts(token);
                } else {
                    setTimeout(this.returnCategories, 500);
                }
            },
            getURL() {
                let url = window.location.pathname.toLowerCase(),
                    urlQuery = url.replace('/rewards-catalog/', ''),
                    data = this.categories;

                    for (let i = 0; i < data.length; i++) {
                        let section = data[i].codeName.toLowerCase(),
                            id = data[i].id;

                        if (urlQuery === section) {
                            this.getCategory(id);
                        }
                    }
            },
            getCategory(id) {
                let apiToken = this.token,
                    urlProtocol = this.getProtocol(),
                    url = process.env.VUE_APP_APIURL+'/1.0/Ecommerce/GetProductbyCategory?categoryId=' + id;

                return fetch(urlProtocol +  url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + apiToken
                    }
                })
                .then(response => response.json())
                .then(json => {
                    if (json.data) {
                        this.setStock(json.data);
                    } else {
                        this.error = true;
                        this.errorMessage = "There are currently no products in stock. Please check back later."
                    }
                })
                .catch(error => {
                    console.log(this.errorMessage = error);
                })
            },
            sort(a) {
                let value = a.target.value,
                    items = this.items,
                    sorted;

                if (value == 0) {
                    sorted = items.sort((x,y) => {
                        return x.price - y.price;
                    })
                }

                if (value == 1) {
                    sorted = items.sort((x,y) => {
                        return y.price - x.price;
                    })
                }

                this.setItems();
            },
            setStock(json) {
                let data = json,
                    products = [];

                for (let i = 0; i < data.length; i++) {
                    data[i].stock > 0 ? products.push(data[i]) : false;
                }

                this.itemCount = data.length;

                this.items = products;
                this.products = products;

                this.setPages();
                this.setItems();
            },
            setPages() {
                // Sets current pages, items per page, max pages etc.
                // Add the folloing to you data object

                // items: (get this from the api),
                // totalPages: 0,
                // itemsPerPage: this.items / this.totalPages,
                // currentPage: 1,
                // currentItems: []

                // Then add:
                // this.setPages();
                // this.setItems();
                // to the end of your API call function chain once this.items has been set
                
                this.itemsPerPage = 24;
                this.totalPages = Math.ceil(this.items.length / this.itemsPerPage);
            },
            changePage(p) {
                this.currentPage = p;
                this.setItems();
            },
            setItems() {
                // Sets current items to show up in pagination
                 

                let start = (this.itemsPerPage * this.currentPage) - this.itemsPerPage,
                    end = (this.itemsPerPage * this.currentPage),
                    currentItems = this.items.slice(start, end);

                this.currentItems = currentItems;
            }
        }
    }
</script>