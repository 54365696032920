<template>
    <div class="content_PressReleaseDetail">
        
        <hollow-dots-spinner
                :animation-duration="1000"
                :dot-size="15"
                :dots-num="3"
                color="#0FD8C9"
                v-if="isLoading"
        />


        <h1>{{ title }}</h1>
        
        <span v-html="content"></span>

    </div>
</template>

<script>
   // import moment from 'moment';
    import { HollowDotsSpinner } from 'epic-spinners';

    export default {
        props: ['articleId'],
        components: {
            HollowDotsSpinner
        },
        data: function () {
            return {
                articleIdDetail: this.articleId,
                title: '',
                content: '',
                isLoading: false
            }
        },
        mounted(){
            this.getToken()
            this.isLoading = true;
        },
        methods:{
            getToken() {
                // then hit API with token
                this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });
            },
            fetchData(token) {
                let apiToken = token,
                    urlProtocol = this.getProtocol(),
                    id = this.articleIdDetail;
                    
                return fetch(urlProtocol+process.env.VUE_APP_APIURL+"/1.0/Games/Article/GetById/"+id, {
                    headers: {
                        'Authorization': 'Bearer '+apiToken,
                    },
                })
                .then(response => response.json())
                .then(json => {
                    let res = json.data;
                    this.title = res.title;
                    this.content = res.content;
                    this.isLoading = false;

                })
                .catch(error => {
                    // console.error("There was an error!", error);
                    return this.errorMessage = error;

                });
            }
        },
        computed: {
        }
    };
</script>

<style lang="scss" scoped>


</style>