<template>
    <section class="forgot_password">
        <form data-abide novalidate data-validate-on-blur="true" id="forgotPasswordForm" v-if="hiddeForm">
            <div class="grid-x grid-margin-x block-section">
                <div class="grid-x grid-margin-x align-justify block-section">
                    <div class="cell large-12">
                        <p>Enter the Email associated with your account. We'll email you a password recovery link.</p>
                    </div>

                    <div class="cell large-12">
                        <label>
                            <input type="text" v-model="dataUser.userName" id="user-email" placeholder="Email Address *"
                                pattern="email" required>
                            <span class="form-error">Please provide a valid Email Address</span>
                        </label>
                    </div>
                    <div class="cell large-12">
                        <button class="button submit-button" @click="submitForm">Send</button>
                    </div>
                </div>
            </div>
        </form>
        <!-- MODAL -->
        <div :class="{ 'reveal callout terms-modal': true, 'error-modal': modal.modalError }" id="modalFP" data-reveal
            data-close-on-click="false">
            <div class="grid-x">
                <h5>{{ modal.title }}</h5>
                <div class="cell small-12">
                    <div v-html="modal.description"></div>
                </div>
            </div>
            <button type="button" class="close-button" data-close aria-label="Close modal" v-if="modal.btnClose">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </section>
</template>

<script>
import $ from 'jquery';
export default {
    components: {},
    data: function () {
        return {
            token: '',
            isUserLogged: false,
            dataUser: {
                userName: ''
            },
            modal: {
                title: '',
                description: '',
                modalError: false
            },
            hiddeForm: false
        }
    },
    mounted: function () {
        $(document).foundation();
        this.getToken();
    },
    methods: {
        getToken() {
            this.isUserLogged = this.isUserAuthed();
            this.getAPItoken()
                .then((token) => {
                    this.token = token;
                });
        },
        openModal(modalID) {
            $('#' + modalID).foundation('open');
        },
        closeModal(modalID) {
            $('#' + modalID).foundation('close');
        },
        submitForm(e) {
            let _this = this;
            $(document)
                .on("forminvalid.zf.abide", function (ev, frm) {
                    console.log("FORM ERROR");
                })
                .on("formvalid.zf.abide", function (ev, frm) {
                    _this.forgotPassword();
                    //MODAL
                    _this.modal = {
                        title: "Forgot Password",
                        description: "Please wait a moment...",
                        modalError: false
                    }
                    _this.openModal('modalFP');
                })
                .on("submit", function (ev) {
                    ev.preventDefault();
                });
        },
        forgotPassword() {
            //FORM
            var apiToken = this.token
            let body = this.dataUser;

            const methodHeaderData = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + apiToken
                },
                body: JSON.stringify(body)
            };

            fetch("https:" + process.env.VUE_APP_APIURL + "/1.0/PAM/ForgotPassword", methodHeaderData)
                .then(response => response.json())
                .then(json => {
                    if (json.data == null) {
                        //MODAL
                        this.modal = {
                            title: "ERROR: Forgot Password",
                            description: "<p>" + json.error.message + "</p>",
                            modalError: true,
                            btnClose: true
                        }
                    } else {
                        this.modal = {
                            title: "Forgot Password",
                            description: "<p>We'll email you a password recovery link.</p>",
                            btnClose: true
                        }
                        this.openModal('modalFP');
                    }
                });
        },
        hiddeOnResetPasswordSucceeded() {
            var checkUrl = window.location.href;

            if (checkUrl.includes('/Account/ForgotPassword')) {
                this.hiddeForm = false;
            } else {
                this.hiddeForm = true;
            }
        }
    }
};
</script>
<style scss-scoped>
.forgot_password {
    button.submit-button {
        min-width: 20rem;
    }
}
</style>