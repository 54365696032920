<template>
    <div class="search-box siteSearch desktopOnly">
        <div id="pnlSearch" class="searchBox">
            <input class="siteSearch__val clear-default searchPlaceholder" id="txtWord" maxlength="120" name="SearchText" placeholder="Search" type="text" value="">
            <input type="submit" value="Go" id="btnSearch" class="siteSearch__button">
            <div id="pnlPredictiveResultsHolder" class="predictiveSearchHolder"></div>
        </div>
    </div>
</template>


<script>


export default{
    components: {
        
    },
    data(){
        return{
        };
    },

    mounted(){
    },
    methods:{
    }
}



</script>


<style>


</style>