<template>
    <section class="delete_account_confirm">
        <form data-abide novalidate data-validate-on-blur="true" id="deleteAccountForm">
            <div class="grid-x align-justify block-section email-inputs">
                <div class="cell large-12">
                    <label>
                        <input type="text" v-model="dataUser.email" id="user-email" placeholder="Email Address *"
                            pattern="email" readonly required>
                        <span class="form-error">Please provide a valid Email Address</span>
                    </label>
                </div>
                <div class="cell large-12">
                    <label>
                        <input type="password" id="user-password" v-model="dataUser.password" placeholder="Password *"
                            pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$" required>
                        <span class="form-error">Please provide your Password</span>
                    </label>
                </div>
                <div class="cell large-12">
                    <label>
                        <input type="password" placeholder="Confirm Password *" data-equalto="user-password" required>
                        <span class="form-error">Password do not match</span>
                    </label>
                </div>
            </div>
            <!-- SUBMIT FORM / CANCEL -->
            <div class="grid-x grid-margin-x block-section">
                <div class="cell large-12 action-section">
                    <button class="button submit-button" id="btnGet" @click="submitForm">Delete my account</button>
                    <a href="/">I changed my mind</a>
                </div>
            </div>
        </form>
        <input type="hidden" name="user-id" :value="dataUser.userId" id="user-id"> 
        <!-- MODAL -->
        <div :class="{ 'reveal callout terms-modal': true, 'error-modal': modal.modalError }" id="modalDeleteAccount"
            data-reveal data-close-on-click="false">
            <div class="grid-x">
                <h5>{{ modal.title }}</h5>
                <div class="cell small-12">
                    <div v-html="modal.description"></div>
                </div>
            </div>
            <button type="button" class="close-button" data-close aria-label="Close modal" v-if="modal.btnClose">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        
    </section>
</template>

<script>
import $ from 'jquery';

export default {
    props: [],
    components: {},
    data: function () {
        return {
            token: this.userToken,
            isUserLogged: false,
            dataUser: {
                userId: '',
                email: '',
                password: ''
            },
            modal: {
                title: '',
                description: '',
                modalError: false,
                btnClose: false
            }
        }
    },
    mounted: function () {
        $(document).foundation();

        this.isUserLogged = this.isUserAuthed();
        this.getToken();
    },
    methods: {
        getToken() {
            this.getAPItoken()
                .then((token) => {
                    this.token = token;
                    this.getUserData();
                });
        },
        getUserData() {
            let _dataUser = JSON.parse( sessionStorage.getItem('apiToken'));
            this.dataUser.userId = _dataUser.user.userId;
            this.dataUser.email = _dataUser.user.email;
        },
        openModal(modalID) {
            $('#' + modalID).foundation('open');
        },
        closeModal(modalID) {
            $('#' + modalID).foundation('close');
        },
        submitForm(e) {
            let _this = this;
            $(document)
                .on("forminvalid.zf.abide", function (ev, frm) {
                    // console.log("FORM ERROR");
                })
                .on("formvalid.zf.abide", function (ev, frm) {
                    _this.deleteUserAccount();
                    //MODAL
                    _this.modal = {
                        title: "Deleting your data",
                        description: "<p>please wait a few moment...</p>"
                    }
                    _this.openModal('modalDeleteAccount');
                })
                .on("submit", function (ev) {
                    ev.preventDefault();
                });
        },
        testLogout() {
            let button = document.getElementById('forceLogout');

            button.click();
        },
        deleteUserAccount() {
            //FORM
            var apiToken = this.token
            let body = {
                "email": this.dataUser.email,
                "userId": this.dataUser.userId,
                "password": this.dataUser.password
            };
            
            const methodHeaderData = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + apiToken
                },
                body: JSON.stringify(body)
            };

            fetch("https:" + process.env.VUE_APP_APIURL + "/1.0/PAM/DeleteUser", methodHeaderData)
            .then(response => response.json())
            .then(json => {
                if (json.data == null) {
                    //MODAL
                    this.modal = {
                        title: "ERROR: Delete Your Account",
                        description: "<p>" + json.error.message + "</p>",
                        modalError: true,
                        btnClose: true
                    }
                } else {
                    this.modal = {
                        title: "Delete Your Account",
                        description: "<p>Your Account has been deleted.</p><p>You are being redirected.</p>"
                    }
                    // this.openModal('modalAccountProfile');

                    setTimeout(() => {
                        // this.$cookies.remove('apiToken')
                        // this.$cookies.remove('TokenInfo')
                        this.$cookies.remove('forgotPassword')
                        // this.$cookies.remove('identity.authentication')
                        this.testLogout();
                    }, 2000);
                }
            });
        },
    }
};
</script>