<template>
    <section class="autologout-wrapper">

        <div class="modal-container"  v-if="warningZone">
            
            <div class="modalAutoLogout" id="modalAutoLogout">
                <div class="grid-x">
                    <div class="cell small-12">
                        <div class="text-center">
                            <h2>Are you still there?</h2>
                            <a @click="getRefreshToken()" class="continue-link">Click to continue</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import $ from 'jquery';

import moment from 'moment';

export default {
    data: function () {
        return {
            events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],

            firstLoad: true,
            token: '',
            apiRefreshToken: '',
            username: '',
            timeRemaining: '',
            refreshTimeRemaining: '',

            warningTimer: null,
            logoutTimer: null,
            warningZone: false,
            updateRefreshToken: false,
        }
    },
    mounted: function () {
        this.trackEvents()
        //this.getRefreshToken();

        $(".autologout-wrapper").appendTo("body");

        let tokenInfo = JSON.parse(sessionStorage.getItem('TokenInfo') );


        this.apiRefreshToken = tokenInfo.refreshToken.token
        this.username = tokenInfo.user.username
    },

    destroyed: function(){
        this.events.forEach( function (event){
            window.removeEventListener(event, this.resetTimer)
        }, this)

        this.resetTimer();

    },

    methods: {
        trackEvents(){
            this.events.forEach( function (event){
                window.addEventListener(event, this.resetTimer)
            }, this)

            this.setTimers();
        },

        getRefreshToken() {
            this.warningZone = false
            document.getElementsByClassName('continue-link')[0].classList.add("disabled-link");
      
            this.refreshToken();
        },
        calculateTimeRemaining () {           
            let formatExpire = sessionStorage.getItem('tokenExpires').split('.')[0].replace(/['"]+/g, '');
            let formatCurrentDate;
            formatCurrentDate = moment(  new Date() )
    
            let token = JSON.parse( sessionStorage.getItem('TokenInfo')),
                expire = moment(  formatExpire  ),
                refreshTime = moment(token.refreshToken.expires),
                currentTime =  formatCurrentDate;

            

            let timeLeft = expire.diff(currentTime, 'minutes');

            this.timeRemaining = timeLeft;
            this.refreshTimeRemaining = refreshTime.diff(currentTime, 'minutes');

            return timeLeft;
        },
        refreshToken() {

            let username = this.username,
                refreshToken = this.apiRefreshToken,
                protocol = this.getProtocol();


             let loginCreds = {
                "userName": username,
                "refreshToken": refreshToken
            }


            return fetch(protocol+process.env.VUE_APP_AUTHURL+'/1.0/Authentication/RefreshToken', {
                method: 'POST',
                body: JSON.stringify(loginCreds),
                headers: {
                    'Content-Type': 'application/json-patch+json'
                }
            })
            .then(response => response.json())
            .then((response) => {
                let res = response;
                this.setToken(res);
                this.updateRefreshToken = false;
            })
        },
        submitRefresh(response) {
            let tokenObj = {};
            tokenObj = response.data,
            tokenInfo = JSON.parse(sessionStorage.getItem('TokenInfo') );
  

            if (tokenObj.token === null || tokenObj.token === undefined || tokenObj.token === "") {
                if (tokenObj.refreshToken.isExpired === true) {
                    console.log('Logout process..')
                    document.querySelector('.sign-out-button').click();
                } else {
                    console.log('Refresh token process...')
                    this.getRefreshToken();
                }
            } else {
                var tokenExpires = new Date(tokenObj.expires);
                tokenExpires = tokenExpires.toUTCString();

                this.setToken(tokenObj);
            }
        },
        setToken(data) {
            let tokenObj = data.data,
                tokenInfo = JSON.parse(sessionStorage.getItem('TokenInfo') );

            if (tokenInfo) {
                     tokenInfo.token = tokenObj.token;
                     tokenInfo.message = tokenObj.message;
                     tokenInfo.expires = tokenObj.expires;
                     tokenInfo.isExpired = tokenObj.isExpired;
                     tokenInfo.minutesToExpiration = tokenObj.minutesToExpiration;
                     tokenInfo.isAuthenticated = tokenObj.isAuthenticated;
                     tokenInfo.refreshToken.token = tokenObj.refreshToken.token;
                     tokenInfo.refreshToken.currentDatetime = tokenObj.refreshToken.currentDateTime;
                     tokenInfo.refreshToken.expires = tokenObj.refreshToken.expires;
                     tokenInfo.refreshToken.isExpired = tokenObj.refreshToken.isExpired;
                     tokenInfo.refreshToken.minutesToExpiration = tokenObj.refreshToken.minutesToExpiration;
                     tokenInfo.refreshToken.created = tokenObj.refreshToken.created;
                     tokenInfo.refreshToken.revoked = tokenObj.refreshToken.revoked;
                     tokenInfo.refreshToken.isActive = tokenObj.refreshToken.isActive;
                     tokenInfo.refreshToken.lastUpdated = tokenObj.refreshToken.lastupdated;

                    sessionStorage.setItem('TokenInfo', JSON.stringify(tokenObj));
                    sessionStorage.setItem("apiToken" , JSON.stringify(tokenObj) )
                    sessionStorage.setItem("tokenExpires", JSON.stringify(tokenObj.expires) )
                
                    let tokenInfoSetted = JSON.parse(sessionStorage.getItem('TokenInfo') );
                    this.apiRefreshToken = tokenInfoSetted.refreshToken.token

            //     console.log("= TokenInfo =")
                
            }  


            

            this.token = tokenObj;
            
            //this.getTokenTime();
        },

        setTimers() {
            this.warningTimer = setInterval(this.warningMessage, 45 * 1000)
            this.logoutTimer = setTimeout(this.logoutUser, 900 * 1000)           
        }, 

        warningMessage() {
            //this.warningZone = true
            //let refreshTimeRemaining = this.calculateTimeRemaining();
     

            let formatExpire = JSON.parse(sessionStorage.getItem('TokenInfo') );
            let formatCurrentDate;
            formatCurrentDate = moment(  new Date() )

            let token = JSON.parse( sessionStorage.getItem('apiToken')),
                expire = moment( formatExpire.expires ),
                currentTime = formatCurrentDate,
                timeLeft = expire.diff(currentTime, 'minutes');            
            
            

            // Check refreshToken time remaining
            if (timeLeft < 5) {
                this.updateRefreshToken = true;
            } 
            
            if (timeLeft < 1){
                console.log('Logout process..')
                document.querySelector('.sign-out-button').click();
            }
            
        },

        logoutUser() {
            console.log('Logout process..')
            document.querySelector('.sign-out-button').click();
        },

        resetTimer() {
            clearInterval(this.warningTimer)
            clearTimeout(this.logoutTimer)
            this.setTimers();
            
            let refreshTimeRemaining = this.calculateTimeRemaining()
            // Check refreshToken time remaining
            if (refreshTimeRemaining < 5) {
                this.updateRefreshToken = true;
                
            }
        },
        
    },
    watch: {
        updateRefreshToken: function (val) {
            if(val === true){
                this.warningZone = true
            }
        }
    }
};
</script>

<style scoped lang="scss">
.modal-container {
    position:fixed;
    top:50%;
    left:50%;
    width: 300px;
   height: auto;
   background-color: white;
   padding: 20px;
   box-shadow:0px 0px 20px #000;;
   border-radius: 10px;
    z-index:1;
}

.modalAutoLogout{
}

.disabled-link {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}
</style>