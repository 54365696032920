import {mapActions, mapGetters} from 'vuex';
import Vue from 'vue';

import VueSanitize from 'vue-sanitize';
import sanitizeHtml from 'sanitize-html';
Vue.use(VueSanitize);

export default {
    data: function() {
        return {
            token: ''
        }
    },
    methods: {
        isUserAuthed() {
            let userData = JSON.parse ( sessionStorage.getItem('apiToken') ) ;

            let userName = userData.user.username;
            
            if (userName === 'mobilepublic@mtllc.com') {
                // console.log('public user');
                return false;
            } else if (userName !== "" && userName !== undefined) {
                console.log('authed user');
                return true;
            } else {
                // console.log('no user, no token?');
                return false;
            }
        },
        forceLogout(user) {
            let userName = '';

            if (user) {
                userName = user;
            } else {
                let token = JSON.parse( sessionStorage.getItem('apiToken'));
                userName = token.user.username;
            }

            let param = {
                "userName": userName,
                "parameters": {
                    "additionalProp1": "string",
                    "additionalProp2": "string",
                    "additionalProp3": "string"
                }
            };

            return fetch(protocol+process.env.VUE_APP_AUTHURL+'/1.0/Authentication/Logout', {
                method: 'POST',
                body: JSON.stringify(param),
                headers: {
                    'Content-Type': 'application/json-patch+json'
                }
            })
            .then(response => response.json())
            .then((response) => {
                    let res = response;
                    console.log(res);
            })
            .catch(error => {
                console.log('Logout Error: ' + error);
            });
        },
        getRefereshToken(apiToken) {

            // console.log(apiToken);

            // let username = process.env.VUE_APP_APIUSER;
            let username = apiToken.User.Username;
            let password = "string";
            let refreshToken = apiToken.RefreshToken.Token;
            let protocol = this.getProtocol();

            let loginCreds = {
                "userName": username,
                "password": password,
                "refreshToken": refreshToken
            }

            // console.log('refreshToken: '+refreshToken);

            return fetch(protocol+process.env.VUE_APP_AUTHURL+'/1.0/Authentication/RefreshToken', {
                method: 'POST',
                body: JSON.stringify(loginCreds),
                headers: {
                    'Content-Type': 'application/json-patch+json'
                }
            })
            .then(response => response.json())
            .then((response) => {
                // var resStatus = response;
                // console.log('response: '+response.ok+' resStatus: '+resStatus);
                // if (response.ok) {
                    let res = response;
                    console.log(res);
                    this.submitRefresh(res);
                // } else {
                    // console.log('token error: '+response+' resStatus: '+resStatus);
                    // throw new Error('Data API access temporarily down. Please try again later.');
                // }
            })
            .catch(error => {
                console.log('token refresh error: '+error);
                // reset and try again
                this.$cookies.remove('apiToken');
                return this.getNewAPItoken();
                // return error;
            });
        },
        submitRefresh(response) {
            var tokenObj = {};

            tokenObj = response.data;
            // console.log(tokenObj);

            // check if token exists
            // console.log('refreshed token: '+tokenObj.token);
            if (tokenObj.token === null && tokenObj.token === undefined && tokenObj.token === "") {
                // reset and try again
                // console.log(response);
                // console.log('remove token cookie');
                // this.$cookies.remove('apiToken');
                // return this.getNewAPItoken();
            } else {
                var tokenExpires = new Date(tokenObj.token.expires);
                tokenExpires = tokenExpires.toUTCString();
                sessionStorage.setItem('apiToken', JSON.stringify(tokenObj));
                sessionStorage.setItem("tokenExpires", JSON.stringify(tokenExpires) )
                let tokenInfo = JSON.parse(sessionStorage.getItem('TokenInfo') );
                if (tokenInfo) {
                    tokenInfo.Token.Token = tokenObj.token.token;
                    tokenInfo.Message = tokenObj.message;
                    tokenInfo.Expires = tokenObj.expires;
                    tokenInfo.IsExpires = tokenObj.isExpired;
                    tokenInfo.MinutesToExpiration = tokenObj.minutesToExpiration;
                    tokenInfo.IsAuthenticated = tokenObj.isAuthenticated;
                    tokenInfo.RefreshToken.Token = tokenObj.refreshToken.token;
                    tokenInfo.RefreshToken.CurrentDatetime = tokenObj.refreshToken.currentDateTime;
                    tokenInfo.RefreshToken.Expires = tokenObj.refreshToken.expires;
                    tokenInfo.RefreshToken.IsExpired = tokenObj.refreshToken.isExpired;
                    tokenInfo.RefreshToken.MinutesToExpiration = tokenObj.refreshToken.minutesToExpiration;
                    tokenInfo.RefreshToken.Created = tokenObj.refreshToken.created;
                    tokenInfo.RefreshToken.Revoked = tokenObj.refreshToken.revoked;
                    tokenInfo.RefreshToken.IsActive = tokenObj.refreshToken.isActive;
                    tokenInfo.RefreshToken.LastUpdated = tokenObj.refreshToken.lastupdated;

                    //this.$cookies.set('TokenInfo', JSON.stringify(tokenInfo), "/", tokenInfo.RefreshToken.Expires, false);
                    sessionStorage.setItem('TokenInfo', JSON.stringify(tokenInfo));
                    sessionStorage.setItem("TokenInfoExpires", JSON.stringify(tokenInfo.RefreshToken.Expires) )
                }
                // this.token = tokenObj;
                // return tokenObj.token;                   
            }
        }, 
        getNewAPItoken() {
            // console.log('== get new token with Vue ==');
            let username = process.env.VUE_APP_APIUSER;
            let password = process.env.VUE_APP_APIPASS;
            let protocol = this.getProtocol();

            let loginCreds = {
                "userName": username,
                "password": password
            }
            return fetch(protocol+process.env.VUE_APP_AUTHURL+'/1.0/Authentication/Login', {
                method: 'POST',
                body: JSON.stringify(loginCreds),
                headers: {
                    'Content-Type': 'application/json-patch+json'
                }
            })
            .then(response => {
                var resStatus = response.status;
                // console.log('response: '+response.ok+' resStatus: '+resStatus);
                if (response.ok) {
                    // console.log(response.json());
                    return response.json();
                } else {
                    // console.log('token error: '+response+' resStatus: '+resStatus);
                    throw new Error('Data API access temporarily down. Please try again later.');
                }
            })
            .then(response => {
                var tokenObj = {};
                tokenObj = response.data;
                // check if token exists
                if (tokenObj.token !== null && tokenObj.token !== undefined && tokenObj.token !== "") {
                    var tokenExpires = new Date(tokenObj.token.expires);
                    tokenExpires = tokenExpires.toUTCString();
                    console.log('set session storage')
                    sessionStorage.setItem('apiToken', JSON.stringify(tokenObj));
                    sessionStorage.setItem("tokenExpires", JSON.stringify(tokenExpires) );
                    this.token = tokenObj;
                    return tokenObj.token;
                } else {
                    // console.log('== get token error ==');
                    this.$cookies.remove('apiToken');
                    // nothing to do if this fails, the site is down.
                    return "error";
                }
            })
            .catch(error => {
                // console.log('get token error: '+error);
                // nothing to do if this fails, the site is down.
                // return error;
            });

        },
        returnToken() {
            return this.token;
        },
        getAPItoken() {
            // check if a token is still valid
            let apiToken =  JSON.parse(sessionStorage.getItem('apiToken') ) ;

            //console.log(apiToken)

            let tokenExpires = '0';
            
            // return token if still valid
            if (apiToken !== null && apiToken !== undefined) {
                if (apiToken.expires !== null) {
                    tokenExpires =  new Date(apiToken.expires);
                }
            }
            let rightNow = new Date();
            // console.log(rightNow+" < "+tokenExpires);
            if (rightNow < tokenExpires) {
                // token is still current
                // console.log(apiToken.token);
                return Promise.resolve(apiToken.token);
            } else if (apiToken === undefined) {
                if (apiToken.error == null || apiToken.error == undefined) {
                    // refresh token
                    // this.getRefereshToken(apiToken);
                }
            } else {
                // must be errors
                // need to handle still
                // console.log("=== Token Error ===");
                // console.log(apiToken.error);
                // console.log(apiToken);
                return Promise.resolve('error');
            }
        },
        getProtocol() {
            // hard-coded for HTTPS since it's mandatory for CMS, Site and API.
            return process.env.VUE_APP_APIPROTOCOL; //'https:'; //window.location.protocol;
        },
        nameNoSpecialFormat(targetInputID) {
            const nameInput = targetInputID;
            if (nameInput) {
                nameInput.addEventListener('input', (event) => {
                    const inputStripped = nameInput.value.replace(/[^a-zA-Z0-9 ]/, '');
                    nameInput.value = inputStripped;                    
                });
            }
            else {
                // console.log('unable to find name input ');
            }
        },
        phoneNumberFormat(targetInputID){
            const phoneInput = targetInputID; //document.querySelector('#phoneInput');
            const phoneFormat = '{0}{1}{2}-{3}{4}{5}-{6}{7}{8}{9}';
            if (phoneInput) {
                phoneInput.addEventListener('input', (event) => {
                    const inputStripped = phoneInput.value.replace(/\D/g, '');
                    const inputIsValid = !isNaN(parseInt(event.data));

                    if (event.inputType.includes('deleteContent')) {
                        /*
                        TODO Create input inequality when values are deleted
                        that are NOT at the end of the input
        
                        '(012) 34' -> '(012) 3' FINE
                        '(012) 34' -> '(01x) 34' INEQUALITY TO FIX
                        */
                        return;
                    }

                    if (event.inputType == 'insertText' && (inputStripped.length > 10 || !inputIsValid)) {
                        phoneInput.value = phoneInput.value.substring(0, phoneInput.value.length - 1);
                        return;
                    }

                    if (inputStripped)
                        phoneInput.value = formatPhoneInput(inputStripped);
                });

                const formatPhoneInput = (inputNumber) => {
                    let inputNumArr = inputNumber.split('');
                    let formatVar = inputNumArr.length - 1;

                    // indexOf() + 3, so we can replace the entire '{x}' variable in phoneFormat
                    let replaceIndex = phoneFormat.indexOf(`{${formatVar}}`) + 3;

                    // Autocompletion to next input value
                    switch (formatVar) {
                        case 2:
                            replaceIndex += 2;
                            break;
                        case 5:
                            replaceIndex += 1;
                            break;
                        default:
                            break;
                    }

                    let formattedInput = phoneFormat.substring(0, replaceIndex);

                    for (let i = 0; i < inputNumArr.length; i++) {
                        formattedInput = formattedInput.replace(`{${i}}`, inputNumArr[i]);
                    }

                    return formattedInput;
                }
            }
            else {
                // console.log('unable to find phone input ');
            }
            
        },
        pastedPhoneFormat(num) {
            let phone = num;

            if (phone !== null) {
                let area = phone.slice(0, 3),
                    county = phone.slice(3, 6),
                    local = phone.slice(6, phone.length),
                    formatted = area + '-' + county + '-' + local;

                return formatted;
            }
        },
        phoneNumberFormatModel(input){
            let phoneInput = input; //document.querySelector('#phoneInput');
            let phoneFormat = '{0}{1}{2}-{3}{4}{5}-{6}{7}{8}{9}';
            if (phoneInput) {
                // phoneInput.addEventListener('input', (event) => {
                    let inputStripped = phoneInput.replace(/\D/g, '');
                    let inputIsValid = !isNaN(parseInt(phoneInput));

                    let formatPhoneInput = (inputNumber) => {
                        let inputNumArr = inputNumber.split('');
                        let formatVar = inputNumArr.length - 1;
    
                        // indexOf() + 3, so we can replace the entire '{x}' variable in phoneFormat
                        let replaceIndex = phoneFormat.indexOf(`{${formatVar}}`) + 3;
    
                        // Autocompletion to next input value
                        switch (formatVar) {
                            case 2:
                                replaceIndex += 2;
                                break;
                            case 5:
                                replaceIndex += 1;
                                break;
                            default:
                                break;
                        }
    
                        let formattedInput = phoneFormat.substring(0, replaceIndex);
    
                        for (let i = 0; i < inputNumArr.length; i++) {
                            formattedInput = formattedInput.replace(`{${i}}`, inputNumArr[i]);
                        }
                        console.log(formattedInput)
                        return formattedInput;
                    }

                    if (inputStripped.length > 10 || !inputIsValid) {
                        phoneInput = phoneInput.substring(0, phoneInput.length - 1);
                        return 'Nothing';
                    }

                    if (inputStripped)
                        phoneInput = formatPhoneInput(inputStripped);
                    }
                    else {
                        console.log('unable to find phone input ');
                    }
        },
        dateValidation(dateVal) {
            const dateRegex = /^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/;
            if (dateRegex.test(dateVal)) {
                return true;
            }
            else {
                return false;
            }
        },
        phoneValidation(phoneVal) {
            // test for phone format
            const phoneRegExA = /[A-z]/g; // any letter
            const phoneRegExN = /[^0-9,\-,(,),\s]/g;    // any non-number besides "( - )" and spaces
            let phoneTestAv = phoneRegExA.test(phoneVal);
            let phoneTestNv = phoneRegExN.test(phoneVal);
            if (phoneTestAv == true || phoneVal.length < 7) {
                return false;
            } else if (phoneTestNv == false) {
                return true;
            } else {
                return false;
            }
        },
        ssnValidation(ssnVal) {
            // Validate SSN
            const ssnRegex = new RegExp("^\\d{9}?$");
            if (ssnRegex.test(ssnVal)) {
                return true;
            } else {
                return false;
            }
        },
        cleanseImagePath(path) {
            let image = path;

            if (image.indexOf('~') === 0) {
                let dirtyPath = image.split('~'),
                    cleanPath = dirtyPath[1];

                return cleanPath;
            } else {
                return image;
            }
        },
        decimalFormat(decVal) {
            // Decimal "number"
            const decRegex = /^\d+(\.\d{1,2})?$/; // only numbers and possible point with up to 2 places
            const pointRegex = /^\d+\.\d{2}$/; // has ".xx" format?
            const placeRegex = /^\d+\.\d{1}$/; // has ".x" format?
            if (!decRegex.test(decVal)) {
                return false;
            } else {
                // test if has decimal & add if not
                if(placeRegex.test(decVal)) {
                    decVal = decVal + '0';
                } else if (!pointRegex.test(decVal)) {
                    decVal = decVal + '.00';
                }
                return decVal;
            }
        },
        accountNumberValidation(n) {
            // bank account can be any length, but only numbers
            return !isNaN(parseFloat(n)) && isFinite(n);
            // const accntRegex = /^\d+$/;
            // if (accntRegex.test(acctVal)) {
            //     return true;
            // } else {
            //     return false;
            // }
        },
        routingNumberValidation(routingVal) {
            const routeRegex = new RegExp("^\\d{9}?$");
            if (routeRegex.test(routingVal)) {
                return true;
            } else {
                return false;
            }
        },
        zipValidation(zipVal) {
            // Validate zip code
            const zipRegex = /^\d{5}?$/;
            if (zipRegex.test(zipVal)) {
                return true;
            } else {
                return false;
            }
        },
        zipPlusFourValidation(zipVal) {
            // Validate zip code
            const zipRegex = /^\d{4}?$/;
            if (zipRegex.test(zipVal)) {
                return true;
            } else {
                return false;
            }
        },
        textValidation(txtVal, noNumbers) {
            // noNumbers is booleon
            // test for text format
            const textRegExA = /[A-z]/g; // any letter?
            const textRegExN = /\d/g;    // any number?
            const textTestAv = textRegExA.test(txtVal);
            const textTestNv = textRegExN.test(txtVal);
            if (textTestAv == false) {
                return false;
            } else if (textTestNv == true && noNumbers) {
                return false;
            } else {
                return true;
            }
        },
        emailValidation(emailVal) {
            const emailRegExS = /\s/;
            const emailRegExa = /@/g;
            const emailTestS = emailRegExS.test(emailVal);
            const emailTesta = emailRegExa.test(emailVal);
            if (emailVal == undefined || emailVal.length < 5) {
                return false;
            } else if (emailTestS == true) {
                return false;
            } else if (emailTesta == false) {
                return false;
            } else {
                return true;
            }
        },
        passwordValidation(passwordVal) {
            // console.log(passwordVal)
            const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;
            const passwordTest = passwordRegex.test(passwordVal);
            return passwordTest;
        },
        getUserPoints() {
            // maybe check user is not public first?
            let userData = JSON.parse(sessionStorage.getItem('apiToken') ) ;
            let apiToken = userData.token;
            let userId = userData.user.userId;
            let url = process.env.VUE_APP_APIURL + '/1.0/Ecommerce/GetUserAvailableCredit?userId=' + userId;

            fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + apiToken,
                },
            })
            .then(response => response.json())
            .then((json) => {
                let d = json;
                let points = 0;
                if (d.data !== null && d.data !== undefined) {
                    // catch for users created in CMS withour Registering
                    // Swagger will create the customer, but not before we get null
                    points = d.data.pointBalance.balance;
                }
                if(userData.user.username !== 'mobilepublic@mtllc.com'){
                    userData.user.pointBalance.balance = points;
                    sessionStorage.setItem('apiToken', JSON.stringify(userData));
                }
            });
        },
        getLoggedInUserId() {
            let apiToken =  JSON.parse( sessionStorage.getItem('apiToken')) ;
            // return token if already set
            if (apiToken !== null && apiToken !== undefined) {
                if (apiToken.token !== null) {
                    let tokenExpires = new Date(apiToken.expires)
                    let rightNow = new Date()
                    // console.log(rightNow + ' ?< ' + tokenExpires);

                    if (rightNow < tokenExpires) {
                        // console.log(apiToken.user.userId);
                        return apiToken.user.userId;
                        //return Promise.resolve(apiToken.user.userId);
                    } else {
                        // need to get a token or fallback response
                        // this.getRefereshToken(apiToken);
                        //return Promise.resolve(0);
                        return 0;
                    }
                }
            } 
            // return Promise.resolve(0);
            return 0;
        },
        getURLQuery(q) {
            let prop = q;

            let url = window.location.search.substring(1),
                urlVar = url.split('&'),
                param,
                i;

            for (i = 0; i < urlVar.length; i++) {
                param = urlVar[i].split('=');

                if (param[0] === prop) {
                    return param[0] === undefined ? true : param[1];
                }
            }

            return false;
        },
        getCategoryList(token) {
            return fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + '/1.0/Ecommerce/GetCategoryList', {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(response => response.json())
            .then(json => {
                if (json.data.length) {
                    return {
                        return: true,
                        data: json.data
                    }
                } else {
                    return {
                        return: false,
                        error: json.error
                    }
                }
            });
        },
        getContentByKey(contentKey, contentID) {
            
            let sessionContent = this.$cookies.get(contentID);
            // console.log(sessionContent);

            if (sessionContent !== undefined && sessionContent !== "" && sessionContent !== null) {
                if (sessionContent.startsWith('"{')) {
                    // content is in JSON string format
                    sessionContent = JSON.parse(sessionContent);
                    sessionContent = JSON.parse(sessionContent);
                }
                return sessionContent;

            } else {
                return this.fetchContentFromAPI(contentKey, contentID);
            }
        },
        fetchContentFromAPI(contentKey, contentID) {
            return this.getAPItoken()
            .then((token) => {
                return fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + '/1.0/Games/StaticContentElements/StaticContentElement/GetContentElementByKey?contentKey=' + contentKey, {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                })
                .then(response => response.json())
                .then(json => {
                    let element = json.data[0].centralContent;
                    
                    if (element !== undefined && element !== "" && element !== null) {

                        sessionStorage.setItem(contentID, JSON.stringify(element));
                        
                        if (element.startsWith('{')) {
                            // content is in JSON string format
                            element = JSON.parse(element);
                        } else {
                            element = JSON.stringify(element);
                            // element = element.toString();
                        }
                        // this isn't working for plain string content from API, only JSON
                        // console.log(element);
                        return Promise.resolve(element);
                    } else {
                        console.log('no content found for the key key: '+contentKey);
                        return Promise.resolve({});
                    }

                })
            });
        },
        getCookie(cookie) {
            return this.$cookies.get(cookie);
        },
        sanitizeMessage: (string) => {
            const html = sanitizeHtml(string);

            return html;
        },
        sortDates(a, b) {
            
            if ( a.date > b.date ){
                return -1;
            }
            if ( a.date < b.date ){
                return 1;
            }
            return 0;
        },
        isUserInRole(role) {
            // must be sent the role "codename" to return true
            let theCookie = JSON.parse( sessionStorage.getItem('apiToken')) ,
                userRoles = theCookie.user.listOfRoles,
                i;
                // console.log(userRoles);

            for (i = 0; i < userRoles.length; i++) {
                // console.log(userRoles[i],role);
                if (userRoles[i] === role) {
                    return true;
                }
            }
            return false;
        },
        // Validates that the input string is a valid date formatted as "mm/dd/yyyy"
        isValidDate(dateString){
            // First check for the pattern
            if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString))
                return false;

            // Parse the date parts to integers
            var parts = dateString.split("/");
            var day = parseInt(parts[1], 10);
            var month = parseInt(parts[0], 10);
            var year = parseInt(parts[2], 10);

            // Check the ranges of month and year
            if (year < 1000 || year > 3000 || month == 0 || month > 12)
                return false;

            var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

            // Adjust for leap years
            if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
                monthLength[1] = 29;

            // Check the range of the day
            return day > 0 && day <= monthLength[month - 1];
        },
        tildeKiller(thisString) {
            // console.log(thisString);
            // return thisString = thisString.replace('src="~','src="');
            return thisString.split('src="~').join('src="');
        },
        mapSelect(d, id) {
            let countyCode = 0,
                select = document.getElementById(id),
                options = select.getElementsByTagName('option');

            for (let i = 0; i < options.length; i++) {
                let option = options[i],
                    text = option.innerHTML,
                    value = option.value;

                if (text === d) {
                    countyCode = value;
                    break;
                }
            }

            select.value = countyCode;

            return countyCode;
        },
        ...mapActions ({
            setToken: 'tokenManagement/setToken'
        }),
        ...mapGetters ({
            getToken: 'tokenManagement/getToken'
        })
    },
    watch: {
        token: function () {
            this.returnToken();
        }
    }
}