<template>
    <div class="wishlist" v-if="isUserLogged">
        <h2>Products on your wish list</h2>
        <section class="productsNav">   
            <div class="productCount">{{ itemCount }} items</div>
            <div class="productSort">
                <div class="productSort-label">
                    <p>
                        Sort products by:
                    </p>
                </div>
                <select name="productSortSelect" id="productSortSelect" class="productSort-select" @change="sort">
                    <option value="0">Points Low to High</option>
                    <option value="1">Points High to Low</option>
                </select>
            </div>
        </section>
        <section v-if="!error" class="catalogList"> <!-- && !error-->
            <span class="productList">
                <span v-for="product in currentItems" :key="product.skuNumber" class="product">
                    <div class = "productWrapper">
                        <span class="productPrice">{{product.price.toLocaleString()}} Points</span>
                    <div class="productItem cf callout light boxShadow">
                        <strong v-if="product.stock < 1" class="noLongerAvailable">NO LONGER AVAILABLE</strong>
                        <a v-if="product.stock > 0" :href="product.nodeAliasPath" :class="{ 'productImg': true, 'noLongerAvailable': product.stock < 1 }">
                            <img :src="processImage(product.image)" :alt="product.description">
                        </a>
                        <!-- <a v-if="product.stock < 1" href="/" class="productImg" data-open="modal-wishlist" style="opacity=0.3;pointer-events:none;"> -->
                        <a v-if="product.stock < 1" href="#" :class="{ 'productImg': true, 'noLongerAvailable': product.stock < 1 }" data-open="modal-wishlist" @click="openPurchase">
                            <img :src="processImage(product.image)" :alt="product.description">
                        </a>
                    </div>
                    <span class="productRemove" data-open="modal-remove" @click="removeProduct(product.productId)">
                        Remove from Wish List
                    </span>
                    </div>
                    <article class="productInfo">
                        <a  v-if="product.stock > 0" :href="product.nodeAliasPath" class="productTitle">
                            {{product.name}}
                        </a>
                        <a href="/" v-if="product.stock < 1" class="productTitle">
                            {{product.name}}
                        </a>
                    </article>
                </span>
            </span>
        </section>
        <section v-else>
            {{ errorMessage }}
        </section>

        <div id="modal-wishlist" class="reveal modal-wishlist reveal-modal" data-reveal>
            <section class="oneStepCheckout-error">
                <div>
                    <h2>This item is no longer available.</h2>
                    <button class="close-button" data-close type="button" @click="closePurchase">
                        <span aria-hidden="true">×</span>
                    </button>
                    <button class="button btn-primary" data-close @click="closePurchase">Okay</button>
                </div>
            </section>
        </div>

        <div id="modal-remove" class="reveal modal-remove reveal-modal" data-reveal>
            <section class="oneStepCheckout-error">
                <div>
                    <h2>SUCCESS!</h2>
                    <button class="close-button" data-close type="button" @click="closePurchase">
                        <span aria-hidden="true">×</span>
                    </button>
                    <p>This product has been removed from your wish list.</p>
                    <button class="button btn-primary" data-close @click="closeRemove">Okay</button>
                </div>
            </section>
        </div>

        <Pagination :totalPages="totalPages" :itemsPerPage="itemsPerPage" @changePage="changePage" :currentPage="currentPage" :center="true" :right="true"></Pagination>
    </div>
</template>

<script>
import AdditionalRewardsVue from '../AdditionalRewards.vue'
import Pagination from '../../Pagination/pagination.vue'
import Foundation from "@/foundation-sites/js/foundation.js";

    export default {
        components: {
            AdditionalRewardsVue,
            Pagination
        },
        data: function() {
            return {
                isUserLogged:false,
                token: '',
                products: [
                
                ],
                games: [
                    {
                        name: 1,
                        id: 2
                    }
                ],
                itemCount: 0,
                items: 0,
                totalPages: 0,
                itemsPerPage: this.items / this.totalPages,
                currentPage: 1,
                currentItems: [
            
                 ],
                error: false,
                errorMessage: 'Your wish list is empty'
            }
        },
        mounted: function() {
            this.getToken()
        },
        methods: {
            openPurchase() {
                let popup = new Foundation.Reveal($('#modal-wishlist'));
                popup.open();
            },
            closePurchase() {
                // this.enter = true;
                // this.confirm = false;
                // this.error = false;
                $('#modal-wishlist').hide();
                $('.reveal-overlay').hide();
            },
            openRemove() {
                let popup = new Foundation.Reveal($('#modal-remove'));
                popup.open();
            },
            closeRemove() {
                // this.enter = true;
                // this.confirm = false;
                // this.error = false;
                $('#modal-remove').hide();
                $('.reveal-overlay').hide();
            },
            processImage(img){
               
                return img.replace("~","");
            }, 
            getToken() {
                this.getAPItoken()
                .then((token) => {
                    this.isUserLogged = this.isUserAuthed();
                    this.token = token;
                    this.user = JSON.parse( sessionStorage.getItem('apiToken'));
                    this.getProducts(token);
                });
            },
            getProducts(token) {
                let apiToken = token,
                    urlProtocol = this.getProtocol(),
                    url = process.env.VUE_APP_APIURL+'/1.0/Ecommerce/WhislistGetUserProducts?userId=' + this.user.user.userId;

                return fetch(urlProtocol +  url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + apiToken
                    }
                })
                .then(response => response.json())
                .then(json => {
                    if (json.error === null) {
                        this.setStock(json.data);
                    } else {
                        this.products = [];
                        this.currentItems = [];
                        this.error = true;
                        this.errorMessage = json.error.message;
                    }
                })
                .catch(error => {
                    this.error = true;
                    console.log(this.errorMessage = error);
                })
            },
            removeProduct(id) {
                this.openRemove();
                let apiToken = this.token,
                    urlProtocol = this.getProtocol(),
                    url = process.env.VUE_APP_APIURL+'/1.0/Ecommerce/WhishlistRemoveProduct?skuId=' + id + '&userId=' + this.user.user.userId;

                return fetch(urlProtocol +  url, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + apiToken
                    }
                })
                .then(response => response.json())
                .then(json => {
                    this.getProducts(this.token)
                })
                .catch(error => {
                    console.log(this.errorMessage = error);
                })
            },
            sort(a) {
                let value = a.target.value,
                    items = this.items,
                    sorted;

                if (value == 0) {
                    sorted = items.sort((x,y) => {
                        return x.price - y.price;
                    })
                }

                if (value == 1) {
                    sorted = items.sort((x,y) => {
                        return y.price - x.price;
                    })
                }

                this.setItems();
            },
            setStock(json) {
                let data = json,
                    products = [];

                if (data === null) {
                    this.products = '';
                } else {
                    for (let i = 0; i < data.length; i++) {
                        products.push(data[i])
                    }

                    this.itemCount = data.length;

                    this.items = products;
                    this.products = products;
                }

                this.setPages();
                this.setItems();
            },
            setPages() {
                // Sets current pages, items per page, max pages etc.
                // Add the folloing to you data object

                // items: (get this from the api),
                // totalPages: 0,
                // itemsPerPage: this.items / this.totalPages,
                // currentPage: 1,
                // currentItems: []

                // Then add:
                // this.setPages();
                // this.setItems();
                // to the end of your API call function chain once this.items has been set
                
                this.itemsPerPage = 12;
                this.totalPages = Math.ceil(this.items.length / this.itemsPerPage);
            },
            changePage(p) {
                this.currentPage = p;
                this.setItems();
            },
            setItems() {
                // Sets current items to show up in pagination
                 

                let start = (this.itemsPerPage * this.currentPage) - this.itemsPerPage,
                    end = (this.itemsPerPage * this.currentPage),
                    currentItems = this.items.slice(start, end);

                this.currentItems = currentItems;
            }
        }
    }
</script>