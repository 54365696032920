<template>
    <div class="">
        <div class="press-releases-wrapper" v-if="showPressRoom">

            <div class="archive text-right">
                <a @click="clkArchive" v-if="showBtn" >Archive</a>
                <!-- <a @click="clkBackToPress" v-if="!showBtn">Back to press room</a> -->
            </div>

            <div class="archive-header" v-if="showArchiveHeader">
                <a :class="{'activeYear': selectedYear === '0'}" @click="clkYear($event, selectedYear = '0' )">{{ new Date().getFullYear() }}</a>
                <a :class="{'activeYear': selectedYear === '1'}" @click="clkYear($event, selectedYear = '1' )">{{ new Date().getFullYear() - 1 }}</a>
                <a :class="{'activeYear': selectedYear === '2'}" @click="clkYear($event, selectedYear = '2' )">{{ new Date().getFullYear() - 2 }}</a>
            </div>

            <div class="press-release-header">
                <a :class="{'active': selected === '1'}" @click="clkMonth('0', '01', selected = '1')">Jan</a>
                <a :class="{'active': selected === '2', 'disabled': (2 > currentMonth && selectedYear === '0')}" @click="clkMonth('1', '02', selected = '2')">Feb</a>
                <a :class="{'active': selected === '3', 'disabled': (3 > currentMonth && selectedYear === '0')}" @click="clkMonth('2', '03', selected = '3')">Mar</a>
                <a :class="{'active': selected === '4', 'disabled': (4 > currentMonth && selectedYear === '0')}" @click="clkMonth('3', '04', selected = '4')">Apr</a>
                <a :class="{'active': selected === '5', 'disabled': (5 > currentMonth && selectedYear === '0')}" @click="clkMonth('4', '05', selected = '5')">May</a>
                <a :class="{'active': selected === '6', 'disabled': (6 > currentMonth && selectedYear === '0')}" @click="clkMonth('5', '06', selected = '6')">Jun</a>
                <a :class="{'active': selected === '7', 'disabled': (7 > currentMonth && selectedYear === '0')}" @click="clkMonth('6', '07', selected = '7')">Jul</a>
                <a :class="{'active': selected === '8', 'disabled': (8 > currentMonth && selectedYear === '0')}" @click="clkMonth('7', '08', selected = '8')">Aug</a>
                <a :class="{'active': selected === '9', 'disabled': (9 > currentMonth && selectedYear === '0')}" @click="clkMonth('8', '09', selected = '9')">Sep</a>
                <a :class="{'active': selected === '10', 'disabled': (10 > currentMonth && selectedYear === '0')}" @click="clkMonth('9', '10', selected = '10')">Oct</a>
                <a :class="{'active': selected === '11', 'disabled': (11 > currentMonth && selectedYear === '0')}" @click="clkMonth('10', '11', selected = '11')">Nov</a>
                <a :class="{'active': selected === '12', 'disabled': (12 > currentMonth && selectedYear === '0')}" @click="clkMonth('11', '12', selected = '12')">Dec</a>
            </div>

            <hollow-dots-spinner
                :animation-duration="1000"
                :dot-size="15"
                :dots-num="3"
                color="#0FD8C9"
                v-if="isLoading"
            />

            <div class="press-release-results">
                <div class="press-release-results_item" v-for="result in results" :key="result.id">
                    <span class="press-release-results_item_date">
                        {{ customFormatter(result.date) }}
                    </span>
                    <span class="press-release-results_item_title">
                        <a @click="goToDetail(result.articleID)">{{ result.title }}</a>
                    </span>
                </div>
            </div>

            <div v-if="errorMessage">
                <p>{{ errorTxt }}</p>
            </div>
            
        </div>

        <div class="press-releases-detail" v-if="!showPressRoom">
            <PressReleasesDetailVue :articleId="articleId"></PressReleasesDetailVue>
            <div class="btn_back">
                <a @click="clkBackToPressDetail" class="button">Back to press room</a>
            </div>
        </div>
    
    </div>
</template>

<script>
    import moment from 'moment';
    import { HollowDotsSpinner } from 'epic-spinners';
    import PressReleasesDetailVue from './PressReleasesDetail.vue';

    export default {
        components: {
            HollowDotsSpinner,
            PressReleasesDetailVue
        },
        data: function () {
            return {
                showPressRoom: true,
                month: new Date().getMonth(),
                apiMonth: moment(new Date()).format("M"),
                year: new Date().getFullYear().toString(),
                results: [],
                selected: moment(new Date()).format("M"),
                selectedYear: '',
                showResults: false,
                showArchiveHeader: false,
                showBtn: true,
                errorMessage: false,
                errorTxt: '',
                isLoading: false,
                articleId: '',
                saveUrl: '',
                currentMonth: ''
            }
        },
        mounted: function () {
            window.onpopstate = function () {
                location.reload()
            };

            this.selectedYear = '0'
            this.currentMonth = Number(this.month) + 1;

            let url = window.location.href;

            if(url.includes('?release-detail')){
                var baseUrl = url
                var urlArticleId = baseUrl.substring(baseUrl.lastIndexOf('=') + 1);
                this.goToDetail(urlArticleId)
                
            }else{
                this.getToken()
            }

        },
        methods: {
            customFormatter(date) {
                return moment(date).format('M/D/YYYY');
            },
            clkYear(event){
                this.year = event.currentTarget.innerText;
                this.month = 0
                this.apiMonth = '01'
                
                const urlParams = new URLSearchParams(window.location.search);
                urlParams.set('year', this.year);
                urlParams.set('month', this.apiMonth);
                urlParams.set('jMonth', this.month);
                let newRelativePathQuery = window.location.pathname.replace(/\/+$/, '') + '?' + urlParams.toString();
                history.pushState(null, '', newRelativePathQuery);
                
                if(this.year == new Date().getFullYear() ){
                    // console.log('')
                    this.clkBackToPress()
                    return;
                }

                this.getToken()
            },
            clkMonth(jsMonth ,apiMonth){
                this.month = jsMonth;
                this.apiMonth = apiMonth;

                const urlParams = new URLSearchParams(window.location.search);
                urlParams.set('year', this.year);
                urlParams.set('month', this.apiMonth);
                urlParams.set('jMonth', this.month);
                let newRelativePathQuery = window.location.pathname.replace(/\/+$/, '') + '?' + urlParams.toString();
                history.pushState(null, '', newRelativePathQuery);


                this.getToken()
                
            },
            clkArchive(){
                this.showArchiveHeader = true;
                this.showBtn = false;
                let activeYear = new Date().getFullYear() - 1;
                this.year = activeYear.toString();
                this.month = 0;
                this.apiMonth = '01';
                this.selectedYear = '1';
                this.selected = '1';

                const urlParams = new URLSearchParams(window.location.search);
                urlParams.set('year', this.year);
                urlParams.set('month', this.apiMonth);
                urlParams.set('jMonth', this.month);
                let newRelativePathQuery = window.location.pathname.replace(/\/+$/, '') + '?' + urlParams.toString();
                history.pushState(null, '', newRelativePathQuery);

                this.getToken()
            },
            clkBackToPress(){
                this.showArchiveHeader = false;
                this.showBtn = true;
                this.year = new Date().getFullYear().toString();
                this.month = new Date().getMonth();
                this.apiMonth = moment(new Date()).format("M");

                const urlParams = new URLSearchParams(window.location.search);
                urlParams.set('year', this.year);
                urlParams.set('month', this.apiMonth);
                urlParams.set('jMonth', this.month);
                let newRelativePathQuery = window.location.pathname + '?' + urlParams.toString();
                history.pushState(null, '', newRelativePathQuery);

                this.getToken()
            },
            clkBackToPressDetail(){
                this.showPressRoom = true;
                
                let cleanUrl = window.location.href.split('?release-detail')[0]
                let url = new URL(location.href);
                url.searchParams.delete('id');
                history.pushState(null, '', cleanUrl);
                if(this.saveUrl.includes('?year') ){
                    history.pushState(null, '', this.saveUrl);
                }
                
            },
            getToken() {
                // then hit API with token
                this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });
            },
            fetchData(token) {
                let apiToken = token,
                    urlProtocol = this.getProtocol(),
                    url = window.location.href,
                    month = this.month,
                    apiMonth = this.apiMonth,
                    apiYear = this.year;

                this.results = [];
                this.showResults = false;
                this.errorMessage = false;
                this.isLoading = true;

                if(url.includes('?year')){
                    const params = new Proxy(new URLSearchParams(window.location.search), {
                        get: (searchParams, prop) => searchParams.get(prop),
                    });
                    let yearValue = params.year,
                        monthValue = params.month,
                        jMonthValue = params.jMonth;

                    month = jMonthValue; 
                    apiMonth = monthValue;
                    apiYear = yearValue;
                    let initSelect = parseInt(month) + 1;
                    this.selected = initSelect.toString()

                    if( yearValue == new Date().getFullYear() -1 || yearValue == new Date().getFullYear() -2 ){
                        if(yearValue == new Date().getFullYear() -1){
                            this.selectedYear = '1'
                        }else if(yearValue == new Date().getFullYear() -2){
                            this.selectedYear = '2'
                        }
                        this.showArchiveHeader = true;
                        this.showBtn = false;
                    } else {
                        // default to show current year selected
                        this.selectedYear = '0'
                        this.showArchiveHeader = true;
                        this.showBtn = false;
                    }
                } else {
                    // default to show current year selected
                    this.selectedYear = '0'
                    this.showArchiveHeader = true;
                    this.showBtn = false;
                    // should deactivate the future months
                    this.isCurrentMonth = true;
                }

                
                let d = new Date(apiYear, parseInt(month) + 1, 0);
                let lastDay = d.getDate().toString();
                

                return fetch(urlProtocol+process.env.VUE_APP_APIURL+"/1.0/Games/Article/GetByDateRangeAndCategory?dateFrom="+apiYear+"-"+ apiMonth +"-01&dateTo="+apiYear+"-"+ apiMonth +"-"+ lastDay +"&category=PressRelease", {
                    headers: {
                        'Authorization': 'Bearer '+apiToken,
                    },
                })
                .then(response => response.json())
                .then(json => {
                    let result = json.data;
                    this.isLoading = false;

                    if(json.error == null){
                        this.errorMessage = false;
                        this.results = result;
                        this.showResults = true;
                    }else{
                        this.errorMessage = true;
                        this.showResults = false;
                        let noDataMsg = "There are no Articles that match the specified criteria.";
                        if (noDataMsg == json.error.message ) {
                            this.errorTxt = "There are no releases for the selected month.";
                        } else {
                            this.errorTxt = json.error.message
                        }
                    }

                })
                .catch(error => {
                    // console.error("There was an error!", error);
                    this.showResults = false;
                    return this.errorMessage = error;
                    
                });
            },
            goToDetail(articleId){
                
                this.articleId = articleId;
                this.showPressRoom = false;

                let url = window.location.href;
                this.saveUrl = window.location.search;

                if(url.includes('?')){
                    let cleanUrl = window.location.href.split('?')[0]
                    history.pushState(null, '', cleanUrl);
                }

                const urlParams = new URLSearchParams(window.location.search);
                urlParams.set('id', articleId);
                let newRelativePathQuery = window.location.pathname.replace(/\/+$/, '') + '?release-detail' + '?' + urlParams.toString();
                history.pushState(null, '', newRelativePathQuery);
                //process.env.VUE_APP_APIURL+"/1.0/Games/Article/GetById/3"
            }
        },
        computed: {
        }
    };
</script>

<style lang="scss" scoped>


</style>