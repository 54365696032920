<template>
    <div class="second-chance-detail">

        <h1>{{ title }}</h1>
        <img v-if="mainBannerURL" :src="`/getattachment/${mainBannerURL}/attachment.aspx`" class="scPromoBanner" id="scPromoBanner_desktop" :alt="`${title}`" />
        <img v-if="mainBannerMobileURL" :src="`/getattachment/${mainBannerMobileURL}/attachment.aspx`" class="scPromoBanner" id="scPromoBanner_mobile" :alt="`${title}`" />

        <tabs class="main-tab">
            <tab title="Promotion Entry" class="sc_entryTab is-active">
                <h2 v-if="lPheadline">{{ lPheadline }}</h2>
                <p v-if="lPintroText" v-html="lPintroText" class="sc_introTxt"></p>
                <p v-if="barcodeText" class="sc_barcodeInfo">{{ barcodeText }}</p>
                <img v-if="barcodeImageURL" :src="`/getattachment/${barcodeImageURL}/attachment.aspx`" class="sc_barcodeImg" alt="barcode example" />
                <!-- Ticket Entry Component goes here -->
            </tab>
            <tab title="FAQ" class="sc_faqTab">
                <span v-html="faQcontent"></span>
            </tab>
            <tab title="Prizes & Drawings" class="sc_prizesTab">
                <span v-html="prizesDrawingsContent"></span>
            </tab>
            <tab title="Terms & Conditions" class="sc_rulesTab">
                <span v-html="rulesContent"></span>
            </tab>
            <tab title="Winners" class="sc_winnerTab">
                <!-- SCD Winner Component goes here -->
                <Winners></Winners>
            </tab>
        </tabs>

        <!-- <TicketWrapper class="ticket-wrapper" scdCode="" header="Your entry code is located above the bar code on your 2nd Chance Entry ticket."></TicketWrapper> -->

        <span v-html="lPextraText"></span>
    </div>
</template>

<script>
    import $ from 'jquery';
    import Tab from '../../../ViewComponents/Tabs/Tab.vue';
    import Tabs from '../../../ViewComponents/Tabs/Tabs.vue';
    import Winners from './SecondChanceWinners.vue';
    import TicketWrapper from '../../TicketEntry/TicketEntryWrapper.vue';

    export default {
        components: {
            Tab,
            Tabs,
            Winners,
            TicketWrapper
        },
        data: function () {
            return {
               promotion: [],
               title: '',
               mainBannerURL: '',
               mainBannerMobileURL: '',
               lPheadline: '',
               lPintroText: '',
               lPextraText: '',
               barcodeText: '',
               barcodeImageURL: '',
               faQcontent: '',
               prizesDrawingsContent: '',
               rulesContent: '',
               tEntry: false
            }
        },
        mounted: function () {
            this.getToken()
        },
        methods: {
            getToken() {
                // then hit API with token
                this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });

            },
            fetchData(token) {
                let apiToken = token;
                let params = new URLSearchParams(document.location.search);
                let promotionId;
                promotionId = params.get("id");
                console.log(promotionId)

                if (promotionId == null) {
                    promotionId = document.getElementById('PromotionID').value
                }

                
                return fetch(process.env.VUE_APP_APIPROTOCOL+process.env.VUE_APP_APIURL+"/1.0/SecondChance/Promotion/Get-Promotion-Details/"+promotionId, {
                    headers: {
                        'Authorization': 'Bearer '+apiToken,
                    },
                })
                .then(response => response.json())
                .then(json => {
                    var results = json.data;
                    this.promotion = results;
                    console.log( results)
                    this.title = results.promotionName;
                    this.mainBannerURL = results.mainBanner;
                    this.mainBannerMobileURL = results.mainBannerMobile;
                    this.lPheadline = results.lPheadline;
                    this.lPintroText = results.lPintroText;
                    this.lPextraText = results.lPextraText;
                    this.barcodeText = results.barcodeText;
                    this.barcodeImageURL = results.barcodeImage;
                    this.faQcontent = results.faQcontent;
                    this.prizesDrawingsContent = results.prizesDrawingsContent;
                    this.rulesContent = results.rulesContent;
                    
                    setTimeout(() => {
                        $('.tabs-title.is-active').click();
                    }, 1500);
                })
                .catch(error => {
                    // console.error("There was an error!", error);
                    return this.errorMessage = error;
                });
            },
        },
        computed: {
        }
    };
</script>

<style lang="scss" scoped>
.ticket-wrapper{
    width: 100%;
    margin-top: 3rem;
}
</style>