<template>
    <!-- <div :class="['testToken ', {'go':go, 'warn':warn, 'alert':alert}]"> -->
    <div>
        <div id="timeoutModal" class="reveal reveal-modal data-modalOptions" data-reveal>
            <h1>Timeout</h1>
            <p>Still there? Click the button below to continue. If you do not click the button in <strong>{{ timeRemaining }}</strong> minutes you will be logged out.</p>
            <button class="button btn-primary" @click="refreshToken">Continue</button>
        </div>
        <div id="logoutModal" class="reveal reveal-modal data-modalOptions" data-reveal>
            <h1>Logged Out</h1>
            <p>You have been logged out due to inactivity. If you would like to continue please log in.</p>
            <button class="button btn-primary" @click="closeLogoutModal">Ok</button>
        </div>
    </div>
    <!-- </div> -->
</template>

<script>
import $ from 'jquery';
import moment from 'moment';
import Modal from './TimeoutModal.vue';

export default {
    components: {
        Modal
    },
    data: function () {
        return {
            firstLoad: true,
            token: '',
            apiRefreshToken: '',
            username: '',
            timeRemaining: '',
            refreshTimeRemaining: '',
            go: true,
            warn: false,
            alert: false,
            refresh: false
        }
    },
    mounted: function () {
        this.getRefreshToken();
    },
    methods: {
        setRefresh() {
            // let localRefresh = localStorage.getItem('apiRefresh');
            
            // if (localRefresh !== null && localRefresh !== undefined && localRefresh !== "") {
            //     let localParse = JSON.parse(localRefresh);
            
            //     this.refresh = localParse.rememberMe;

            //     if (this.refresh === true && this.username != "mobilepublic@mtllc.com") {
            //         let data = {
            //             "rememberMe": true,
            //             "email": this.username,
            //             "token": this.apiRefreshToken,
            //             "used": false
            //         }

            //         localStorage.setItem('apiRefresh', JSON.stringify(data));
            //     }
            // }
        },
        getRefreshToken() {
            let token = JSON.parse( sessionStorage.getItem('apiToken'));

            this.token = token;
            this.apiRefreshToken = token.refreshToken.token;
            this.username = token.user.username;

            this.setRefresh();

            if (this.username != 'mobilepublic@mtllc.com') {
                this.getTokenTime();
            }
        },
        setToken(data) {
            let tokenObj = data,
                tokenInfo = { ...JSON.parse(sessionStorage.getItem('TokenInfo') ) };

            if (tokenInfo) {
                tokenInfo.Token = tokenObj.token.token;
                tokenInfo.Message = tokenObj.message;
                tokenInfo.Expires = tokenObj.expires;
                tokenInfo.IsExpires = tokenObj.isExpired;
                tokenInfo.MinutesToExpiration = tokenObj.minutesToExpiration;
                tokenInfo.IsAuthenticated = tokenObj.isAuthenticated;
                tokenInfo.RefreshToken.Token = tokenObj.refreshToken.token;
                tokenInfo.RefreshToken.CurrentDatetime = tokenObj.refreshToken.currentDateTime;
                tokenInfo.RefreshToken.Expires = tokenObj.refreshToken.expires;
                tokenInfo.RefreshToken.IsExpired = tokenObj.refreshToken.isExpired;
                tokenInfo.RefreshToken.MinutesToExpiration = tokenObj.refreshToken.minutesToExpiration;
                tokenInfo.RefreshToken.Created = tokenObj.refreshToken.created;
                tokenInfo.RefreshToken.Revoked = tokenObj.refreshToken.revoked;
                tokenInfo.RefreshToken.IsActive = tokenObj.refreshToken.isActive;
                tokenInfo.RefreshToken.LastUpdated = tokenObj.refreshToken.lastupdated;

                sessionStorage.setItem('TokenInfo', JSON.stringify(tokenInfo), "/", tokenInfo.RefreshToken.Expires, false);
                sessionStorage.setItem('apiToken', JSON.stringify(tokenObj), tokenObj.expires, '/', null, true, 'Strict');
                this.apiRefreshToken = tokenObj.refreshToken.token;
            }  

            this.setRefresh();

            this.token = tokenObj;

            // console.log('=== TOKEN REFRESHED ===');
            // console.log(this.apiRefreshToken);
            // console.log(tokenObj.refreshToken.token);
            
            this.getTokenTime();
            this.close();
        },
        getTokenTime () {
            let token = this.token;

            if (token) {
                this.tokenCaution();

                setInterval(() => {
                    this.tokenCaution();
                }, 60000);
            }
        },
        calculateTimeRemaining () {
            let token = JSON.parse( sessionStorage.getItem('apiToken')) ,
                expire = moment(token.expires),
                refreshTime = moment(token.refreshToken.expires),
                currentTime = moment(new Date()),
                timeLeft = expire.diff(currentTime, 'minutes');
                
            // console.log('Token: ' + JSON.stringify(token));
            console.log('Time Remaining: ' + timeLeft);
            
            this.timeRemaining = timeLeft;
            this.refreshTimeRemaining = refreshTime.diff(currentTime, 'minutes');

            return timeLeft;
        },
        tokenCaution () {
            let remaining = this.calculateTimeRemaining(),
                refresh = this.refresh;

            if (remaining < 1) {
                console.log('= Pop Time Remaining Modal =')
                this.refresh = true;
            }

            if (refresh === true) {
                console.log('= Pop Time Remaining Modal =')
                // Check refreshToken time remaining
                if (this.refreshTimeRemaining < 1) {
                    this.forceLogout();
                } else {
                    console.log('- Refresh Now -');
                    this.refreshToken();
                }
            }
                // this.refreshToken();
            // } else if (remaining <= 15 && this.firstLoad === true) {
            //     console.log('< 15 Remaining to Refresh')
            //     this.refreshToken();
            // } else if (remaining <= 15 && remaining > 5 && this.firstLoad === false) {
            //     console.log('< 15 Remaining NO refresh')
            //     this.go = false;
            //     this.warn = true;
            //     this.alert = false;
            // } else if (remaining <= 5 && this.alert === false) {
            //     console.log('< 5 REMAINING')
            //     this.tokenAlert();
            // } else if (remaining <= 0) {
            //     this.forceLogout();
            // } else {
            //     this.go = true;
            //     this.warn = false;
            //     this.alert = false;
            // }
            // this.firstLoad = false;
        },
        /**!SECTION
         * If User pushed Continue on modal, refreshToken(); reset this.refresh = false;
         */
        tokenAlert () {
            this.go = false;
            this.warn = false;
            this.alert = true;
            let token = JSON.parse( sessionStorage.getItem('apiToken')),
                expire = expire = moment(token.expires),
                currentTime = moment(new Date()),
                timeLeft = expire.diff(currentTime, 'minutes');

            if (timeLeft <= 5) {
                this.open();
            } else {
                this.timeRemaining = timeLeft;
                this.calculateTimeRemaining
            }
        },
        refreshToken() {
            let username = this.username,
                password = "string",
                refreshToken = this.apiRefreshToken,
                protocol = this.getProtocol();

             let loginCreds = {
                "userName": username,
                "password": "string",
                "refreshToken": refreshToken,
                "newTermsAndConditionsAccepted": true
            }

            return fetch(protocol+process.env.VUE_APP_AUTHURL+'/1.0/Authentication/RefreshToken', {
                method: 'POST',
                body: JSON.stringify(loginCreds),
                headers: {
                    'Content-Type': 'application/json-patch+json'
                }
            })
            .then(response => response.json())
            .then((response) => {
                let res = response;
                
                this.submitRefresh(res);
            })
            // .catch(error => {
            //     console.log('token refresh error: '+error);
            //     // reset and try again
            //     // this.$cookies.remove('apiToken');
            //     // return this.getNewAPItoken();
            //     // return error;
            // });
        },
        submitRefresh(response) {
            let tokenObj = {};
            tokenObj = response.data;

            if (tokenObj.token === null || tokenObj.token === undefined || tokenObj.token === "") {
                if (tokenObj.refreshToken.isExpired === true) {
                    this.forceLogout();
                } else {
                    this.getRefreshToken();
                }
            } else {
                var tokenExpires = new Date(tokenObj.expires);
                tokenExpires = tokenExpires.toUTCString();

                this.setToken(tokenObj);
            }
        },
        open() {
            let modal = new Foundation.Reveal($('#timeoutModal'));
            modal.open();
        },
        close() {
            $('#timeoutModal').hide();
            $('.reveal-overlay').hide();
        },
        openLogoutModal() {
            let modal = new Foundation.Reveal($('#logoutModal'));
            modal.open();
        },
        closeLogoutModal() {
            $('#logoutModal').hide();
            $('.reveal-overlay').hide();
        },
        forceLogout() {
            let button = document.getElementById('forceLogout');

            button.click();
        }
    }
}
</script>

<style lang="scss" scoped>
    .testToken {
        height: 5px;

        &.go {
            background-color: #42f5a4;
        }
        &.warn {
            background-color: #f5ce42;
        }
        &.alert {
            background-color: #f54269;
        }
    }
</style>