// NOTE Survey State Module

export default {
    namespaced: true,
    state: () => ({
        surveys: [],
        user: 'saying Hello World'
    }),
    mutations: {

    },
    actions: {

    },
    getters: {
        getUser(state) {
            return state.user
        }
    }
}