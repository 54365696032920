import { render, staticRenderFns } from "./Achievements.vue?vue&type=template&id=8c6bc546&scoped=true"
import script from "./Achievements.vue?vue&type=script&lang=js"
export * from "./Achievements.vue?vue&type=script&lang=js"
import style0 from "./Achievements.vue?vue&type=style&index=0&id=8c6bc546&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c6bc546",
  null
  
)

export default component.exports