<template>
    <div class="moduleContent MyLottoNotifications">
        <span class="alert-success InfoLabel" style="display:none;">Your changes were saved successfully.</span>
        <span class="alert-error ErrorLabel" style="display:none;">ERROR MESSAGE</span>

        <div>
            <label for="subscribeToOffers" class="notifOffers inline-check">
                <input type="checkbox" name="subscribeToOffers" id="subscribeToOffers" v-model="subscribeToOffers" @change="handleCheckBox">
                <span>Yes, I want to receive special offers and promotions from My Lottery 360 via email.</span>
            </label>
            <p class="notifTItle">Select the notifications that you would like to receive for each game.</p>
            <span class="notification-savemessage" :class="{ 'fadeOut' : fade }" v-if="saved"> Changes are saved.</span>
        </div>

        <div class="notifList cf" v-for="(notificationGroup, index) in notifications" :key="index" :class="{'disable' : loading}">

            <div class="notifHeader" @click="toggleOptions">
                <h3>{{ notificationGroup.game_name }}</h3>
                <i class="icon-caret-right cms-icon-150"></i>
            </div>
            <div class="nofitOptions" v-if="notificationGroup.games.length"
                :class="{ toggled: notificationGroup.showOptions }">
                <div class="nofitOptionsLabel"><span>Email</span><span>Push</span></div>
                <div class="nofitOptionsItem" v-for="(notificationGroupItem, index) in notificationGroup.games"
                    :key="index">
                    <h4>{{ notificationGroupItem.name }}</h4>
                    <div class="nofitOptionsItemControls">
                        <div v-for="(value, key) in notificationGroupItem.typeAlert" :key="key">
                            <input type="checkbox" :id="`${key}`" v-model="notificationGroupItem.typeAlert[key]"
                                @change="handleCheckBox">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: ['isUserLogged', 'dataUser', 'saved', 'fade', 'loading'],
    components: {
    },
    data: function () {
        return {
            subscribeToOffers: false,
            notifications: [
                {
                    "game_name": "powerball",
                    "games": [
                        // {
                        //     "name": "Jackpot Rolls",
                        //     "typeAlert": { "powerJRollEmail": false, "powerJRollPN": false }
                        // },
                        {
                            "name": "Winning Numbers",
                            "typeAlert": { "powerWNEmail": false, "powerWNPN": false }
                        },
                        {
                            "name": "$100M Jackpot Alert",
                            "typeAlert": { "power100JAEmail": false, "power100JAPN": false }
                        },
                        {
                            "name": "$200M Jackpot Alert",
                            "typeAlert": { "power200JAEmail": false, "power200JAPN": false }
                        },
                        {
                            "name": "$300M Jackpot Alert",
                            "typeAlert": { "power300JAEmail": false, "power300JAPN": false }
                        },
                        {
                            "name": "$400M Jackpot Alert",
                            "typeAlert": { "power400JAEmail": false, "power400JAPN": false }
                        }
                    ]
                },
                {
                    "game_name": "Mega millions",
                    "games": [
                        // {
                        //     "name": "Jackpot Rolls",
                        //     "typeAlert": { "megaJRollEmail": false, "megaJRollPN": false }
                        // },
                        {
                            "name": "Winning Numbers",
                            "typeAlert": { "megaWNEmail": false, "megaWNPN": false }
                        },
                        {
                            "name": "$100M Jackpot Alert",
                            "typeAlert": { "mega100JAEmail": false, "mega100JAPN": false }
                        },
                        {
                            "name": "$200M Jackpot Alert",
                            "typeAlert": { "mega200JAEmail": false, "mega200JAPN": false }
                        },
                        {
                            "name": "$300M Jackpot Alert",
                            "typeAlert": { "mega300JAEmail": false, "mega300JAPN": false }
                        },
                        {
                            "name": "$400M Jackpot Alert",
                            "typeAlert": { "mega400JAEmail": false, "mega400JAPN": false }
                        }
                    ]
                },
                {
                    "game_name": "Lotto",
                    "games": [
                        // {
                        //     "name": "Jackpot Rolls",
                        //     "typeAlert": { "lottoJRollEmail": false, "lottoJRollPN": false }
                        // },
                        {
                            "name": "Winning Numbers",
                            "typeAlert": { "lottoWNEmail": false, "lottoWNPN": false }
                        },
                        {
                            "name": "$3M Jackpot Alert",
                            "typeAlert": { "lotto3JAEmail": false, "lotto3JAPN": false }
                        },
                        {
                            "name": "$4M Jackpot Alert Email",
                            "typeAlert": { "lotto4JAEmail": false, "lotto4JAPN": false }
                        },
                        {
                            "name": "$5M Jackpot Alert",
                            "typeAlert": { "lotto5JAEmail": false, "lotto5JAPN": false }
                        },
                        {
                            "name": "$7M Jackpot Alert",
                            "typeAlert": { "lotto7JAEmail": false, "lotto7JAPN": false }
                        }
                    ]
                },
                {
                    "game_name": "Hit 5",
                    "games": [
                        // {
                        //     "name": "Jackpot Rolls",
                        //     "typeAlert": { "hit5JRollEmail": false, "hit5JRollPN": false }
                        // },
                        {
                            "name": "Winning Numbers",
                            "typeAlert": { "hit5WNEmail": false, "hit5WNPN": false }
                        },
                        {
                            "name": "$500K Jackpot Alert",
                            "typeAlert": { "hit5500JAEmail": false, "hit5500JAPN": false }
                        }
                    ]
                },
                {
                    "game_name": "Match 4",
                    "games": [
                        {
                            "name": "Winning Numbers",
                            "typeAlert": { "match4WNEmail": false, "match4WNPN": false }
                        },
                    ]
                },
                {
                    "game_name": "Pick 3",
                    "games": [
                        {
                            "name": "Winning Numbers",
                            "typeAlert": { "pick3WNEmail": false, "pick3WNPN": false }
                        },
                    ]
                },
                {
                    "game_name": "Cash pop",
                    "games": [
                        {
                            "name": "Winning Numbers",
                            "typeAlert": { "cashPopWNEmail": false, "cashPopWNPN": false }
                        },
                    ]
                },
                {
                    "game_name": "Daily Keno",
                    "games": [
                        {
                            "name": "Winning Numbers",
                            "typeAlert": { "dailyKenoWNEmail": false, "dailyKenoWNPN": false }
                        },
                    ]
                },
                {
                    "game_name": "Scratch",
                    "games": [
                        {
                            "name": "All New Games",
                            "typeAlert": { "allNewGamesEmail": false, "allNewGamesPN": false }
                        },
                        {
                            "name": "New $1 Game",
                            "typeAlert": { "newGames1Email": false, "newGames1PN": false }
                        },
                        {
                            "name": "New $2 Game",
                            "typeAlert": { "newGames2Email": false, "newGames2PN": false }
                        },
                        {
                            "name": "New $3 Game",
                            "typeAlert": { "newGames3Email": false, "newGames3PN": false }
                        },
                        {
                            "name": "New $5 Game",
                            "typeAlert": { "newGames5Email": false, "newGames5PN": false }
                        },
                        {
                            "name": "New $10 Game",
                            "typeAlert": { "newGames10Email": false, "newGames10PN": false }
                        },
                        {
                            "name": "New $20 Game",
                            "typeAlert": { "newGames20Email": false, "newGames20PN": false }
                        },
                        {
                            "name": "New $30 Game",
                            "typeAlert": { "newGames30Email": false, "newGames30PN": false }
                        }
                    ]
                }
            ]
        }
    },
    mounted: function () {
        // this.$emit('receive-offers', this.subscribeToOffers);
        // this.$emit('notification-list', this.notifications);
    },
    methods: {
        toggleOptions(event) {
            const header = event.currentTarget;
            header.closest('.notifList').classList.toggle('toggled');
            header.closest('.notifList').querySelector('.nofitOptions').classList.toggle('toggled');
        },
        handleCheckBox() {
            this.$emit('receive-offers', this.subscribeToOffers);
            this.$emit('notification-list', this.notifications);
        }
    },
    watch: {
        dataUser: {
            handler(newData, oldDate) {
                const userSelectedData = [
                    {
                        "game_name": "powerball",
                        "games": [
                            // {
                            //     "name": "Jackpot Rolls",
                            //     "typeAlert": { "powerJRollEmail": newData.notificationSettings.powerJRollEmail, "powerJRollPN": newData.notificationSettings.powerJRollPN }
                            // },
                            {
                                "name": "Winning Numbers",
                                "typeAlert": { "powerWNEmail": newData.notificationSettings.powerWNEmail, "powerWNPN": newData.notificationSettings.powerWNPN }
                            },
                            {
                                "name": "$100M Jackpot Alert",
                                "typeAlert": { "power100JAEmail": newData.notificationSettings.power100JAEmail, "power100JAPN": newData.notificationSettings.power100JAPN }
                            },
                            {
                                "name": "$200M Jackpot Alert",
                                "typeAlert": { "power200JAEmail": newData.notificationSettings.power200JAEmail, "power200JAPN": newData.notificationSettings.power200JAPN }
                            },
                            {
                                "name": "$300M Jackpot Alert",
                                "typeAlert": { "power300JAEmail": newData.notificationSettings.power300JAEmail, "power300JAPN": newData.notificationSettings.power300JAPN }
                            },
                            {
                                "name": "$400M Jackpot Alert",
                                "typeAlert": { "power400JAEmail": newData.notificationSettings.power400JAEmail, "power400JAPN": newData.notificationSettings.power400JAPN }
                            }
                        ]
                    },
                    {
                        "game_name": "Mega millions",
                        "games": [
                            // {
                            //     "name": "Jackpot Rolls",
                            //     "typeAlert": { "megaJRollEmail": newData.notificationSettings.megaJRollEmail, "megaJRollPN": newData.notificationSettings.megaJRollPN }
                            // },
                            {
                                "name": "Winning Numbers",
                                "typeAlert": { "megaWNEmail": newData.notificationSettings.megaWNEmail, "megaWNPN": newData.notificationSettings.megaWNPN }
                            },
                            {
                                "name": "$100M Jackpot Alert",
                                "typeAlert": { "mega100JAEmail": newData.notificationSettings.mega100JAEmail, "mega100JAPN": newData.notificationSettings.mega100JAPN }
                            },
                            {
                                "name": "$200M Jackpot Alert",
                                "typeAlert": { "mega200JAEmail": newData.notificationSettings.mega200JAEmail, "mega200JAPN": newData.notificationSettings.mega200JAPN }
                            },
                            {
                                "name": "$300M Jackpot Alert",
                                "typeAlert": { "mega300JAEmail": newData.notificationSettings.mega300JAEmail, "mega300JAPN": newData.notificationSettings.mega300JAPN }
                            },
                            {
                                "name": "$400M Jackpot Alert",
                                "typeAlert": { "mega400JAEmail": newData.notificationSettings.mega400JAEmail, "mega400JAPN": newData.notificationSettings.mega400JAPN }
                            }
                        ]
                    },
                    {
                        "game_name": "Lotto",
                        "games": [
                            // {
                            //     "name": "Jackpot Rolls",
                            //     "typeAlert": { "lottoJRollEmail": newData.notificationSettings.lottoJRollEmail, "lottoJRollPN": newData.notificationSettings.lottoJRollPN }
                            // },
                            {
                                "name": "Winning Numbers",
                                "typeAlert": { "lottoWNEmail": newData.notificationSettings.lottoWNEmail, "lottoWNPN": newData.notificationSettings.lottoWNPN }
                            },
                            {
                                "name": "$3M Jackpot Alert",
                                "typeAlert": { "lotto3JAEmail": newData.notificationSettings.lotto3JAEmail, "lotto3JAPN": newData.notificationSettings.lotto3JAPN }
                            },
                            {
                                "name": "$4M Jackpot Alert",
                                "typeAlert": { "lotto4JAEmail": newData.notificationSettings.lotto4JAEmail, "lotto4JAPN": newData.notificationSettings.lotto4JAPN }
                            },
                            {
                                "name": "$5M Jackpot Alert",
                                "typeAlert": { "lotto5JAEmail": newData.notificationSettings.lotto5JAEmail, "lotto5JAPN": newData.notificationSettings.lotto5JAPN }
                            },
                            {
                                "name": "$7M Jackpot Alert",
                                "typeAlert": { "lotto7JAEmail": newData.notificationSettings.lotto7JAEmail, "lotto7JAPN": newData.notificationSettings.lotto7JAPN }
                            }
                        ]
                    },
                    {
                        "game_name": "Hit 5",
                        "games": [
                            // {
                            //     "name": "Jackpot Rolls",
                            //     "typeAlert": { "hit5JRollEmail": newData.notificationSettings.hit5JRollEmail, "hit5JRollPN": newData.notificationSettings.hit5JRollPN }
                            // },
                            {
                                "name": "Winning Numbers",
                                "typeAlert": { "hit5WNEmail": newData.notificationSettings.hit5WNEmail, "hit5WNPN": newData.notificationSettings.hit5WNPN }
                            },
                            {
                                "name": "$500K Jackpot Alert",
                                "typeAlert": { "hit5500JAEmail": newData.notificationSettings.hit5500JAEmail, "hit5500JAPN": newData.notificationSettings.hit5500JAPN }
                            }
                        ]
                    },
                    {
                        "game_name": "Match 4",
                        "games": [
                            {
                                "name": "Winning Numbers",
                                "typeAlert": { "match4WNEmail": newData.notificationSettings.match4WNEmail, "match4WNPN": newData.notificationSettings.match4WNPN }
                            },
                        ]
                    },
                    {
                        "game_name": "Pick 3",
                        "games": [
                            {
                                "name": "Winning Numbers",
                                "typeAlert": { "pick3WNEmail": newData.notificationSettings.pick3WNEmail, "pick3WNPN": newData.notificationSettings.pick3WNPN }
                            },
                        ]
                    },
                    {
                        "game_name": "Cash pop",
                        "games": [
                            {
                                "name": "Winning Numbers",
                                "typeAlert": { "cashPopWNEmail": newData.notificationSettings.cashPopWNEmail, "cashPopWNPN": newData.notificationSettings.cashPopWNPN }
                            },
                        ]
                    },
                    {
                        "game_name": "Daily Keno",
                        "games": [
                            {
                                "name": "Winning Numbers",
                                "typeAlert": { "dailyKenoWNEmail": newData.notificationSettings.dailyKenoWNEmail, "dailyKenoWNPN": newData.notificationSettings.dailyKenoWNPN }
                            },
                        ]
                    },
                    {
                        "game_name": "Scratch",
                        "games": [
                            {
                                "name": "All New Games",
                                "typeAlert": { "allNewGamesEmail": newData.notificationSettings.allNewGamesEmail, "allNewGamesPN": newData.notificationSettings.allNewGamesPN }
                            },
                            {
                                "name": "New $1 Game",
                                "typeAlert": { "newGames1Email": newData.notificationSettings.newGames1Email, "newGames1PN": newData.notificationSettings.newGames1PN }
                            },
                            {
                                "name": "New $2 Game",
                                "typeAlert": { "newGames2Email": newData.notificationSettings.newGames2Email, "newGames2PN": newData.notificationSettings.newGames2PN }
                            },
                            {
                                "name": "New $3 Game",
                                "typeAlert": { "newGames3Email": newData.notificationSettings.newGames3Email, "newGames3PN": newData.notificationSettings.newGames3PN }
                            },
                            {
                                "name": "New $5 Game",
                                "typeAlert": { "newGames5Email": newData.notificationSettings.newGames5Email, "newGames5PN": newData.notificationSettings.newGames5PN }
                            },
                            {
                                "name": "New $10 Game",
                                "typeAlert": { "newGames10Email": newData.notificationSettings.newGames10Email, "newGames10PN": newData.notificationSettings.newGames10PN }
                            },
                            {
                                "name": "New $20 Game",
                                "typeAlert": { "newGames20Email": newData.notificationSettings.newGames20Email, "newGames20PN": newData.notificationSettings.newGames20PN }
                            },
                            {
                                "name": "New $30 Game",
                                "typeAlert": { "newGames30Email": newData.notificationSettings.newGames30Email, "newGames30PN": newData.notificationSettings.newGames30PN }
                            }
                        ]
                    }
                ];
                this.subscribeToOffers = newData.notificationSettings.subscribeToOffers;
                this.notifications = userSelectedData;
            },
            deep: true
        }
    }
};
</script>

<style>
    .disable {
        mouse-events: none;
    }
</style>