<template>
    <section class="register_form">
        <form data-abide novalidate data-validate-on-blur="true" id="registerForm" v-show="!isUserLogged && formEnable">
            <!-- STEP 1 -->
            <div class="grid-x grid-margin-x align-justify block-section">
                <div class="cell large-6">
                    <label for="firstName">
                        <input type="text" v-model="firstName" placeholder="First Name *" required>
                        <span class="form-error">Please enter your First Name</span>
                    </label>
                </div>
                <div class="cell large-6">
                    <label for="lastName">
                        <input type="text" v-model="lastName" placeholder="Last Name *" required>
                        <span class="form-error">Please enter your Last Name</span>
                    </label>
                </div>
                <div class="cell large-6">
                    <label for="gender">
                        <select v-model="gender" required>
                            <option disabled selected value>Select Your Gender *</option>
                            <option value="0">Female</option>
                            <option value="1">Male</option>
                            <option value="2">Other</option>
                            <option value="3">Prefer not to say</option>
                        </select>
                        <span class="form-error">Please enter your Gender</span>
                    </label>
                </div>
                <div class="cell large-6">
                    <label for="date_birth">
                        <input type="date" placeholder="Date Of Birth *" date-date-format="mm/dd/yyyy" name="dateOfBirth"
                            :format="customFormatter" v-model="date_birth" @blur="dateSelected()"
                            :class="{ 'is_valid': birth_error }" required>
                        <span class="form-error-birth" v-if="birth_error">You must be over 18</span>
                    </label>

                    <div class="cell large-6">
                        <label for="ageCheckbox" class="inline-check">
                            <input required id="ageCheckbox" name="ageCheckbox" type="checkbox" v-model="ageCheck">
                            <span>I certify that I am 18 years of age or older *</span>
                            <span class="form-error" v-if="ageCheckError">Please confirm that you are 18 years or
                                older</span>
                        </label>
                        <small><i>We request your date of birth to confirm that members are of legal age to participate
                                in Washington’s Lottery.</i></small>
                    </div>
                </div>
            </div>
            <!-- STEP 2 -->
            <div class="grid-x grid-margin-x align-justify block-section" id="mailingAddressBlock">
                <div class="cell large-12">
                    <h2>Mailing Address</h2>
                    <p class="legend">
                        <i>We’re looking forward to sending your rewards to you! Please provide a valid street
                            address for your account registration. We cannot accept PO Boxes or addresses outside the US
                            at this time. Thank you for your understanding.</i>
                    </p>
                </div>
                <div class="cell large-12">
                    <label>
                        <input type="text" v-model="mailingAddress" placeholder="Address Line 1 *" name="mailingAddress" required>
                        <span class="form-error">
                            Please enter your Address
                        </span>
                    </label>
                </div>
                <div class="cell large-12">
                    <label>
                        <input type="text" v-model="addressContinued" name="addressContinued" placeholder="Address Line 2">
                    </label>
                </div>
                <div class="cell large-6">
                    <label>
                        <input type="text" placeholder="City *" v-model="city" name="city" required>
                        <span class="form-error">Please enter your City</span>
                    </label>
                </div>
                <div class="cell large-3">
                    <label>
                        <select id="stateSelect" v-model="state" name="state" required>
                            <option value="AL"> AL</option>
                            <option value="AK"> AK</option>
                            <option value="AZ"> AZ</option>
                            <option value="AR"> AR</option>
                            <option value="CA"> CA</option>
                            <option value="CO"> CO</option>
                            <option value="CT"> CT</option>
                            <option value="DC"> DC</option>
                            <option value="DE"> DE</option>
                            <option value="FL"> FL</option>
                            <option value="GA"> GA</option>
                            <option value="HI"> HI</option>
                            <option value="ID"> ID</option>
                            <option value="IL"> IL</option>
                            <option value="IN"> IN</option>
                            <option value="IA"> IA</option>
                            <option value="KS"> KS</option>
                            <option value="KY"> KY</option>
                            <option value="LA"> LA</option>
                            <option value="ME"> ME</option>
                            <option value="MD"> MD</option>
                            <option value="MA"> MA</option>
                            <option value="MI"> MI</option>
                            <option value="MN"> MN</option>
                            <option value="MS"> MS</option>
                            <option value="MO"> MO</option>
                            <option value="MT"> MT</option>
                            <option value="NE"> NE</option>
                            <option value="NV"> NV</option>
                            <option value="NH"> NH</option>
                            <option value="NJ"> NJ</option>
                            <option value="NM"> NM</option>
                            <option value="NY"> NY</option>
                            <option value="NC"> NC</option>
                            <option value="ND"> ND</option>
                            <option value="OH"> OH</option>
                            <option value="OK"> OK</option>
                            <option value="OR"> OR</option>
                            <option value="PA"> PA</option>
                            <option value="DC"> PR</option>
                            <option value="RI"> RI</option>
                            <option value="SC"> SC</option>
                            <option value="SD"> SD</option>
                            <option value="TN"> TN</option>
                            <option value="TX"> TX</option>
                            <option value="UT"> UT</option>
                            <option value="VA"> VA</option>
                            <option value="VI"> VI</option>
                            <option value="VT"> VT</option>
                            <option value="WA" selected> WA</option>
                            <option value="WV"> WV</option>
                            <option value="WI"> WI</option>
                            <option value="WY"> WY</option>
                        </select>
                        <span class="form-error">Please enter your State</span>
                    </label>
                </div>
                <div class="cell  large-3">
                    <label>
                        <input type="text" minlength="5" maxlength="5" placeholder="Zip Code *" v-model="zipcode" name="zipcode" required pattern="number">
                        <span class="form-error">Please provide a valid Zip Code</span>
                    </label>
                </div>
                <div class="cell medium-6">
                    <label>
                        <input type="tel" id="phoneNumber" placeholder="Phone Number *" required
                            pattern="^\d{3}-\d{3}-\d{4}$" v-model="phoneNumber" @input="formatPhone(phoneNumber)">
                        <span class="form-error">Please provide a valid Phone Number</span>
                    </label>
                </div>
            </div>
            <!-- STEP 3 -->
            <div class="grid-x grid-margin-x align-justify block-section">
                <div class="cell large-12">
                    <h2>Login Information</h2>
                </div>
                <div class="cell large-12">
                    <label>
                        <input type="text" id="email-address" v-model="userName" placeholder="Email Address *"
                            pattern="email" required>
                        <span class="form-error"> Please provide a valid Email Address</span>
                    </label>
                </div>
                <div class="cell large-12">
                    <label>
                        <input type="text" placeholder="Confirm Email Address *" pattern="email"
                            data-equalto="email-address" required>
                        <span class="form-error">Emails do not match</span>
                    </label>
                </div>
                <div class="cell large-12">
                    <label>
                        <input type="password" id="password" placeholder="Password *" v-model="password"
                            :class="{ 'is-invalid-input': passwordError }" @blur="validate" @keyup="keyUp('validate')"
                            @keydown="keyDown" :pattern="rules"
                            required>


                        <password v-model="password" :strength-meter-only="true" />

                        <ul v-if="passwordError" class="error non-abide">
                            <li v-if="!passwordLength">Passwords must be a minimum of 10 characters.</li>
                            <li v-if="!passwordNumber">Passwords require at least one number.</li>
                            <li v-if="passwordCharacter">Passwords may not include spaces.</li>
                            <li v-if="!passwordSpecial">Passwords require at least one symbol.</li>
                            <li v-if="!passwordUppercase">Passwords require at least one uppercase character.</li>
                            <li v-if="!passwordLowercase">Passwords require at least one lowercase character.</li>
                        </ul>
                    </label>
                    <p class="legend"><i>Please keep in mind that your password must be at least 10 characters long and
                            contain the following: an
                            uppercase letter, a lowercase letter, a number and a symbol.</i></p>
                </div>
                <div class="cell medium-12">
                    <label>
                        <input type="password" placeholder="Confirm Password *" aria-describedby="passwordHint"
                            data-equalto="password" required v-model="confirmPassword" @blur="validate">
                        <span class="form-error">Passwords do not match</span>
                    </label>
                </div>
            </div>
            <!-- STEP 4 -->
            <div class="grid-x grid-margin-x align-justify block-section">
                <div class="cell large-12">
                    <h2>Select Games and Notifications</h2>
                    <NotificationSettings @receive-offers="handleOffers" @notification-list="handleCheckbox">
                    </NotificationSettings>
                </div>
            </div>
            <!-- STEP 5 -->
            <div class="grid-x grid-margin-x align-justify block-section">
                <div class="cell large-12">
                    <label for="agreeTermsCheck" class="inline-check">
                        <input type="checkbox" name="agreeTermsCheck" id="agreeTermsCheck" v-model="agreeTermsCheck"
                            required>
                        <span class="agreeTermsCheck">I agree with Washington’s Lottery <a href="#"
                                @click="openTermsModal">Terms of Service</a>*</span>
                        <span class="form-error" v-if="agreeTermsCheckError">Please confirm that you are agree with
                            Washington’s Lottery Terms of Service</span>
                    </label>
                </div>
            </div>

            <div class="grid-x grid-margin-x block-section">
                <div class="cell large-12 action-section">
                    <button class="button submit-button" @click="submitCheck">Create Account</button>
                </div>
            </div>

            <!-- MODAL TERMS -->
            <div class="large reveal callout terms-modal" id="termsModal" data-reveal data-close-on-click="false">
                <div class="grid-x">
                    <h5>Terms and Conditions</h5>
                    <div class="cell small-12">
                        <div class="terms" v-html="termsContent"></div>
                    </div>
                </div>
                <button class="close-button" data-close aria-label="Close modal" type="button">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <!-- MODAL ERROR -->
            <div class="medium reveal callout alert error-modal" id="errorRegisterModal" data-reveal
                data-close-on-click="false">
                <div class="grid-x">
                    <h5>Error</h5>
                    <div class="cell small-12">
                        <div class="">{{ registerErrorMsg }}</div>
                    </div>
                </div>
                <button class="close-button" data-close aria-label="Close modal" type="button">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </form>
    </section>
</template>

<script>
import Datepicker from "vue-moment-datepicker";
import $ from 'jquery';
import Password from 'vue-password-strength-meter'
import NotificationSettings from '../Account/NotificationSettings.vue';
import moment from 'moment';

export default {
    components: {
        Datepicker,
        Password,
        NotificationSettings
    },
    data: function () {
        return {
            isUserLogged: this.isUserAuthed(),

            firstName: '',
            lastName: '',
            gender: '',
            date_birth: '',
            birth_error: false,
            mailingAddress: '',
            addressContinued: '',
            city: '',
            state: 'WA',
            zipcode: '',
            country: 'USA',
            phoneNumber: '',

            userName: '',
            password: null,
            confirmPassword: null,

            subscribeToOffers: false,
            notifications: {
                "cashPopWNEmail": false,
                "cashPopWNPN": false,
                "powerJRollEmail": false,
                "powerJRollPN": false,
                "powerWNEmail": false,
                "powerWNPN": false,
                "power100JAEmail": false,
                "power100JAPN": false,
                "power200JAEmail": false,
                "power200JAPN": false,
                "power300JAEmail": false,
                "power300JAPN": false,
                "power400JAEmail": false,
                "power400JAPN": false,
                "megaJRollEmail": false,
                "megaJRollPN": false,
                "megaWNEmail": false,
                "megaWNPN": false,
                "mega100JAEmail": false,
                "mega100JAPN": false,
                "mega200JAEmail": false,
                "mega200JAPN": false,
                "mega300JAEmail": false,
                "mega300JAPN": false,
                "mega400JAEmail": false,
                "mega400JAPN": false,
                "lottoJRollEmail": false,
                "lottoJRollPN": false,
                "lottoWNEmail": false,
                "lottoWNPN": false,
                "lotto3JAEmail": false,
                "lotto3JAPN": false,
                "lotto4JAEmail": false,
                "lotto4JAPN": false,
                "lotto5JAEmail": false,
                "lotto5JAPN": false,
                "lotto7JAEmail": false,
                "lotto7JAPN": false,
                "hit5JRollEmail": false,
                "hit5JRollPN": false,
                "hit5WNEmail": false,
                "hit5WNPN": false,
                "hit5500JAEmail": false,
                "hit5500JAPN": false,
                "match4WNEmail": false,
                "match4WNPN": false,
                "pick3WNEmail": false,
                "pick3WNPN": false,
                "dailyKenoWNEmail": false,
                "dailyKenoWNPN": false,
                "allNewGamesEmail": false,
                "allNewGamesPN": false,
                "newGames1Email": false,
                "newGames1PN": false,
                "newGames2Email": false,
                "newGames2PN": false,
                "newGames3Email": false,
                "newGames3PN": false,
                "newGames5Email": false,
                "newGames5PN": false,
                "newGames10Email": false,
                "newGames10PN": false,
                "newGames20Email": false,
                "newGames20PN": false,
                "newGames30Email": false,
                "newGames30PN": false,
                "subscribeToOffers": false,
            },

            eighteen: false,

            termsTitle: '',
            termsContent: '',

            registerApiToken: '',
            registerErrorMsg: '',
            formEnable: true,
            formSuccess: false,

            rules: "^(?=.*\\d)(?!.*\\s)(?=.*[~!@#$%^&*()_+\\-=\\[\\]{};:'\",./<>?])(?=.*[A-Z])(?=.*[a-z])(?=.{10,}$).*$",
            passwordValid: false,
            passwordError: false,
            test: '',
            confirmSubmit: false,

            passwordLowercase: true,
            passwordUppercase: true,
            passwordNumber: true,
            passwordLength: true,
            passwordCharacter: true,
            passwordSpecial: true,

            ageCheck: false,
            ageCheckError: false,
            agreeTermsCheck: false,
            agreeTermsCheckError: false,
        }
    },
    mounted: function () {
        $(document).foundation();
        this.getToken();
        // this.getPasswordRules();

        this.isUserLogged && this.redirectToUrl('/my-profile');
    },
    methods: {
        handleOffers(val) {
            this.subscribeToOffers = val;
        },
        handleCheckbox(val) {
            const filteredArray = val.reduce((result, notificationGroup) => {
                notificationGroup.games.forEach(game => {
                    const typeAlert = game.typeAlert;
                    if (typeAlert) {
                        Object.assign(result, typeAlert);
                    }
                });

                return result;
            }, {});
            this.notifications = filteredArray;
        },
        openTermsModal() {
            $('#termsModal').foundation('open');
        },
        closeTermsModal() {
            $('#errorAddressModal').foundation('close');
        },
        async getPasswordRules() {
            var apiToken = this.token,
                url = process.env.VUE_APP_APIURL + '/1.0/PAM/GetPasswordRules';

            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + apiToken,
                    },
                }
                );
                const json = await response.json();
                this.rules = json.data;
                this.test = new RegExp(this.rules.regularExpression);
            } catch (error) {
                return (this.errorMessage = error);
            }
        },
        keyDown() {
            clearTimeout(this.keyTimer);
        },
        keyUp(method) {
            clearTimeout(this.keyTimer);
            if (method === 'match') {
                if (this.confirmPassword != '') {
                    this.keyTimer = setTimeout(this.match, 400);
                }
            }

            if (method === 'validate') {
                this.keyTimer = setTimeout(this.validate, 400);
            }
        },
        validate() {
            this.passwordLowercase = true;
            this.passwordUppercase = true;
            this.passwordNumber = true;
            this.passwordLength = true;
            this.passwordCharacter = true;
            this.passwordSpecial = true;
            if (this.password.length) {
                var password = this.password;
                var pswdLength = password.length;


                if (pswdLength < 10) {
                    this.passwordLength = false;
                }

                let pswdRegExA = /[A-Z]/g;
                let pswdRegExa = /[a-z]/g;
                let pswdRegExN = /\d/g;
                let pswdRegExS = /\s/;
                let pswdRegExSpecial = /[!@#$%^&*]/g;

                let pswdTestA = pswdRegExA.test(password);
                let pswdTesta = pswdRegExa.test(password);
                let pswdTestN = pswdRegExN.test(password);
                let pswdTestS = pswdRegExS.test(password);
                let pswdTestSpecial = pswdRegExSpecial.test(password);

                if (pswdTestA == false) {
                    this.passwordUppercase = false;
                }
                if (pswdTesta == false) {
                    this.passwordLowercase = false;
                }
                if (pswdTestN == false) {
                    this.passwordNumber = false;
                }
                if (pswdTestS == false) {
                    this.passwordCharacter = false;
                }
                if (pswdTestSpecial == false) {
                    // console.log(password)
                    this.passwordSpecial = false;
                }

                if (this.confirmPassword != '') {
                    this.match();
                }
            }
        },
        match() {
            if (this.password === this.confirmPassword) {
                this.passwordMatch = true;
            } else {
                this.passwordMatch = false;
            }

            this.handleError();
        },
        handleError() {
            if (
                this.passwordLowercase === false ||
                this.passwordUppercase === false ||
                this.passwordNumber === false ||
                this.passwordLength === false ||
                this.passwordCharacter === true ||
                this.passwordMatch === false ||
                this.passwordSpecial === false
            ) {
                this.passwordError = true;

            } else {
                this.passwordError = false;
            }

            this.validatePassword();
        },
        validatePassword() {
            // console.log('Validate Password')
            if (
                this.passwordError === false &&
                this.passwordMatch === true
            ) {
                this.passwordValid = true;
            } else {
                this.passwordValid = false;
            }

        },
        customFormatter(date) {
            return moment(date).format('MM/DD/yyyy');
        },
        getToken() {
            // then hit API with token
            this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });
        },
        async fetchData(token) {
            var apiToken = token;
            this.registerApiToken = apiToken;
            var urlProtocol = this.getProtocol();

            try {
                const response = await fetch(urlProtocol + process.env.VUE_APP_APIURL +
                    "/1.0/Games/StaticContentElements/StaticContentElement/GetContentElementByKey?contentKey=terms-conditions", {
                    headers: {
                        'Authorization': 'Bearer ' + apiToken,
                    },
                });
                const json = await response.json();

                var terms = json.data[0];
                this.termsTitle = terms.title;
                this.termsContent = terms.centralContent;
            } catch (error) {
                return this.errorMessage = error;
            }
        },
        dateSelected() {
            this.$nextTick(function () {
                function getAge(birth, target) {
                    let months = target.diff(birth, 'months', true)
                    let birthSpan = { year: Math.floor(months / 12), month: Math.floor(months) % 12, day: Math.round((months % 1) * target.daysInMonth(), 0) }
                    if (birthSpan.year < 1 && birthSpan.month < 1) {
                        return 10
                    } else if (birthSpan.year < 1) {
                        return 10
                    } else if (birthSpan.year < 2) {
                        return 10
                    } else {
                        return birthSpan.year
                    }

                }

                let birth = new Date(this.date_birth);
                let ageOfPerson = getAge(birth, moment());
                // console.log(ageOfPerson);
                if (ageOfPerson < 18) {
                    this.birth_error = true
                    // console.log(this.birth_error)
                    this.eighteen = false;
                } else {
                    this.birth_error = false;
                    this.eighteen = true;
                }
            });

        },
        submitCheck: function (e) {
            // e.preventDefault();
            $(document).foundation();
            this.ageCheck === false ? this.ageCheckError = true : this.ageCheckError = false;
            this.agreeTermsCheck === false ? this.agreeTermsCheckError = true : this.agreeTermsCheckError = false;
            if (this.passwordValid === true) {
                this.passwordError = false;
                let _this = this;

                const $form = $('#registerForm');
                new Foundation.Abide($form);
                $(document).on("formvalid.zf.abide", function () {

                    // const formData = $('#registerForm').serializeArray();
                    // console.log(formData);

                    var apiToken = _this.registerApiToken;

                    let bodyRegister = {
                        "gender": _this.gender,
                        "userName": _this.userName,
                        "email": _this.userName,
                        "password": _this.password,
                        "firstName": _this.firstName,
                        "lastName": _this.lastName,
                        "address": {
                            "address": _this.mailingAddress,
                            "address2": _this.addressContinued,
                            "city": _this.city,
                            "state": _this.state,
                            "zipCode": _this.zipcode
                        },
                        "phoneNumber": _this.phoneNumber,
                        "birthdate": _this.date_birth,
                        "nickname": "",
                        "altPhone": "",
                        // "subscribeToOffers": _this.subscribeToOffers,
                        "termsAcceptance": _this.agreeTermsCheck,
                        "isUserOver18": _this.eighteen,
                        "notificationSettings": _this.notifications,
                        "primaryPhoneForTxt": true,
                        "registrationType": 0,
                        "altPhoneForTxt": true,
                        "phoneMFVerified": true,
                        "pendingMFPhoneVerification": true,
                        "isUserEnrolledInCashless": true,
                    }
                    bodyRegister.notificationSettings['subscribeToOffers'] = _this.subscribeToOffers;

                    const requestRegisterOptions = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + apiToken
                        },
                        body: JSON.stringify(bodyRegister)
                    };

                    this.formEnable = false;
                    this.confirmSubmit = true;

                    fetch("https:" + process.env.VUE_APP_APIURL + "/1.0/PAM/RegisterUser", requestRegisterOptions)
                        .then(response => response.json())
                        .then(dataRegister => {
                            // console.log(dataRegister)
                            if (dataRegister.data === null) {
                                _this.registerErrorMsg = dataRegister.error.message;
                                let errorCode = dataRegister.error.errorCode;

                                setTimeout(() => {
                                    $('#errorRegisterModal').foundation('open');
                                }, 500);

                                _this.formEnable = true;
                                _this.confirmSubmit = false;

                                //Force Address is Invalid
                                if(errorCode === 5){
                                    let anchor = document.querySelector("#mailingAddressBlock");
                                    anchor.scrollIntoView({ behavior: "smooth", block: "start" });

                                    $('input[name="mailingAddress"], input[name="city"], input[name="state"], input[name="zipcode"]').addClass("is-invalid-input");
                                }

                            } else {
                                _this.redirectToUrl('/register/thanks-for-registering')
                                _this.formEnable = false;
                                _this.formSuccess = true;
                            }
                        });

                }).on("submit", function (ev) {
                    // console.log('SUBMITTING')
                    ev.preventDefault();
                });
            } else {
                e.preventDefault();
                // this.registerErrorMsg = data.error.message;
                this.passwordError = true;

                // Check if the element is found
                setTimeout(() => {
                    const firstErrorElement = document.querySelector('.is-invalid-label');

                    if (firstErrorElement) {
                        // Scroll to the element
                        firstErrorElement.scrollIntoView({
                            behavior: 'smooth',
                            block: 'start',
                        });
                    }
                }, 100);
            }
        },
        formatPhone(val) {
            const numericValue = val.replace(/\D/g, '');
            if (numericValue.length === 10) {
                this.phoneNumber = `${numericValue.slice(0, 3)}-${numericValue.slice(3, 6)}-${numericValue.slice(6)}`;
            } else {
                this.phoneNumber = numericValue;
            }
        },
        redirectToUrl(url) {
            window.location.href = url;
        }
    },
    computed: {
    },
};
</script>