<template>
        <div class="productDetailWrap cf">
            <SignInRegister v-if="!isUserLogged"></SignInRegister>
            <!-- <Drawings v-if="isUserLogged && id != 0 && drawings != '' && completedDrawHist === false && secondHist === false && completedSecondHist === false && cashHist === false && completedCashHist === false" :debug="false" :tokenKey="token" title="Active Drawing Entries" :resultsReturned="qty" :data="drawings" userId="id" @history="showDrawHist" :view="drawHist"></Drawings>

            <CompletedDrawings v-if="isUserLogged && id != 0 && completedDrawings != '' && drawHist === false && secondHist === false && completedSecondHist === false && cashHist === false && completedCashHist === false" :debug="false" :tokenKey="token" title="Recent Completed Drawings" :resultsReturned="10" :data="completedDrawings" userId="id" @history="showDrawHist" :view="completedDrawHist"></CompletedDrawings>

            <SecondChance v-if="isUserLogged && id != 0 && secondChance != '' && drawHist === false && completedDrawHist === false && completedSecondHist === false && cashHist === false && completedCashHist === false" :debug="false" :tokenKey="token" title="Active Second Chance Entries" :resultsReturned="10" :data="secondChance" userId="id" @history="showDrawHist" :view="secondHist"></SecondChance>

            <CashExplosion v-if="isUserLogged && id != 0 && completedCashExplosion != '' && drawHist === false && completedDrawHist === false && secondHist === false && completedSecondHist === false && completedCashHist === false" :debug="false" :tokenKey="token" title="Cash Explosion" :resultsReturned="10" :data="cashExplosion" userId="id" @history="showDrawHist" :view="cashHist"></CashExplosion> -->

            <Drawings v-if="isUserLogged && id != 0 && drawings != ''" :debug="false" :tokenKey="token" title="Active Drawing Entries" :resultsReturned="10" :data="drawings" :userId="id" @history="showDrawHist" :view="drawHist"></Drawings>

            <CompletedDrawings v-if="isUserLogged && id != 0 && completedDrawings != ''" :debug="false" :tokenKey="token" title="Recent Completed Drawings" :resultsReturned="10" :data="completedDrawings" userId="id" @history="showDrawHist" :view="completedDrawHist"></CompletedDrawings>

            <SecondChance v-if="isUserLogged && id != 0 && secondChance != ''" :debug="false" :tokenKey="token" title="Active Second Chance Entries" :resultsReturned="10" :data="secondChance" userId="id" @history="showDrawHist" :view="secondHist"></SecondChance>

            <CompletedSecondChance v-if="isUserLogged && id != 0 && completedSecondChance != ''" :debug="false" :tokenKey="token" title="Completed Second Chance Entries" :resultsReturned="10" :data="completedSecondChance" userId="id" @history="showDrawHist" :view="completedSecondHist"></CompletedSecondChance>

            <CashExplosion v-if="isUserLogged && id != 0 && cashExplosion != ''" :debug="false" :tokenKey="token" title="Cash Explosion" :resultsReturned="10" :data="cashExplosion" userId="id" @history="showDrawHist" :view="cashHist"></CashExplosion>
        </div>
</template>

<script>
    import SignInRegister from '../../../ViewComponents/SignInRegister/SignInRegister.vue';
    import Drawings from './EntryList_Drawings.vue';
    import CompletedDrawings from './EntryList_CompletedDrawings.vue';
    import SecondChance from './EntryList_SecondChance.vue';
    import CompletedSecondChance from './EntryList_CompletedSecondChance.vue';
    import CashExplosion from './EntryList_CashExplosion.vue';
    import moment from 'moment';

    export default {
        components:{
            SignInRegister,
            Drawings,
            CompletedDrawings,
            SecondChance,
            CompletedSecondChance,
            CashExplosion
        },
        data: function () {
            return {
                id: 0,
                isUserLogged: false,
                drawings: '',
                completedDrawings: '',
                secondChance: '',
                completedSecondChance: '',
                cashExplosion: '',
                completedCashExplosion: '',
                qty: 1,
                history: false,
                drawHist: false,
                completedDrawHist: false,
                secondHist: false,
                completedSecondHist: false,
                cashHist: false,
                completedCashHist: false
            }
        },
        mounted: function () {
            this.isUserLogged = this.isUserAuthed();            
            this.getToken();
        },
        methods: {
            getToken() {
                // then hit API with token
                this.getAPItoken().then((token) => {
                    this.token = token;
                }).then(() => {
                    this.id = this.getLoggedInUserId();
                }).then(() => {
                    this.getDrawingEntries();
                    this.getCompletedEntries();
                }).then(() => {
                    this.getSecondChanceEntries();
                    // this.getDrawingEntriesByDate();
                    this.getCompletedSecondChanceEntries();
                }).then(() => {
                    this.getCashExplosionEntries();
                });
            },
            convertDates(d) {
                let entries = d,
                    newDates = [];

                if (entries === null) {
                    false;
                } else {
                    for (let i = 0; i < entries.length; i++) {
                        let entry = entries[i];

                        entry.drawingClosingDate = moment(entry.drawingClosingDate).format("MM/DD/YYYY");
                        entry.drawingDate = moment(entry.drawingDate).format("MM/DD/YYYY");
                        entry.drawingStartDate = moment(entry.drawingStartDate).format("MM/DD/YYYY");

                        newDates.push(entry);
                    }
                }
                
                if (entries === null) {
                    false;
                } else {
                    return newDates;
                }
            },
            getDrawingEntries() {
                let url = process.env.VUE_APP_APIURL + '/1.0/PlainDraw/PlainDraw/GetPlainDrawingUserEntries?userID=' + this.id + '&open=true';
                // let url = process.env.VUE_APP_APIURL + '/1.0/PlainDraw/PlainDraw/Promotion/Get-User-Participation-Entries-Detail?userID=' + this.id;

                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        'Authorization': "Bearer " + this.token
                    },
                })
                .then((response) => response.json())
                .then((json) => {
                    // console.log('PlainDrawingUserEntries')
                    // console.log(json)
                    json.error === null ? this.drawings = this.convertDates(json.data) : false;
                })
                .catch(error => {
                    console.log(this.errorMessage = error);
                })
            },
            getCompletedEntries() {
                let url = process.env.VUE_APP_APIURL + '/1.0/PlainDraw/PlainDraw/GetPlainDrawingUserEntries?userID=' + this.id + '&open=false';

                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        'Authorization': "Bearer " + this.token
                    },
                })
                .then((response) => response.json())
                .then((json) => {
                    json.error === null ? this.completedDrawings = this.convertDates(json.data) : false;
                })
                .catch(error => {
                    console.log(this.errorMessage = error);
                })
            },
            getSecondChanceEntries() {
                let url = process.env.VUE_APP_APIURL + '/1.0/SecondChance/DrawingEntries/GetActiveDrawingEntriesByUserId/' + this.id;

                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        'Authorization': "Bearer " + this.token
                    },
                })
                .then((response) => response.json())
                .then((json) => {
                    json.error === null ? this.secondChance = this.convertDates(json.data) : false;
                })
                .catch(error => {
                    console.log(this.errorMessage = error);
                })
            },
            getCompletedSecondChanceEntries() {
                let url = process.env.VUE_APP_APIURL + '/1.0/SecondChance/DrawingEntries/GetClosedDrawingEntriesByUserId/' + this.id;

                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        'Authorization': "Bearer " + this.token
                    },
                })
                .then((response) => response.json())
                .then((json) => {
                    json.error === null ? this.completedSecondChance = this.convertDates(json.data) : false;
                })
                .catch(error => {
                    console.log(this.errorMessage = error);
                })
            },
            getCashExplosionEntries() {
                let url = process.env.VUE_APP_APIURL + '/1.0/CashExplosion/CashExplosion/GetCashExplosionEntries?userID=' + this.id; 
                // console.log(this.id)
                // let url = process.env.VUE_APP_APIURL + '/1.0/CashExplosion/CashExplosion/GetCashExplosionEntries?userID=' + 114; // FOR TESTING

                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        'Authorization': "Bearer " + this.token
                    },
                })
                .then((response) => response.json())
                .then((json) => {
                    json.error === null ? this.cashExplosion = json.data : false;
                    // this.cashExplosion = [{"ticketSerialNumber":"932-002003-022-2-0675111783","submitDate":"2023-07-24T22:17:18.04","drawId":543,"drawDate":"2023-07-29T00:00:00","showAirDate":"2023-07-29T00:00:00"},{"ticketSerialNumber":"992-277527-003-0-0150132337","submitDate":"2023-07-12T19:48:28.297","drawId":541,"drawDate":"2023-07-15T00:00:00","showAirDate":"2023-07-15T00:00:00"}];
                })
                .catch(error => {
                    console.log(this.errorMessage = error);
                })
            },
            getCompletedCashExplosionEntries() {
                let url = process.env.VUE_APP_APIURL + '/1.0/CashExplosion/CashExplosion/GetCashExplosionEntries?userID=' + this.id;

                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        'Authorization': "Bearer " + this.token
                    },
                })
                .then((response) => response.json())
                .then((json) => {
                    json.error === null ? this.completedCashExplosion = this.convertDates(json.data) : false;
                })
                .catch(error => {
                    console.log(this.errorMessage = error);
                })
            },
            showDrawHist(type) {
                // this.history = !this.history;

                switch (type) {
                    case 1:
                        this.drawHist = !this.drawHist;
                        break;
                    case 2:
                        this.completedDrawHist = !this.completedDrawHist;
                        break;
                    case 3:
                        this.secondHist = !this.secondHist;
                        break;
                    case 4:
                        this.completedSecondHist = !this.completedSecondHist;
                        break;
                    case 5:
                        this.cashHist = !this.cashHist;
                        break;
                    case 6:
                        this.completedCashHist = !this.completedCashHist;
                        break;
                }

                // this.history === false ? this.qty = 1 : this.qty = 100;
            }
        },
        computed: {
        }
    };
</script>

<style lang="scss" scoped>

</style>